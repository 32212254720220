import React, { useState, useEffect } from "react";
import {
  Button,
  Box,
  Grid,
  Typography,
  Divider,
  InputAdornment,
  TextField,
  useMediaQuery,
} from "@mui/material";

import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import SearchIcon from "@mui/icons-material/Search";
import { getAllCollection } from "../../services/redux/NftApiReducer/NftApiReducer";
import CustomLoader from "../../components/shared/customLoader/customLoader";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import CampaignsCard from "../../components/features/Campaigns/CampaignsCard";
import {
  getAllCampaigns,
  getSelectedCampaign,
} from "../../services/redux/CampaignsReducer/CampaignsReducer";
import axios from "axios";
import { campaignsBaseUrl } from "../../services/api/api";
import toast from "react-hot-toast";

const containerStyle = {
  position: "relative",
  width: "100%",
  minHeight: "100vh",
  background:
    "linear-gradient(225deg, #731686 1%, transparent 10%, transparent 80%, #af22c8 100%)", // Linear gradient background
  backgroundSize: "cover",
  backgroundRepeat: "no-repeat",
  backgroundPosition: "center",
  paddingBottom: "20px",
};
const contentStyle = {
  position: "relative",
  top: "0px",
  width: "100%",
};

const Campaigns = () => {
  const [searchQuery, setSearchQuery] = useState("");
  const txtcolor = "#f1f1f1";
  const { campaigns, loading } = useSelector((state) => state.campaigns);
  const { selectedCampaign, loading: selectedCampaignLoading } = useSelector(
    (state) => state.campaigns
  );

  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    dispatch(getAllCampaigns());
  }, []);

  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
  };

  const filteredCampaigns = campaigns?.data?.filter((campaign) =>
    campaign.name.toLowerCase().includes(searchQuery.toLowerCase())
  );

  const handleData = (id) => {
    const isAuthenticated = localStorage.getItem("token");
    if (!isAuthenticated) {
      toast.error("You need to login first", { id: "copy" });
    } else navigate("/breeding/rewardbag/" + id);
  };
  const is1024ScreenSize = useMediaQuery("(min-width:1024px)");

  console.log(selectedCampaign, loading);

  return (
    <div style={containerStyle}>
      <div style={contentStyle}>
        <Box>
          <Grid container spacing={2}>
            <Grid item xl={2} lg={2} sm={2} xs={2}>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "start",
                  pt: 3,
                  pl: 1,
                }}
              >
                <Button
                  size="large"
                  sx={{ color: txtcolor }}
                  onClick={() => navigate(-1)}
                >
                  <ArrowBackIcon fontSize="large" sx={{ color: txtcolor }} />
                </Button>
              </Box>
            </Grid>
            <Grid item xl={8} lg={8} sm={8} xs={10}>
              <Box
                sx={{ display: "flex", justifyContent: "center", pt: 4, pb: 4 }}
              >
                <Typography
                  variant="h5"
                  sx={{
                    color: txtcolor,
                    fontWeight: 600,
                    fontFamily: "goodTime",
                    // fontSize: "14px",
                  }}
                >
                  Packs
                </Typography>
              </Box>
            </Grid>
            <Grid item xl={2} lg={2} sm={2} xs={1}>
              <Box
                sx={{ display: "flex", justifyContent: "center", pt: 4, pr: 4 }}
              ></Box>
            </Grid>
          </Grid>
          <Divider
            variant="middle"
            sx={{ mt: 0, backgroundColor: "#AF22C8", mx: 4, mb: 0 }}
          />

          <Box
            sx={{
              display: "flex",
              width: "100%",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Box
              sx={{
                ...(is1024ScreenSize ? { pt: 3.5, mr: 4 } : { pt: 3.5, mr: 2 }),
              }}
            >
              <TextField
                //   key={}
                style={{ width: "300px" }}
                size="small"
                placeholder="Search Packs"
                //   onKeyPress={handleKeyPress}
                onChange={handleSearchChange}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SearchIcon sx={{ color: "#D7D1D1" }} />
                    </InputAdornment>
                  ),
                  sx: {
                    color: "#D7D1D1",
                    backgroundColor: "rgba(255, 255, 255, 0.2)",
                    borderRadius: 6,
                    "& .MuiOutlinedInput-notchedOutline": {},
                    "&:hover .MuiOutlinedInput-notchedOutline": {
                      border: " 2px solid #7F6C6C",
                    },
                    "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                      border: " 2px solid #7F6C6C",
                    },
                    height: "35px",
                  },
                }}
                autoComplete="off" // Add this line to turn off auto-fill
              />
            </Box>
          </Box>
          <Box
            sx={{
              p: 1,
              pb: 4,
              display: "flex",
              justifyContent: "center",
            }}
          >
            {!loading ? (
              <>
                {campaigns.length === 0 ? (
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      pt: 6,
                      width: "100%",
                    }}
                  >
                    <Typography
                      variant="h6"
                      sx={{
                        color: "white",
                        fontFamily: "goodTime",
                        textAlign: "center",
                      }}
                    >
                      {"No Packs Found!"}{" "}
                    </Typography>
                  </Box>
                ) : (
                  <Grid container spacing={2} sx={{ pt: 4, px: 4 }}>
                    {campaigns &&
                      filteredCampaigns &&
                      filteredCampaigns?.map((item, index) => (
                        <Grid
                          key={index}
                          item
                          lg={3}
                          md={4}
                          sm={6}
                          xs={12}
                          sx={{
                            py: 4,
                            pb: 2,
                            display: "flex",
                            justifyContent: "center",
                          }}
                        >
                          <CampaignsCard
                            data={item}
                            onConfirm={() => handleData(item?.campaign_id)}
                          />
                        </Grid>
                      ))}
                  </Grid>
                )}
              </>
            ) : (
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  minHeight: "80vh", // Set minimum height to fill the entire viewport
                }}
              >
                <CustomLoader width={"60%"} text={"Please wait ..."} />{" "}
              </Box>
            )}
          </Box>
        </Box>
      </div>
    </div>
  );
};

export default Campaigns;
