import * as React from "react";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import "./TabPanels.css";

const TabPanel = (props) => {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        // <Grid container>
        // <Grid item sm={12}>
        <Box
          className={"custom-scroll"}
          sx={{ p: 1, borderRadius: 5 }}
          flexDirection="column"
        >
          <Typography>{children}</Typography>
        </Box>
        //   {/* </Grid> */}
        // </Grid>
      )}
    </div>
  );
};
export default TabPanel;
