import React, { useEffect, useState, useRef } from "react";
import { Box, Grid, Typography, Button, Tabs, Divider } from "@mui/material";

import { useDispatch } from "react-redux";

import { toast } from "react-hot-toast";
import { useSelector } from "react-redux/es/hooks/useSelector";

import styles from "./myAllNFTs.module.css"; // Import the CSS module

import { useNavigate, useParams } from "react-router-dom";
import NFTCard from "../../../components/features/NFTCard/NFTCard";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import {
  getAllCollectionNFTVanity,
  getCollectionById,
} from "../../../services/redux/NftApiReducer/NftApiReducer";
import CollectionHeader from "../../../components/layout/Shared/CollectionHeader/CollectionHeader";
import useScrollPagination from "../../../hooks/useScrollPagination/useScrollPagination";
import CustomLoader from "../../../components/shared/customLoader/customLoader";
import ScrollToTopOnMount from "../../../utils/ScrollToTop/automaticScrollToTop/automaticScrollToTop";
import { resetSellState } from "../../../services/redux/MyNftApiReducer/MyNftApiReducer";
import { resetText } from "../../../services/redux/TextReducer/TextReducer";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";

// Throttle function implementation
const throttle = (func, limit) => {
  let lastFunc;
  let lastRan;
  return function () {
    const context = this;
    const args = arguments;
    if (!lastRan) {
      func.apply(context, args);
      lastRan = Date.now();
    } else {
      clearTimeout(lastFunc);
      lastFunc = setTimeout(() => {
        if (Date.now() - lastRan >= limit) {
          func.apply(context, args);
          lastRan = Date.now();
        }
      }, limit - (Date.now() - lastRan));
    }
  };
};

const containerStyle = {
  position: "relative",
  width: "100%",
  minHeight: "100vh",
  background:
    "linear-gradient(225deg, #731686 1%, transparent 10%, transparent 80%, #af22c8 100%)", // Linear gradient background
  backgroundSize: "cover",
  backgroundRepeat: "no-repeat",
  backgroundPosition: "center",
  paddingBottom: "20px",
  marginTop: "10px",
};
const contentStyle = {
  position: "relative",
  top: "0px",
  width: "100%",
};

const CollectionAllNfts = () => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const param = useParams();
  const [myNFT, setMyNFT] = useState();

  const [collectionName, setCollectionName] = useState();
  const [collectionHeader, setCollectionHeader] = useState();

  const [loading1, setLoading] = useState(false);
  const { text } = useSelector((state) => state.text);
  const navigate = useNavigate();
  const { collectionById, loading, VanityNfts } = useSelector(
    (state) => state.nft
  );

  const { buyNFTState, sellNFT } = useSelector((state) => state.mynft);

  const [offset, setOffset] = useState(1); // Initialize offset with 0

  const collection_id = useSelector((state) => state.collection?.collectionId);
  const [pages, setPages] = useState(1);
  useEffect(() => {
    setCollectionName(param.name);
    // Reset to the first page when myNFT data changes
  }, [param]);
  //////////////////////////////////////////////////////////////////////

  useEffect(() => {
    if (offset === 1) {
      fetchData();
    } else if (offset <= pages) {
      fetchData();
    }
  }, [offset]);

  function mergeArrays(existingData, newData) {
    // Ensure newData is not undefined or null
    const mergedData = newData ?? [];

    // Clear the existingData if offset is 1
    if (offset === 1) {
      existingData = [];
    }

    if (newData?.length > 0 && newData) {
      // Iterate over the new data and push each element to the existing array
      mergedData?.forEach((item) => {
        existingData?.push(item);
      });
    }
    // Return the updated existing array
    return existingData;
  }

  // Throttle the scroll event handler
  const handleScroll = throttle(() => {
    const scrollTop = window.scrollY;
    const windowHeight = window.innerHeight;
    const documentHeight = document.documentElement.scrollHeight;

    if (scrollTop + windowHeight >= documentHeight - 5) {
      if (offset < pages) {
        setOffset((prevOffset) => prevOffset + 1);
      }
    }
  }, 100); // Adjust throttle delay as needed

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [handleScroll]);

  useEffect(() => {
    return () => {
      // Perform cleanup here, for example, resetting the data
      setMyNFT([]);
    };
  }, []);

  useEffect(() => {
    console.log(collectionById, " collectionById?.data?.nfts");
    setMyNFT((prevNFTs) => mergeArrays(prevNFTs, collectionById?.data?.nfts));

    if (offset === 1) {
      setPages(collectionById?.data?.collection_info?.total_pages);
    }
  }, [collectionById]);

  useEffect(() => {
    console.log(VanityNfts, " VanityNfts?.data?.nfts");
    setMyNFT((prevNFTs) => mergeArrays(prevNFTs, VanityNfts?.data?.nfts));

    if (offset === 1) {
      setPages(VanityNfts?.data?.collection_info?.total_pages);
    }
  }, [VanityNfts]);

  useEffect(() => {
    setCollectionHeader(collectionById?.data || VanityNfts?.data);
  }, [VanityNfts, collectionById]);

  ////////////////////////////////////////////////////////////////////////

  const fetchData = () => {
    if (param.accountNumber && param.name) {
      const apiData = {
        vanity_collection_name: param.name,
        user_account: param.accountNumber,
        limit: 24,
        offset: offset,
      };
      dispatch(getAllCollectionNFTVanity(apiData));
    } else {
      let account = localStorage.getItem("accnum");
      const apiData = {
        vanity_name: param.collections,
        limit: 24,
        offset: offset,
        userAccount: account,
      };
      dispatch(getCollectionById(apiData));
    }
    // Simulated delay for demonstration purposes
    // Replace with your actual API call
  };
  // Execute the initial data fetch on component mount
  // useEffect(() => {
  //   if (offset <= 3) {
  //     fetchData();
  //   }
  // }, [param.accountNumber, param.name, offset]);

  useEffect(() => {
    if (sellNFT?.data?.status) {
      toast.success(sellNFT?.data?.message);
      dispatch(resetSellState());
      setOffset(1);

      dispatch(resetText(""));
      // window.scrollTo(0, 0);
    } else if (sellNFT?.data?.status === false) {
      toast.error(sellNFT?.data?.message);
      dispatch(resetSellState());
    }
  }, [sellNFT?.data?.status]);

  function renderContent() {
    if (sellNFT.loading === true) {
      return (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            minHeight: "80vh", // Set minimum height to fill the entire viewport
          }}
        >
          <CustomLoader
            width={"60%"}
            text={text || text !== "" ? text : "Please wait ..."}
          />{" "}
        </Box>
      );
    } else {
      if (loading && !myNFT?.length > 0) {
        return (
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              minHeight: "80vh", // Set minimum height to fill the entire viewport
            }}
          >
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                minHeight: "80vh", // Set minimum height to fill the entire viewport
              }}
            >
              {" "}
              <CustomLoader width={"60%"} text={"Please wait ..."} />{" "}
            </Box>
          </Box>
        );
      } else if (myNFT && myNFT?.length > 0) {
        return (
          <Box sx={{ display: "flex", flexDirection: "column" }} width={"100%"}>
            <Grid
              container
              spacing={4}
              sx={{ paddingInline: 4 }}
              justifyContent={myNFT?.length < 4 ? "center" : "start"}
            >
              {myNFT &&
                myNFT?.map((items) => (
                  <Grid
                    item
                    xl={3}
                    lg={3}
                    sm={12}
                    xs={12}
                    sx={{ display: "flex", justifyContent: "center" }}
                    key={items.id}
                  >
                    <NFTCard nft={items} borderColor="#AF22C8" />
                  </Grid>
                ))}
            </Grid>
            {loading && (
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  minHeight: "80vh", // Set minimum height to fill the entire viewport
                }}
              >
                <CustomLoader
                  width={"60%"}
                  text={text || text !== "" ? text : "Please wait ..."}
                />{" "}
              </Box>
            )}
          </Box>
        );
      } else {
        return (
          <Box sx={{ display: "flex", justifyContent: "center", pt: 6 }}>
            {!loading && (
              <Typography
                variant="h6"
                sx={{ color: "#f1f1f1", fontFamily: "goodTime" }}
              >
                No NFT's Found!
              </Typography>
            )}
          </Box>
        );
      }
    }
  }

  return (
    <div style={containerStyle}>
      <div style={contentStyle}>
        <Grid container spacing={2}>
          <Grid item xs={2}>
            {" "}
            <Box
              sx={{ display: "flex", justifyContent: "start", pt: 3, ml: 1 }}
            >
              <Button
                size="large"
                sx={{ color: "white", pl: 4, py: 0 }}
                onClick={() => navigate(-1)}
              >
                <ArrowBackIcon fontSize="medium" sx={{ color: "white" }} />
              </Button>
            </Box>
          </Grid>

          <Grid item xs={8}>
            <>
              {!loading1 && (
                <Box sx={{}}>
                  <CollectionHeader Data={collectionHeader} loading={loading} />
                </Box>
              )}
            </>
          </Grid>

          <Grid item xs={2}></Grid>
        </Grid>
        {!loading1 && (
          <Divider
            variant="middle"
            sx={{ mb: 4, backgroundColor: "#AF22C8", mx: 6, mt: 2 }}
          />
        )}

        <Box sx={{ mb: 2 }}>{renderContent()}</Box>
      </div>
    </div>
  );
};
export default CollectionAllNfts;
