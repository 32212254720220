import React from "react";
import SingleMintingForm from "../../components/features/SingleMint/SingleMint";
import { Box } from "@mui/material";

const SingleMinting = () => {
  return (
    <Box>
      <SingleMintingForm />
    </Box>
  );
};

export default SingleMinting;
