import React from "react";
import {
  Grid,
  Box,
  Typography,
  IconButton,
  Avatar,
  Divider,
  Button,
  Tooltip,
} from "@mui/material";
import { format } from "date-fns";
import FacebookOutlinedIcon from "@mui/icons-material/FacebookOutlined";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import TwitterIcon from "@mui/icons-material/Twitter";
import EmailIcon from "@mui/icons-material/Email";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import CloseIcon from "@mui/icons-material/Close";
import { useDispatch } from "react-redux";
import {
  buyDirectNFT,
  buyNFT,
  sellOfferCancel,
} from "../../../services/redux/MyNftApiReducer/MyNftApiReducer";
import { setText } from "../../../services/redux/TextReducer/TextReducer";

const circleStyle = {
  color: "#AF22C8", // Icon color
  borderRadius: "50%", // Makes it circular
  border: "2px solid #AF22C8", // Border color and width
  width: 40, // Width of the button
  height: 40, // Height of the button
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  "& .MuiIconButton-label": {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
};

const circleStyleFont = {
  fontSize: "20px",
};
const InfoContent = ({ nftDetail }) => {
  const Owner = localStorage.getItem("accnum");
  const dispatch = useDispatch();
  const icons = [
    { label: "facebook", icon: FacebookOutlinedIcon },
    { label: "linkedin", icon: LinkedInIcon },
    { label: "twitter", icon: TwitterIcon },
    { label: "email", icon: EmailIcon },
    { label: "shopping", icon: ShoppingCartIcon },
    { label: "T", icon: () => <Typography variant="h6">T</Typography> }, // For custom typography icon
  ];

  const handleSellOfferCancel = (data) => {
    // Display an alert with the offer details
    dispatch(setText("Waiting xaman approval ..."));
    const apiBody = {
      offer_id: data.id,
    };

    // or if you prefer an array
    // console.log(data);/
    dispatch(sellOfferCancel(apiBody));
  };

  const handleBuyNFT = (nft) => {
    dispatch(setText("Waiting xaman approval ..."));

    // console.log(nft);
    const apiBody = {
      nft_id: nft.nft_id,
      price: nft.price,
      offer_id: nft.id,
    };
    dispatch(buyDirectNFT(apiBody));
  };

  return (
    <Box>
      {nftDetail?.sell_offers && nftDetail?.sell_offers?.length ? (
        <>
          <Grid
            container
            spacing={3}
            maxHeight={"400px"}
            sx={{ overflowY: "auto", overflowX: "hidden" }}
          >
            <Divider
              variant="middle"
              sx={{ mt: 1, backgroundColor: "#AF22C8", width: "100%" }}
            />
            {nftDetail?.sell_offers?.map((item, index) => (
              <Grid item xs={12} md={6} px={4}>
                {Owner === nftDetail.owner ? (
                  <Box
                    onClick={() => handleSellOfferCancel(item)}
                    sx={{
                      cursor: "pointer",
                      p: 1,
                      border: "solid 2px #af22c8",
                      borderRadius: 2,
                      display: "flex",
                      flexDirection: "row",
                      textAlign: "center",
                      width: "100%",
                      transition: "background-color 0.3s",

                      "&:hover": {
                        backgroundColor: "#af22c8",
                        boxShadow: "0 0 8px #af22c8",
                        color: "#FFF",
                      },
                    }}
                  >
                    <Grid
                      item
                      xs={12}
                      sx={{
                        justifyContent: "center",
                        textAlign: "center",
                      }}
                    >
                      <Grid
                        container
                        alignItems="center"
                        textAlign="center"
                        sx={{ justifyContent: "center" }}
                      >
                        <Grid
                          item
                          className="grid-item"
                          sx={{ justifyContent: "center" }}
                        >
                          <Typography
                            variant="body1"
                            sx={{ color: "#FFF", display: "flex", pr: 1 }}
                          >
                            {"Cancel sell offer for:"}
                          </Typography>
                        </Grid>
                        <Grid item>
                          <Typography
                            variant="body1"
                            sx={{ color: "#f1ed00", display: "flex" }}
                          >
                            {`${item?.price} xrp`}
                          </Typography>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Box>
                ) : Owner === null ? (
                  <>
                    <Tooltip title="You need to login first">
                      <Box
                        sx={{
                          p: 1,
                          border: "solid 2px grey",
                          borderRadius: 2,
                          display: "flex",
                          flexDirection: "row",
                          textAlign: "center",
                          width: "100%",
                          transition: "background-color 0.3s",
                        }}
                      >
                        <Grid
                          item
                          xs={12}
                          sx={{
                            justifyContent: "center",
                            textAlign: "center",
                          }}
                        >
                          <Grid
                            container
                            alignItems="center"
                            textAlign="center"
                            sx={{ justifyContent: "center" }}
                          >
                            <Grid
                              item
                              className="grid-item"
                              sx={{ justifyContent: "center" }}
                            >
                              <Typography
                                variant="body1"
                                sx={{ color: "#FFF", display: "flex", pr: 1 }}
                              >
                                {"Buy for:"}
                              </Typography>
                            </Grid>
                            <Grid item>
                              <Typography
                                variant="body1"
                                sx={{ color: "#f1ed00", display: "flex" }}
                              >
                                {`${item?.price} xrp`}
                              </Typography>
                            </Grid>
                          </Grid>
                        </Grid>
                      </Box>
                    </Tooltip>
                  </>
                ) : (
                  <Box
                    onClick={!Owner == "" && (() => handleBuyNFT(item))}
                    sx={{
                      cursor: "pointer",
                      p: 1,
                      border: "solid 2px #af22c8",
                      borderRadius: 2,
                      display: "flex",
                      flexDirection: "row",
                      textAlign: "center",
                      width: "100%",
                      transition: "background-color 0.3s",

                      "&:hover": {
                        backgroundColor: "#af22c8",
                        boxShadow: "0 0 8px #af22c8",
                        color: "#FFF",
                      },
                    }}
                  >
                    <Grid
                      item
                      xs={12}
                      sx={{
                        justifyContent: "center",
                        textAlign: "center",
                      }}
                    >
                      <Grid
                        container
                        alignItems="center"
                        textAlign="center"
                        sx={{ justifyContent: "center" }}
                      >
                        <Grid
                          item
                          className="grid-item"
                          sx={{ justifyContent: "center" }}
                        >
                          <Typography
                            variant="body1"
                            sx={{ color: "#FFF", display: "flex", pr: 1 }}
                          >
                            {"Buy for:"}
                          </Typography>
                        </Grid>
                        <Grid item>
                          <Typography
                            variant="body1"
                            sx={{ color: "#f1ed00", display: "flex" }}
                          >
                            {`${item?.price} xrp`}
                          </Typography>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Box>
                )}
              </Grid>
            ))}
          </Grid>
          {/* <Divider
            variant="middle"
            sx={{ my: , backgroundColor: "#AF22C8", width: "100%" }}
          /> */}
        </>
      ) : (
        <Box minHeight={"100px"}>
          <Typography sx={{ display: "flex", justifyContent: "center" }}>
            {"No sell offer created yet"}
          </Typography>
        </Box>
      )}
      <Box sx={{ color: "#AF22C8", mt: 2 }}>
        {/* <Box sx={{ display: "flex", justifyContent: "space-between" }}>
          {icons.map(({ label, icon: Icon }) => (
            <IconButton key={label} aria-label={label} sx={circleStyle}>
              <Icon sx={circleStyleFont} />
            </IconButton>
          ))}
        </Box> */}

        {/* <Typography variant="body1" sx={{ mt: 2, color: "white" }}>
          Pixie's 1st spinner collection with rewards to holders
        </Typography> */}
      </Box>
    </Box>
  );
};

export default InfoContent;
