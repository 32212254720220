import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  TextField,
  Grid,
  MenuItem,
  Typography,
  IconButton,
} from "@mui/material";
// import "./Mint.css";

// import styles from "./Mint.module.css";
import { useNavigate } from "react-router-dom";

import { toast } from "react-hot-toast";

import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import DeleteIcon from "@mui/icons-material/Delete";

import { useSelector, useDispatch } from "react-redux";
import BackgroundWrapper from "../../../layout/Shared/BackgroundWrapper/BackgroundWrapper";
import CustomLoader from "../../../shared/customLoader/customLoader";
import {
  addMintsAPI,
  addWalletAddressesAPI,
  resetAddMintsAPI,
  resetAddWalletAddressesAPI,
} from "../../../../services/redux/MyNftApiReducer/MyNftApiReducer";

import CustomInput from "../../../shared/customInput/customInput";
import { useForm } from "react-hook-form";
import { axiosRequestAuth } from "../../../../services/api/api";
import CustomInput2 from "../../../shared/customInput/customInput2";

// import CustomInput from "../../components/shared/customInput/customInput";

const AddMints = () => {
  const bgcolor = "rgba(104, 103, 103, 0.5)";
  const txtcolor = "#f1f1f1";
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [fields, setFields] = useState([""]); // Initial state with an empty string
  const [fieldsOrderId, setFieldsOrderId] = useState([""]); // Initial state with an empty string
  const [fieldsCollectionId, setFieldsCollectionId] = useState([""]);
  const [destination, setDestination] = useState();
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState({});

  const [collections, setCollections] = useState([]);
  const { addMintsData, loading: loader } = useSelector((state) => state.mynft);
  const walletAddressRegex = /(?:^|\s)([a-zA-Z0-9]{30,})(?=\s|$)/;

  const validate = () => {
    const newErrors = {};

    // Check for empty fields in fieldsCollectionId
    fieldsCollectionId.forEach((field, index) => {
      if (!field) {
        newErrors[`fieldsCollectionId_${index}`] =
          "Must select one collection.";
      }
    });

    // Check for empty and numeric fields in fieldsOrderId
    fieldsOrderId.forEach((field, index) => {
      if (!field) {
        newErrors[`fieldsOrderId_${index}`] = "Order ID is required.";
      } else if (isNaN(Number(field))) {
        newErrors[`fieldsOrderIdNumbers_${index}`] =
          "Order ID must be a number.";
      }
    });

    // Check for empty and invalid wallet addresses in fields
    fields.forEach((field, index) => {
      if (!field) {
        newErrors[`walletAddress_${index}`] = "Wallet address is required.";
      } else if (!walletAddressRegex.test(field)) {
        newErrors[`walletAddress_${index}`] = "Invalid wallet address.";
      }
    });

    setErrors(newErrors);

    if (Object.keys(newErrors).length > 0) {
      toast.error("Please fix the errors before submitting.");
    }

    return Object.keys(newErrors).length === 0;
  };
  /////////////////////////////////////////////////////////
  const handleChange = (index, value) => {
    const newFields = [...fields];
    newFields[index] = value;
    setFields(newFields);
  };
  const handleChange2 = (index, value) => {
    const newFields = [...fieldsOrderId];
    newFields[index] = value;
    // validate();
    setFieldsOrderId(newFields);
  };

  const handleChange3 = (index, value) => {
    const newFields = [...fieldsCollectionId];
    newFields[index] = value;
    setFieldsCollectionId(newFields);
  };

  const handleChangeDestination = (value) => {
    setDestination(value);
  };

  const handleAddField = () => {
    setFields([...fields, ""]);
    setFieldsOrderId([...fieldsOrderId, ""]);
    setFieldsCollectionId([...fieldsCollectionId, ""]);
  };

  const handleRemoveField = (index) => {
    if (fields?.length > 1) {
      const newFields = [...fields];
      newFields.splice(index, 1);
      setFields(newFields);
      const newFields2 = [...fieldsOrderId];
      newFields2.splice(index, 1);
      setFieldsOrderId(newFields2);
      const newFields3 = [...fieldsCollectionId];
      newFields3.splice(index, 1);
      setFieldsCollectionId(newFields3);
    }
  };

  function checkDuplicates(arr) {
    const uniqueSet = new Set(arr);
    return arr.length !== uniqueSet.size;
  }

  const handleSubmit = async (event) => {
    event.preventDefault();
    setLoading(true);
    if (!validate()) {
      setLoading(false);
      return;
    }

    let data = [];
    fields.map((item, index) =>
      data.push({
        collection_id: fieldsCollectionId[index],
        freeMints: parseInt(fieldsOrderId[index]),
        walletAddress: fields[index],
      })
    );
    console.log("function submit calling", data);
    dispatch(addMintsAPI({ free_mints: data }));
  };

  useEffect(() => {
    if (Object.keys(addMintsData?.data).length > 0) {
      if (addMintsData?.data?.status === true) {
        toast.success(addMintsData?.data?.message);
        dispatch(resetAddMintsAPI());
        navigate("/user-mints");
      } else if (addMintsData?.data?.status === false) {
        toast.error(addMintsData?.data?.message);
        dispatch(resetAddMintsAPI());
      }
    }
  }, [addMintsData?.data?.status]);

  useEffect(() => {
    setLoading(loader);
  }, [loader]);

  async function getCollections() {
    setLoading(true);
    try {
      const response = await axiosRequestAuth(
        "get",
        "v2/collection/dropdown-for-order"
      );
      setLoading(false);

      // toast.success(response.data.message);
      setCollections(response?.data?.data?.collections);
    } catch (error) {
      setLoading(false);
      //   setLoading(false);
    }
  }

  useEffect(() => {
    getCollections();
  }, []);

  return (
    <BackgroundWrapper>
      {loading ? (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            minHeight: "80vh", // Set minimum height to fill the entire viewport
          }}
        >
          <CustomLoader width={"60%"} text={"Please wait ..."} />
        </Box>
      ) : (
        <Box>
          <Grid container spacing={2}>
            <Grid item lg={2}>
              {" "}
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "start",
                  pt: 1,
                  ml: 1,
                  pl: 6,
                }}
              >
                <Button
                  size="large"
                  sx={{ color: txtcolor }}
                  onClick={() => navigate(-1)}
                >
                  <ArrowBackIcon fontSize="large" sx={{ color: txtcolor }} />
                </Button>
              </Box>
            </Grid>
            <Grid item lg={8}>
              <Box sx={{ display: "flex", justifyContent: "center", pt: 2 }}>
                <Typography
                  variant="h4"
                  sx={{ color: txtcolor, fontWeight: 600 }}
                >
                  Bulk Add Wallets
                </Typography>
              </Box>
            </Grid>
            <Grid item lg={2}></Grid>
          </Grid>

          <Grid
            container
            spacing={2}
            sx={{
              display: "flex",
              direction: "row",
              justifyContent: "center",
              pt: 2,
            }}
          >
            <Grid
              item
              xl={2}
              lg={2}
              sm={12}
              sx={{
                p: 2,
              }}
            />

            <Grid
              item
              xl={6}
              lg={8}
              sm={12}
              sx={{
                p: 2,
              }}
            >
              <Box>
                <form onSubmit={handleSubmit}>
                  <Grid
                    container
                    spacing={2}
                    sx={{
                      maxHeight: "70vh",
                      overflowY: "auto",
                      pb: 2,
                    }}
                  >
                    <Grid item xs={2}>
                      <Box sx={{ pt: 2 }}>
                        <Typography sx={{ color: "white" }}>
                          Collection
                        </Typography>
                      </Box>

                      <Box>
                        {fieldsCollectionId.map((field, index) => (
                          <Box
                            sx={{
                              display: "flex",
                              flexDirection: "column",
                              pb: 1,
                            }}
                          >
                            <CustomInput2
                              name="Collection"
                              bgcolor={bgcolor}
                              txtcolor={txtcolor}
                              isFullWidth={true}
                              options={collections}
                              index={index}
                              handleChange={handleChange3}
                              error={
                                errors[`fieldsCollectionId_${index}`]
                                  ? true
                                  : false
                              }
                            />
                          </Box>
                        ))}
                      </Box>
                    </Grid>
                    <Grid item xs={2}>
                      <Box sx={{ pt: 2 }}>
                        <Typography sx={{ color: "white" }}>Count</Typography>
                      </Box>

                      <Box>
                        {fieldsOrderId.map((field, index) => (
                          <Box
                            sx={{
                              display: "flex",
                              flexDirection: "column",
                              pb: 1,
                            }}
                          >
                            <TextField
                              required
                              // label={`Field ${index + 1}`}
                              size="small"
                              value={field}
                              onChange={(e) =>
                                handleChange2(index, e.target.value)
                              }
                              fullWidth
                              sx={{
                                "& .MuiOutlinedInput-root": {
                                  backgroundColor: bgcolor, // Background color when not active
                                  "&:hover fieldset": {
                                    borderColor: "purple", // Border color on hover
                                  },
                                  "&.Mui-focused fieldset": {
                                    borderColor: "purple", // Border color on focus
                                  },
                                },
                                "& .MuiInputBase-input": {
                                  color: txtcolor, // Text color
                                },
                                border:
                                  errors[`fieldsOrderId_${index}`] ||
                                  errors[`fieldsOrderIdNumbers_${index}`]
                                    ? "1px solid red"
                                    : "1px solid transparent", // Change border based on errors
                              }}
                            />

                            {/* <IconButton
                              onClick={() => handleRemoveField(index)}
                            >
                              <DeleteIcon sx={{ color: "red" }} />
                            </IconButton> */}
                            {errors[`fieldsOrderId_${index}`] && (
                              <Typography
                                variant="body2"
                                color="error"
                                sx={{ mt: 1 }}
                              >
                                {errors[`fieldsOrderId_${index}`]}
                              </Typography>
                            )}
                          </Box>
                        ))}
                      </Box>
                    </Grid>
                    <Grid item xs={8}>
                      <Box sx={{ pt: 2 }}>
                        <Typography sx={{ color: "white" }}>
                          Add Wallet Address
                        </Typography>
                      </Box>

                      <Box>
                        {fields.map((field, index) => (
                          <Box
                            sx={{
                              display: "flex",
                              justifyContent: "center",
                              pb: 1,
                            }}
                          >
                            <Box
                              sx={{
                                display: "flex",
                                flexDirection: "column",
                                width: "100%",
                              }}
                            >
                              <TextField
                                required
                                // label={`Field ${index + 1}`}
                                size="small"
                                value={field}
                                onChange={(e) =>
                                  handleChange(index, e.target.value)
                                }
                                fullWidth
                                sx={{
                                  "& .MuiOutlinedInput-root": {
                                    backgroundColor: bgcolor, // Background color when not active
                                    "&:hover fieldset": {
                                      borderColor: "purple", // Border color on hover
                                    },
                                    "&.Mui-focused fieldset": {
                                      borderColor: "purple", // Border color on focus
                                    },
                                  },
                                  "& .MuiInputBase-input": {
                                    color: txtcolor, // Text color
                                  },
                                  "& .MuiSelect-icon": {
                                    color: "white", // Color of the dropdown icon
                                  },
                                  border: errors[`walletAddress_${index}`]
                                    ? "1px solid red"
                                    : !errors[`walletAddress_${index}`] &&
                                      "1px solid transparent",
                                  "& .MuiOutlinedInput-root": {
                                    backgroundColor: bgcolor, // Background color when not active
                                    "&:hover fieldset": {
                                      borderColor: "purple", // Border color on hover
                                    },
                                    "&.Mui-focused fieldset": {
                                      borderColor: "purple", // Border color on focus
                                    },
                                  },
                                  "& .MuiInputBase-input": {
                                    color: txtcolor, // Text color
                                  },

                                  border: errors[`walletAddress_${index}`]
                                    ? "1px solid red"
                                    : !errors[`walletAddress_${index}`] &&
                                      "1px solid transparent",

                                  "& .MuiOutlinedInput-root": {
                                    backgroundColor: bgcolor, // Background color when not active
                                    "&:hover fieldset": {
                                      borderColor: "purple", // Border color on hover
                                    },
                                    "&.Mui-focused fieldset": {
                                      borderColor: "purple", // Border color on focus
                                    },
                                  },
                                  "& .MuiInputBase-input": {
                                    color: txtcolor, // Text color
                                  },
                                }}
                              />
                            </Box>

                            <Box>
                              <IconButton
                                onClick={() => handleRemoveField(index)}
                              >
                                <DeleteIcon sx={{ color: "red" }} />
                              </IconButton>
                            </Box>
                          </Box>
                        ))}
                      </Box>
                    </Grid>
                  </Grid>
                  <Box sx={{ display: "flex", mt: 1 }}>
                    <Box sx={{ mr: 2 }}>
                      <Button
                        variant="contained"
                        sx={{
                          width: "120px",
                          bgcolor: "#af22c8",
                          "&:hover": {
                            bgcolor: "#62136f", // Adjust the darkness level as needed
                          },
                        }}
                        onClick={handleAddField}
                      >
                        Add More
                      </Button>
                    </Box>
                    <Box>
                      <Button
                        variant="contained"
                        sx={{
                          width: "100px",
                          bgcolor: "#af22c8",
                          "&:hover": {
                            bgcolor: "#62136f", // Adjust the darkness level as needed
                          },
                        }}
                        type="submit"
                      >
                        Submit
                      </Button>
                    </Box>
                  </Box>
                </form>
              </Box>
            </Grid>
            <Grid
              item
              lg={6}
              xl={2}
              sm={12}
              sx={{
                p: 2,
              }}
            />
          </Grid>
        </Box>
      )}
    </BackgroundWrapper>
  );
};

export default AddMints;
