import React, { useEffect, useState } from "react";
import CustomTable from "../../../components/shared/customTable/customTable";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { imageURL } from "../../../services/api/api";
import { CloseOutlined } from "@mui/icons-material";
import CloseIcon from "@mui/icons-material/Close";
import BackgroundWrapper from "../../../components/layout/Shared/BackgroundWrapper/BackgroundWrapper";
import {
  Grid,
  Box,
  Typography,
  Button,
  Modal,
  Paper,
  Tabs,
  Tab,
  MenuItem,
  Menu,
  IconButton,
  FormControlLabel,
  Switch,
} from "@mui/material";
import { axiosRequestAuth } from "../../../services/api/api";
import CustomMuiButton from "../../../components/shared/customButton/customButton";
import toast from "react-hot-toast";

import { useNavigate } from "react-router-dom";
import AddIcon from "@mui/icons-material/Add";
import CustomModal from "../../../components/shared/customModal/customModal";
import TabPanel from "../../../utils/TabPanels/Tabpanels";
import CustomLoader from "../../../components/shared/customLoader/customLoader";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
const tabStyle = {
  fontSize: "16px", // Adjust the font size as needed
  fontWeight: 600,
  overflowY: "hidden",
};
const dropdownButtonStyle = {
  color: "white",
  backgroundColor: "rgba(104, 103, 103, 0.5)", // Greyish background color
};
const menuItemStyle = {
  fontSize: "1rem", // Larger font size
  padding: "12px 24px", // Increase padding for larger size
};

function AdminCarousel() {
  const [loading, setLoading] = useState(true);
  const [carouselCollection, setCarouselCollection] = useState();
  const [deleteId, setDeleteId] = useState();
  const [openModal, setOpenModal] = useState(false);
  const [carouselState, setCarouselState] = useState(false);
  const [collectionInfo, setCollectionInfo] = useState();
  const [openModalview, setOpenModalView] = useState(false);
  const [tabValue, setTabValue] = useState(1);
  const [selectedOption, setSelectedOption] = useState("All Collections");
  const [collectionFlag, setCollectionFlag] = useState("A");
  const [anchorEl, setAnchorEl] = useState(null);
  const [orderList, setOrderList] = useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuItemClick = (option) => {
    setSelectedOption(option);

    if (option === "All Collections") {
      setCollectionFlag("A");
    } else if (option === "USD Collections") {
      setCollectionFlag("B");
    } else if (option === "XRP Collections") {
      setCollectionFlag("C");
    } else if (option === "Carousel Collections") {
      setCollectionFlag("D");
    }
    setAnchorEl(null);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  // Example user data
  const navigate = useNavigate();

  const bgcolor = "rgba(104, 103, 103, 0.5)";
  const txtcolor = "#f1f1f1";
  // Handle the edit action
  const handleEditClick = (data) => {
    // Handle edit action for the selected user
    navigate(`/admin/collection-edit/${data?.id}`);
  };

  const handleViewClick = (data) => {
    // Handle edit action for the selected user

    setCollectionInfo(data);
    // deleteFeatured(data.is_carousel);
    setCarouselState(data?.is_carousel);
    setOpenModalView(true);
    // setDeleteId(data.id);
  };

  const handleDeleteClick = (data) => {
    // Handle edit action for the selected user

    // deleteFeatured(data.id);
    setOpenModal(true);
    setDeleteId(data?.id);
  };
  const handleSwitchChange = (event) => {
    setCarouselState(event.target.checked);
  };

  useEffect(() => {
    getAllCollection(collectionFlag);
  }, [collectionFlag]);

  async function handleSyncClick(id) {
    setLoading(true);
    try {
      const response = await axiosRequestAuth("post", "v2/collection/sync", {
        collection_id: id,
      });

      toast.success(response.data.message);
      setLoading(false);
    } catch (error) {
      setLoading(false);

      toast.error(error?.response?.data?.message);
    }
  }

  // async function getCarouselCollection() {
  //   setLoading(true);
  //   try {
  //     const response = await axiosRequestAuth("get", "v2/collection/carousel");

  //     setCarouselCollection(response?.data?.data?.collections);

  //     // toast.success(response.data.message);
  //     setLoading(false);
  //   } catch (error) {
  //     setLoading(false);

  //     toast.error(error?.response?.data?.message);
  //   }
  // }

  async function getAllCollection(flag) {
    setLoading(true);
    try {
      const response = await axiosRequestAuth("post", "v2/collection/all", {
        collection_type: flag,
      });

      setCarouselCollection(response?.data?.data?.collections);
      setOrderList(response?.data?.data?.order);
      // toast.success(response.data.message);
      setLoading(false);
    } catch (error) {
      setLoading(false);

      toast.error(error?.response?.data?.message);
    }
  }

  async function mainCarouselCollection() {
    setLoading(true);
    setOpenModalView(false);
    try {
      const response = await axiosRequestAuth(
        "post",
        `v2/collection/${collectionInfo?.id}`,
        {
          is_carousel: carouselState,
        }
      );
      getAllCollection(collectionFlag);
      toast.success(response?.data?.message);
      setLoading(false);
    } catch (error) {
      setLoading(false);

      toast.error(error?.response?.data?.message);
    }
  }

  const handleChange = (event, newValue) => {
    setTabValue(newValue);
  };
  const deleteCarousel = async () => {
    setLoading(true);
    try {
      const response = await axiosRequestAuth(
        "post",
        `v2/collection/delete/${deleteId}`
      );
      getAllCollection(collectionFlag);
      setOpenModal(false);
      toast.success(response?.data?.message);

      setDeleteId("");
      setLoading(false);
    } catch (error) {
      setOpenModal(false);
      setLoading(false);
      toast.error(error?.response?.data?.message);
    }
  };

  const columns = [
    { key: "name", label: "Name", dataType: "text" },
    { key: "thumbnail", label: "Thumbnail", dataType: "image" },
    { key: "createdAt", label: "Created At", dataType: "date" },
    { key: "taxon_id", label: "Taxon", dataType: "text" },
    { key: "is_carousel", label: "Carousel", dataType: "boolean" },
  ];

  const modalContent = (
    <Box p={2}>
      <Typography variant="h6" sx={{ color: txtcolor }}>
        Are you sure you want to delete?
      </Typography>
      {/* Any additional content you want in the modal */}
    </Box>
  );

  return (
    <BackgroundWrapper>
      <Grid container spacing={1} pl={5} pr={10}>
        <Grid item lg={2}>
          {" "}
          <Box sx={{ display: "flex", justifyContent: "start", pt: 3, ml: 1 }}>
            <Button
              size="large"
              sx={{ color: txtcolor }}
              onClick={() => navigate(-1)}
            >
              <ArrowBackIcon fontSize="large" sx={{ color: txtcolor }} />
            </Button>
          </Box>
        </Grid>
        <Grid item lg={8}>
          <Box sx={{ display: "flex", justifyContent: "center", pt: 2, pb: 4 }}>
            <Typography
              variant="h4"
              sx={{
                fontWeight: 600,
                color: "white",
                textAlign: "center",
                py: 2,
              }}
            >{`Collections`}</Typography>
          </Box>
        </Grid>
        <Grid item lg={2}>
          <Box sx={{ display: "flex", justifyContent: "center", pt: 3 }}>
            <Button
              size="large"
              variant="contained"
              sx={{
                bgcolor: "#af22c8",
                "&:hover": {
                  bgcolor: "#62136f", // Adjust the darkness level as needed
                },
              }}
              onClick={() => navigate("/collection-add")}
            >
              {/* <AddIcon fontSize="large" sx={{ color: txtcolor }} /> */}
              Add Collection
            </Button>
          </Box>
        </Grid>
      </Grid>
      <Grid container spacing={2}>
        <Grid xl={2} lg={2} md={2} sm={0} xs={0} />
        <Grid
          xl={8}
          lg={8}
          md={8}
          sm={12}
          xs={12}
          sx={{
            "::-webkit-scrollbar": {
              display: "none",
            },
            justifyContent: "center",
          }}
        >
          <Box
            sx={{
              justifyContent: "start",
              display: "flex",
              width: "auto",
              pb: 2,
            }}
          >
            <Button
              style={dropdownButtonStyle}
              aria-controls="simple-menu"
              aria-haspopup="true"
              onClick={handleClick}
              size="large"
              endIcon={<ArrowDropDownIcon />}
            >
              {selectedOption}
            </Button>
            <Menu
              id="simple-menu"
              anchorEl={anchorEl}
              keepMounted
              open={Boolean(anchorEl)}
              onClose={handleClose}
            >
              <MenuItem
                style={menuItemStyle}
                onClick={() => handleMenuItemClick("All Collections")}
              >
                All Collections
              </MenuItem>
              <MenuItem
                style={menuItemStyle}
                onClick={() => handleMenuItemClick("USD Collections")}
              >
                USD Collections
              </MenuItem>
              <MenuItem
                style={menuItemStyle}
                onClick={() => handleMenuItemClick("XRP Collections")}
              >
                XRP Collections
              </MenuItem>
              <MenuItem
                style={menuItemStyle}
                onClick={() => handleMenuItemClick("Carousel Collections")}
              >
                Carousel Collection
              </MenuItem>
            </Menu>
            {/* <Tabs
              onChange={handleChange}
              value={tabValue}
              variant="scrollable"
              scrollButtons="auto"
              TabScrollButtonProps={{
                sx: { color: "white" },
              }}
              TabIndicatorProps={{
                sx: { display: "none" },
              }}
              sx={{
                backgroundColor: "rgba(54, 69, 79, 0.5)",
                borderRadius: 2,
                fontSize: 14,
                "& button.Mui-selected": {
                  color: "white",
                  borderRadius: 2,
                  backgroundColor: "#af22c8",
                  transition: "background-color 0.3s ease-in-out", // Add smooth transition
                },
                "& button:not(.Mui-selected):hover": {
                  opacity: 0.8,
                  borderRadius: 2,
                  transition: "color 0.3s ease-in-out", // Add smooth transition
                },
              }}
            >
              <Tab
                sx={{
                  color: "white",
                }}
                label="All Collections"
                value={1}
                style={tabStyle}
              />
              <Tab
                sx={{
                  color: "white",
                }}
                label="Carousel Collections"
                value={2}
                style={tabStyle}
              />
            </Tabs> */}
          </Box>

          <Box>
            {!loading ? (
              <CustomTable
                type={"collection"}
                draggable={true}
                data={carouselCollection}
                columns={columns}
                onEditClick={handleEditClick}
                onDeleteClick={handleDeleteClick}
                onViewClick={handleViewClick}
                showDeleteIcon={false}
                actions={true}
                editable={true}
                sync={true}
                onSyncClick={handleSyncClick}
                showVisibleIcon={false}
                orderList={orderList}
                setOrderList={setOrderList}
              />
            ) : (
              <Box sx={{ display: "flex", justifyContent: "center", pt: 6 }}>
                <CustomLoader width="60%" text={"Please wait ..."} />
              </Box>
            )}
          </Box>
        </Grid>

        <Grid xl={2} lg={2} md={2} sm={0} xs={0} />
      </Grid>{" "}
      <Modal
        componentsProps={{
          backdrop: { style: { backgroundColor: "rgba(0, 0, 0, 0.8)" } },
        }}
        open={openModalview}
        onClose={() => setOpenModalView(false)}
      >
        <Box
          sx={{
            borderTopLeftRadius: "6px",
            borderTopRightRadius: "6px",
            display: "flex",
            justifyContent: "center",
            direction: "column",
            alignItems: "center",
            position: "absolute",
            left: "50%",
            top: "50%",
            transform: "translate(-50%, -50%)",
            pt: 6,
            flexDirection: "column",
          }}
        >
          <Paper
            elevation={2}
            sx={{
              bgcolor: "black",
              border: "solid 2px #af22c8",
              borderRadius: 4,
              color: "white",
              p: 4,
              width: "700px",
            }}
          >
            {/* <form onSubmit={handleSubmit(onSubmit)} style={{ width: "100%" }}> */}
            <Box
              sx={{
                justifyContent: "center",

                // background: "grey",
              }}
            >
              <Grid container>
                <Grid item xs={12}>
                  <Box
                    display={"flex"}
                    justifyContent={"space-between"}
                    alignItems={"center"}
                    sx={{ py: 0 }}
                  >
                    <Box />
                    <Box display={"flex"} textAlign={"center"} sx={{ pt: 1 }}>
                      <Typography
                        variant="h6"
                        sx={{ color: txtcolor }}
                      ></Typography>
                    </Box>
                    <Box justifyContent={"end"} sx={{ mt: -2, mr: -2 }}>
                      <IconButton
                        sx={{
                          bgcolor: "none",
                          color: "white",
                          border: "2px solid #af22c8",
                          borderRadius: "8px", // Adjust the value to make it more square-ish
                          padding: "2px", // Adjust padding as needed
                        }}
                        onClick={() => setOpenModalView(false)}
                      >
                        <CloseIcon />
                      </IconButton>
                    </Box>
                  </Box>
                </Grid>
              </Grid>
              <Grid container spacing={2} pt={2}>
                <Grid item lg={6} xl={6} md={6}>
                  <Box display={"flex"} justifyContent={"center"}>
                    <Box
                      sx={{
                        // background: "#af22c8",
                        height: 250,
                        justifyContent: "center",
                        textAlign: "center",

                        borderRadius: 4,
                        cursor: "pointer",
                        position: "relative",
                      }}
                      // onClick={() => SetDetail(data)}
                    >
                      <img
                        style={{
                          width: "100%",
                          height: "100%",
                          padding: 0,
                          cursor: "pointer",
                          objectFit: "contain",
                        }}
                        src={`${imageURL}${collectionInfo?.thumbnail}`}
                      />
                    </Box>
                  </Box>{" "}
                </Grid>
                <Grid item lg={6} xl={6} md={6}>
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "center",
                    }}
                  >
                    <Box>
                      <Typography variant="h6" sx={{ color: txtcolor }}>
                        {collectionInfo?.name}
                      </Typography>
                    </Box>
                  </Box>

                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "center",
                    }}
                    pt={2}
                  >
                    <Box>
                      <Typography
                        variant="h6"
                        sx={{ color: txtcolor, fontWeight: 600, pr: 1 }}
                      >
                        Taxon:{" "}
                      </Typography>
                    </Box>
                    <Box>
                      <Typography variant="h6" sx={{ color: txtcolor }}>
                        {collectionInfo?.taxon_id}
                      </Typography>
                    </Box>
                  </Box>

                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      pt: 2,
                      flexDirection: "row",
                    }}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                        pt: 0.5,
                      }}
                    >
                      <Typography
                        variant="h6"
                        sx={{ color: txtcolor, fontWeight: 400, pr: 2 }}
                      >
                        Carousel
                      </Typography>
                    </Box>
                    <Box sx={{ display: "flex", justifyContent: "center" }}>
                      <FormControlLabel
                        control={
                          <Switch
                            checked={carouselState}
                            onChange={handleSwitchChange}
                            // color={carouselState ? "#af22c8" : "default"}
                            sx={{
                              color: "#af22c8",
                              bgcolor: bgcolor,
                              borderRadius: 2,
                              borderRadius: 2,
                              "& .Mui-checked": {
                                color: "#af22c8",
                              },
                            }}
                          />
                        }
                        label={
                          <span style={{ paddingLeft: 4 }}>
                            {carouselState ? "Yes" : "No"}
                          </span>
                        }
                      />
                    </Box>
                  </Box>
                  <Box
                    sx={{ display: "flex", justifyContent: "center", pt: 10 }}
                  >
                    <Button
                      sx={{
                        width: "100px",
                        bgcolor: "#af22c8",
                        "&:hover": {
                          bgcolor: "#62136f", // Adjust the darkness level as needed
                        },
                        color: "white",
                      }}
                      onClick={mainCarouselCollection}
                    >
                      Update
                    </Button>
                  </Box>
                </Grid>
              </Grid>
            </Box>
          </Paper>
        </Box>
      </Modal>
      <CustomModal
        open={openModal}
        setOpenModal={setOpenModal}
        content={modalContent}
        onConfirm={deleteCarousel}
      />
    </BackgroundWrapper>
  );
}

export default AdminCarousel;
