import * as React from "react";

import { imageURL } from "../../../services/api/api";

import { Paper, Typography, Box, Card, Grid } from "@mui/material";

const MediaCard = ({ data, onConfirm }) => {
  let width = 320;

  return (
    <>
      <Card
        sx={{
          my: 2,
          background: "black",
          width: width,
          borderRadius: 4,
          cursor: "pointer",
          transition: "transform 0.3s",
          ":hover": {
            transform: "scale(1.05)",
            boxShadow: "0 0 8px #BF40BF",
          },
          border: "solid 2px #BF40BF ",
        }}
        onClick={() => onConfirm(data)}
      >
        <Paper sx={{ background: "black" }}>
          <Box
            sx={{
              pt: 1,

              justifyContent: "center",
              textAlign: "center",
            }}
          >
            <Box sx={{ px: 1, display: "flex", justifyContent: "center" }}>
              <img
                style={{
                  width: "100%",
                  height: 250,
                  padding: 0,
                  objectFit: "cover",
                }}
                src={`${imageURL}${data?.thumbnail}`}
                title="green iguana"
              />
            </Box>
            <Box sx={{ diaplay: "flex", textAlign: "center", pb: 2 }}>
              <Typography
                variant="h6"
                sx={{
                  color: "white",
                  fontWeight: 600,
                  py: 2,
                  fontFamily: "goodTime",
                }}
              >
                {data?.name}
              </Typography>
            </Box>
          </Box>
        </Paper>
      </Card>
    </>
  );
};

export default MediaCard;
