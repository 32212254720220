import React, { useEffect } from "react";

const ScrollToTopOnMount = () => {
  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to the top of the page on component mount
  }, []); // Empty dependency array means this effect will only run once after initial render

  return null; // This component doesn't render anything itself
};

export default ScrollToTopOnMount;
