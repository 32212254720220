import "./nft.css";
import React, { useState, useEffect } from "react";
import {
  Dialog,
  Grid,
  Button,
  Tab,
  Tabs,
  Box,
  useTheme,
  useMediaQuery,
  Typography,
  IconButton,
  DialogTitle,
  TextField,
  InputAdornment,
  Skeleton,
} from "@mui/material";

import { useSelector, useDispatch } from "react-redux";

import {
  resetbuyNFTState,
  burnNFT,
  buyNFT,
  freeTransferNFT,
  resetsellOfferCancelState,
  resetSellState,
  resetBuyOfferState,
  resetBurnState,
  getListedNft,
  getMyOffersNft,
  getNftWaitingMyApproval,
  resetTransferNftState,
  resetapproveOfferNft,
} from "../../../services/redux/MyNftApiReducer/MyNftApiReducer";
import { styled } from "@mui/material/styles";
import toast from "react-hot-toast";
import { useLocation } from "react-router-dom";
import CloseIcon from "@mui/icons-material/Close";
import FingerprintIcon from "@mui/icons-material/Fingerprint";
import SellIcon from "@mui/icons-material/PointOfSale"; // Replace with the appropriate icon
import TransferIcon from "@mui/icons-material/SwapHoriz"; // Replace with the appropriate icon
import OfferIcon from "@mui/icons-material/MonetizationOn"; // Placeholder for an appropriate icon
import CheckIcon from "@mui/icons-material/Check"; // For the tick icon
import placeholderImage from "../../../assets/Category/placeholder.png";

import LocalFireDepartmentIcon from "@mui/icons-material/LocalFireDepartment";
import InfoContent from "../TabsComponent/InfoContent";
import TokenInfo from "../TabsComponent/TokenInfo";
import HistoryTab from "../TabsComponent/HistoryTab";
import OffersTab from "../TabsComponent/OffersTab";
import { saleNFT } from "../../../services/redux/MyNftApiReducer/MyNftApiReducer";
import CustomTable from "../../shared/customTable/customTable";
import CustomLoader from "../../shared/customLoader/customLoader";
import {
  approveOfferState,
  recentlyForSaleNft,
} from "../../../services/redux/NftApiReducer/NftApiReducer";
import {
  resetText,
  setText,
} from "../../../services/redux/TextReducer/TextReducer";
import { setOffsetState } from "../../../services/redux/OffsetReducer/OffsetReducer";
import { imageURL } from "../../../services/api/api";

const TabPanel = (props) => {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
};

const NFTDialog = ({ open, onClose, imageUrl, nft, offersData }) => {
  console.log(imageUrl, "nftnft");

  const dispatch = useDispatch();
  const location = useLocation();

  const LoginUser = localStorage.getItem("accnum");
  const isUserOwner = nft?.owner === LoginUser;

  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));

  const [tabValue, setTabValue] = useState(0);
  const [showOfferInput, setShowOfferInput] = useState(false);

  const [showSellInput, setShowSellInput] = useState(false);

  const [showTransferinput, setShowTransferinput] = useState(false);

  const [offerValue, setOfferValue] = useState("");

  const [transferTo, setTransferTo] = useState("");
  const [customText, setCustomText] = useState("");
  const [openModal, setOpenModal] = useState(open);

  const [loadingPage, setLoadingPage] = useState(false);

  const { loading, approveOfferNft } = useSelector((state) => state.nft);
  const {
    loading: myNftLoading,
    transferNft,
    sellOfferCancelSatate,
    sellNFT,
    buyNFTState,
    burnNFTState,
  } = useSelector((state) => state.mynft);

  let ipfsImage = "";
  let ipfsVideo = "";
  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };
  const { pathname } = location;
  const { text } = useSelector((state) => state.text);

  const [visible, setVisible] = useState(false);
  const [animationStyle, setAnimationStyle] = useState({});

  const [visibleTransfer, setVisibleTransfer] = useState(false);

  const handleMakeOfferClick = () => {
    setShowOfferInput(true);
    setOfferValue("");
  };

  ipfsImage = nft?.imageUrl ? `${nft?.imageUrl}` : null;
  // if (nft?.metadata?.image?.startsWith("ipfs:")) {
  //   const cid = nft?.metadata?.image?.split("ipfs://")[1];
  // } else {
  //   ipfsImage = nft?.metadata?.image;
  // }

  if (nft?.metadata?.video?.startsWith("ipfs:")) {
    const cid = nft?.metadata?.video?.split("ipfs://")[1];
    ipfsVideo = `https://ipfs.io/ipfs/${cid}`;
  } else {
    ipfsVideo = nft?.metadata?.video;
  }

  const handleTransferClick = () => {
    setShowTransferinput(true);
    setTransferTo("");
  };
  const handleBurnNFT = () => {
    dispatch(setText("Waiting burning NFT..."));
    window.scrollTo(0, 0);
    // loading  screen
    // dispatch(setText("Waiting xaman approval ..."));
    // req.body={NFTokenID:NFTokenID}
    const input = { NFTokenID: nft.nftokenID };
    dispatch(burnNFT(input));
    //
  };

  const handleSellOfferClick = () => {
    setShowSellInput(true);
    setOfferValue("");
  };

  useEffect(() => {
    setOpenModal(open);
  }, [open]);
  console.log(sellNFT, "sellNFT");

  useEffect(() => {
    if (sellNFT?.data?.status) {
      refreshPage();

      onClose();
    } else if (sellNFT?.data?.status === false) {
      //toast.error(sellNFT?.data?.message);
      dispatch(resetSellState());
    }
  }, [sellNFT?.data?.status]);

  useEffect(() => {
    if (sellOfferCancelSatate?.data?.status === true) {
      // toast.success(sellOfferCancelSatate?.data?.message);
      dispatch(resetsellOfferCancelState());

      dispatch(resetText(""));
      refreshPage();

      onClose();
    } else if (sellOfferCancelSatate?.data?.status === false) {
      //  toast.error(sellOfferCancelSatate?.data?.message);
      dispatch(resetsellOfferCancelState());

      dispatch(resetText(""));
    }
  }, [sellOfferCancelSatate]);

  useEffect(() => {
    if (buyNFTState?.data?.status === true) {
      // toast.success(buyNFTState?.data?.message);
      dispatch(resetBuyOfferState());

      dispatch(resetText(""));
      refreshPage();
      onClose();
    } else if (buyNFTState?.data?.status === false) {
      // toast.error(buyNFTState?.data?.message);
      dispatch(resetBuyOfferState());
    }
  }, [buyNFTState]);

  useEffect(() => {
    if (burnNFTState?.data?.status === true) {
      // toast.success(buyNFTState?.data?.message);

      dispatch(resetText(""));
      refreshPage();
      dispatch(resetBurnState());
      onClose();
    }
  }, [burnNFTState]);

  useEffect(() => {
    if (transferNft?.data?.status === true) {
      // toast.success(transferNft?.data?.message);
      dispatch(resetTransferNftState());

      dispatch(resetText(""));
      refreshPage();
      onClose();
    } else if (transferNft?.data?.status === false) {
      // console.log(transferNft, "transferNft");
      // toast.error(transferNft?.data?.message);

      dispatch(resetText(""));
      onClose();
      dispatch(resetTransferNftState());
    }
  }, [transferNft]);

  useEffect(() => {
    if (approveOfferNft?.data?.status === true) {
      // toast.success(approveOfferNft?.data?.message);
      dispatch(resetapproveOfferNft());
      dispatch(resetText(""));
      refreshPage();
      onClose();
    } else if (approveOfferNft?.data?.status === false) {
      // console.log(approveOfferNft, "transferNft");
      // toast.error(approveOfferNft?.data?.message);
      dispatch(resetapproveOfferNft());
      dispatch(resetText(""));
      onClose();
    }
  }, [approveOfferNft]);

  const refreshPage = () => {
    if (pathname.includes("recently-for-sale")) {
      const apiData = {
        limit: 16,
        offset: 1,
      };
      dispatch(setOffsetState(1));
      setTimeout(() => {
        // dispatch(recentlyForSaleNft(apiData));
      }, 1000);
    } else if (pathname.includes("nfts/myListedNFTs")) {
      // dispatch(getListedNft());
      console.log("if I'm here nfts/myListedNFTs");
      dispatch(setOffsetState(1));
    } else if (pathname.includes("nfts/offers")) {
      // dispatch(getMyOffersNft());
      console.log("if I'm here nfts/offers");

      dispatch(setOffsetState(1));
    } else if (pathname.includes("nfts/approvals")) {
      // dispatch(getNftWaitingMyApproval());
      console.log("if I'm here nfts/approvals");
      dispatch(setOffsetState(1));
    } else if (pathname.includes("collection-nfts")) {
      // dispatch(getNftWaitingMyApproval());
      console.log("if I'm here collection-nfts");
      dispatch(setOffsetState(1));
    } else if (pathname.includes("nfts/myListedNFTs")) {
      // dispatch(getNftWaitingMyApproval());
      console.log("if I'm here nfts/myListedNFTs");
      dispatch(getListedNft());
    }
  };

  // useEffect(() => {
  //   if (approveOfferNft?.data?.status === true) {
  //     toast.success(approveOfferNft?.data?.message);
  //     refreshPage();
  //     dispatch(resetapproveOfferNft());
  //     onClose();
  //   } else if (approveOfferNft?.data?.status === false) {
  //     toast.error(approveOfferNft?.data?.message);
  //   }
  // }, [approveOfferNft]);

  // Custom styling for the buttons to match the design
  const AttributeButton = styled(Button)({
    border: "2px solid #AF22C8",
    borderRadius: "8px",
    // Apply more styles here to match your design
  });

  let forSaleSashUrl = null; // URL for the "For Sale" sash

  if (LoginUser === nft?.owner) {
    if (nft?.for_sale === true) {
      forSaleSashUrl = "https://xmart.art/img/sashes/left-for-sale.png";
    } else {
      forSaleSashUrl = null;
    }
  } else if (nft?.for_sale === false) {
    forSaleSashUrl = "https://xmart.art/img/sashes/left-make-an-offer.png";
  } else if (nft?.for_sale === true) {
    forSaleSashUrl = "https://xmart.art/img/sashes/left-for-sale.png";
  }

  useEffect(() => {
    // Set loadingPage to true if either myNftLoading or nftLoading is true
    setLoadingPage(
      myNftLoading || loading || transferNft.loading || sellNFT.loading
    );
  }, [myNftLoading, loading, transferNft.loading, sellNFT.loading]);

  const handleOfferCancel = () => {
    console.log("Make an offer");
    setShowOfferInput(false);

    setOfferValue(""); // Reset the offer value if needed
  };

  const handleTransferCancel = () => {
    setShowTransferinput(false);
    setTransferTo("");
    setVisible(false);
    // Reset the offer value if needed
  };

  const handleSellCancel = () => {
    setShowSellInput(false);
    setOfferValue(""); // Reset the offer value if needed
  };

  const handleSellClick = () => {
    setOfferValue("");
    const updatedNft = { nft_id: nft.id, price: parseFloat(offerValue) };
    // const updatedNft = { nft_id: nft.id, price: parseInt(offerValue) };
    dispatch(setText("Waiting xaman approval ..."));
    window.scrollTo(0, 0);
    dispatch(saleNFT(updatedNft));
  };

  const handleTransfer = () => {
    const transferData = { nft_id: nft.id, destination: transferTo };
    dispatch(setText("Waiting xaman approval ..."));
    setTransferTo("");
    setVisible(false);
    setOpenModal(false);
    setShowOfferInput(false);
    dispatch(freeTransferNFT(transferData));

    // console.log({ transferData });
  };

  const handleOfferClick = () => {
    dispatch(setText("Waiting xaman approval ..."));

    setOfferValue("");
    const updatedNft = { nft_id: nft.id, price: parseFloat(offerValue) };
    //const updatedNft = { nft_id: nft.id, price: parseInt(offerValue) };
    dispatch(buyNFT(updatedNft));
  };

  useEffect(() => {
    if (offerValue) {
      setVisible(true);
      setAnimationStyle({
        animation: "slideInUp 0.5s ease-out forwards",
      });
    } else if (visible) {
      setAnimationStyle({
        animation: "slideDown 0.5s ease-out forwards",
      });

      // Wait for animation to complete before hiding the div
      const timeout = setTimeout(() => {
        setVisible(false);
      }, 500);

      return () => clearTimeout(timeout);
    }
  }, [offerValue, visible]);

  useEffect(() => {
    if (transferTo) {
      setVisibleTransfer(true);
      setAnimationStyle({
        animation: "slideInUp 0.5s ease-out forwards",
      });
    } else if (visibleTransfer) {
      setAnimationStyle({
        animation: "slideDown 0.5s ease-out forwards",
      });

      // Wait for animation to complete before hiding the div
      const timeout = setTimeout(() => {
        setVisibleTransfer(false);
      }, 500);

      return () => clearTimeout(timeout);
    }
  }, [transferTo, visibleTransfer]);

  console.log(approveOfferNft, "approveOfferNft");

  return (
    <>
      {loadingPage === true ? (
        <Box
          sx={{
            position: "fixed", // Or 'absolute' if you want it relative to its nearest positioned ancestor
            top: 0,
            left: 0,
            width: "100vw",
            height: "100vh",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            backgroundColor: "rgba(0, 0, 0, 0.8)", // Optional: adds a white translucent background
            zIndex: 1000, // Ensure it's above all other content
          }}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              minHeight: "80vh", // Set minimum height to fill the entire viewport
            }}
          >
            <CustomLoader
              width={"60%"}
              text={text || text !== "" ? text : "Please wait ..."}
            />{" "}
          </Box>
        </Box>
      ) : (
        <Dialog
          fullScreen={fullScreen}
          open={openModal}
          onClose={onClose}
          fullWidth
          maxWidth="lg"
        >
          <DialogTitle
            sx={{
              m: 0,
              p: 2,
              background: `linear-gradient(
                    360deg,
                    #000 0%,        /* Start with black at 0% */
                    #000 5%,       /* Gradually transition to black at 40% */
                    #731686 70%,    /* Start the color transition at 60% */
                    #731686 100%    /* Continue with the final color until 100% */
                )`,
              color: "white",
              display: "flex",
              justifyContent: "space-between", // Space between the items
              alignItems: "center",
            }}
          >
            {/* HEADING  */}
            {!fullScreen && (
              // This will only show on medium devices or larger (desktops)
              <Typography
                variant="h4"
                sx={{ fontSize: "1.6rem", fontFamily: "Good Times" }}
              >
                {nft?.metadata?.collection?.name} / {nft?.metadata?.name}
              </Typography>
            )}
            {fullScreen && (
              // This will show on small devices (below medium)
              <Typography
                variant="h6"
                sx={{ fontSize: "1.6rem", fontFamily: "Good Times" }}
              ></Typography>
            )}

            {/* HEADING  */}
            <IconButton
              aria-label="close"
              onClick={onClose}
              sx={{
                position: "absolute",
                right: 8,
                top: "10px",
                color: "white",
                backgroundColor: "#AF22C8",
                border: "3px solid white",
                borderRadius: "50%",
                // Adjust icon size by changing the fontSize
                fontSize: "1rem", // Smaller icon size
              }}
            >
              <CloseIcon sx={{ fontSize: "inherit" }} />
            </IconButton>
          </DialogTitle>
          <Box
            sx={{
              p: 2,
              backgroundColor: "rgba(0, 0, 0, 0.87)",
              color: "white",
            }}
          >
            <Grid container spacing={2}>
              <Grid item xs={12} md={5} sx={{ position: "relative" }}>
                {/* "For Sale" sash */}
                {forSaleSashUrl && (
                  <Box
                    sx={{ position: "absolute", top: 5, left: 4, zIndex: 2 }}
                  >
                    <img
                      src={forSaleSashUrl}
                      style={{ width: "120px", height: "120px" }}
                    />
                  </Box>
                )}
                {/* NFT image */}
                {nft?.metadata?.video ? (
                  <video
                    style={{
                      width: "100%",
                      height: "400px",
                      objectFit: "cover",
                      borderRadius: "5px",
                    }}
                    autoPlay
                    loop
                    muted
                  >
                    <source src={ipfsVideo} type="video/mp4" />
                    Your browser does not support the video tag.
                  </video>
                ) : (
                  <img
                    src={ipfsImage || placeholderImage}
                    alt="NFT"
                    style={{
                      width: "100%",
                      height: "400px",
                      objectFit: ipfsImage ? "cover" : "contain",
                      borderRadius: "5px",
                    }}
                  />
                )}
                {/* //////////////////// buttons /////////////////// */}
                {LoginUser && (
                  <Grid container spacing={2} sx={{ mt: 1, mb: 2 }}>
                    {isUserOwner ? (
                      <>
                        <Grid item xs={12}>
                          {!showSellInput && (
                            <Button
                              variant="contained"
                              startIcon={<SellIcon />}
                              fullWidth
                              onClick={handleSellOfferClick}
                              sx={{
                                backgroundColor: "#AF22C8",
                                fontFamily: "goodTime", // Same as above
                                color: "white",
                                "&:hover": {
                                  backgroundColor: "#AF22C8",
                                },
                                borderRadius: "20px",
                                textTransform: "none",
                              }}
                            >
                              Sell this NFT
                            </Button>
                          )}
                          {showSellInput && (
                            <Grid item xs={12}>
                              <TextField
                                fullWidth
                                type="number"
                                label="Your Sell Price"
                                placeholder="Enter your offer"
                                value={offerValue}
                                onChange={(e) => setOfferValue(e.target.value)}
                                onKeyPress={(e) => {
                                  if (e.key === "e" || e.key === "E") {
                                    e.preventDefault();
                                  }
                                }}
                                InputProps={{
                                  style: { color: "white" },
                                  inputProps: { min: 0 },
                                  endAdornment: (
                                    <InputAdornment position="end">
                                      <IconButton onClick={handleSellCancel}>
                                        <CloseIcon sx={{ color: "white" }} />
                                      </IconButton>
                                    </InputAdornment>
                                  ),
                                }}
                                sx={{
                                  "& label.Mui-focused": {
                                    color: "white",
                                  },
                                  "& .MuiOutlinedInput-root": {
                                    "& fieldset": {
                                      borderColor: "#AF22C8",
                                    },
                                    "&:hover fieldset": {
                                      borderColor: "#AF22C8",
                                    },
                                    "&.Mui-focused fieldset": {
                                      borderColor: "#AF22C8",
                                    },
                                  },
                                  "& .MuiInputLabel-root": {
                                    // Label color
                                    color: "white",
                                  },
                                  "& .MuiInputBase-input": {
                                    // Input text color
                                    color: "white",
                                  },
                                  "& .MuiInputBase-input::placeholder": {
                                    // Placeholder text color
                                    color: "white",
                                    opacity: 1,
                                  },
                                }}
                              />
                            </Grid>
                          )}
                        </Grid>

                        {!showTransferinput && (
                          <Grid item xs={12}>
                            <Button
                              variant="contained"
                              startIcon={<TransferIcon />}
                              fullWidth
                              onClick={handleTransferClick}
                              sx={{
                                backgroundColor: "#AF22C8",
                                fontFamily: "goodTime", // Same as above
                                color: "white",
                                "&:hover": {
                                  backgroundColor: "#AF22C8",
                                },
                                borderRadius: "20px",
                                textTransform: "none",
                              }}
                            >
                              Transfer NFT
                            </Button>
                          </Grid>
                        )}

                        {nft?.flags?.burnable && nft?.issuer != null && (
                          <Grid item xs={12}>
                            <Button
                              variant="contained"
                              startIcon={<LocalFireDepartmentIcon />}
                              fullWidth
                              onClick={handleBurnNFT}
                              sx={{
                                backgroundColor: "#AF22C8",
                                fontFamily: "goodTime", // Same as above
                                color: "white",
                                "&:hover": {
                                  backgroundColor: "#AF22C8",
                                },
                                borderRadius: "20px",
                                textTransform: "none",
                              }}
                            >
                              Burn this NFT
                            </Button>
                          </Grid>
                        )}
                        {showTransferinput && (
                          <Grid item xs={12}>
                            <TextField
                              fullWidth
                              label="User Account"
                              placeholder="Enter user Account"
                              value={transferTo}
                              onChange={(e) => setTransferTo(e.target.value)}
                              onKeyPress={(e) => {
                                if (e.key === "e" || e.key === "E") {
                                  e.preventDefault();
                                }
                              }}
                              InputProps={{
                                style: { color: "white" },
                                endAdornment: (
                                  <InputAdornment position="end">
                                    <IconButton onClick={handleTransferCancel}>
                                      <CloseIcon sx={{ color: "white" }} />
                                    </IconButton>
                                  </InputAdornment>
                                ),
                              }}
                              sx={{
                                "& label.Mui-focused": {
                                  color: "white",
                                },
                                "& .MuiOutlinedInput-root": {
                                  "& fieldset": {
                                    borderColor: "#AF22C8",
                                  },
                                  "&:hover fieldset": {
                                    borderColor: "#AF22C8",
                                  },
                                  "&.Mui-focused fieldset": {
                                    borderColor: "#AF22C8",
                                  },
                                },
                                "& .MuiInputLabel-root": {
                                  // Label color
                                  color: "white",
                                },
                                "& .MuiInputBase-input": {
                                  // Input text color
                                  color: "white",
                                },
                                "& .MuiInputBase-input::placeholder": {
                                  // Placeholder text color
                                  color: "white",
                                  opacity: 1,
                                },
                              }}
                            />
                          </Grid>
                        )}
                      </>
                    ) : (
                      <>
                        {!nft?.is_sold &&
                          // <Grid item xs={12}>
                          //   <Button
                          //     variant="contained"
                          //     startIcon={<SellIcon />}
                          //     fullWidth
                          //     onClick={handleBuyClick}
                          //     sx={{
                          //       backgroundColor: "#AF22C8",
                          //       fontFamily: "goodTime", // Same as above
                          //       color: "white",
                          //       "&:hover": {
                          //         backgroundColor: "#AF22C8",
                          //       },
                          //       borderRadius: "20px",
                          //       textTransform: "none",
                          //     }}
                          //   >
                          //     Buy Now
                          //   </Button>
                          //   {/* Show this text input when the state is true */}
                          // </Grid>
                          ""}
                        <Grid item xs={12}>
                          {/* Conditional rendering for making an offer */}
                          <Grid item xs={12}>
                            {!showOfferInput && (
                              <Button
                                variant="contained"
                                startIcon={<TransferIcon />}
                                fullWidth
                                onClick={handleMakeOfferClick}
                                sx={{
                                  backgroundColor: "#AF22C8",
                                  fontFamily: "goodTime", // Same as above
                                  color: "white",
                                  "&:hover": {
                                    backgroundColor: "#AF22C8",
                                  },
                                  borderRadius: "20px",
                                  textTransform: "none",
                                }}
                              >
                                Make An Offer
                              </Button>
                            )}
                          </Grid>
                          {showOfferInput && (
                            <Grid item xs={12}>
                              <TextField
                                fullWidth
                                type="number"
                                label="Your Offer"
                                placeholder="Enter your offer"
                                value={offerValue}
                                onChange={(e) => setOfferValue(e.target.value)}
                                onKeyPress={(e) => {
                                  if (e.key === "e" || e.key === "E") {
                                    e.preventDefault();
                                  }
                                }}
                                InputProps={{
                                  style: { color: "white" },
                                  inputProps: { min: 0 },
                                  endAdornment: (
                                    <InputAdornment position="end">
                                      <IconButton onClick={handleOfferCancel}>
                                        <CloseIcon sx={{ color: "white" }} />
                                      </IconButton>
                                    </InputAdornment>
                                  ),
                                }}
                                sx={{
                                  "& label.Mui-focused": {
                                    color: "white",
                                  },
                                  "& .MuiOutlinedInput-root": {
                                    "& fieldset": {
                                      borderColor: "#AF22C8",
                                    },
                                    "&:hover fieldset": {
                                      borderColor: "#AF22C8",
                                    },
                                    "&.Mui-focused fieldset": {
                                      borderColor: "#AF22C8",
                                    },
                                  },
                                  "& .MuiInputLabel-root": {
                                    // Label color
                                    color: "white",
                                  },
                                  "& .MuiInputBase-input": {
                                    // Input text color
                                    color: "white",
                                  },
                                  "& .MuiInputBase-input::placeholder": {
                                    // Placeholder text color
                                    color: "white",
                                    opacity: 1,
                                  },
                                }}
                              />
                            </Grid>
                          )}
                        </Grid>
                      </>
                    )}
                  </Grid>
                )}
                {/* <Grid container spacing={2} sx={{ mt: 1, mb: 2 }}>
                  <CustomTable
                    data={dummyData}
                    editable={false}
                    columns={columns}
                    onEditClick={handleEditClick}
                    onDeleteClick={handleDeleteClick}
                    actions={true}
                  />
                </Grid> */}
              </Grid>
              {/* Attibutes */}
              <Grid item xs={12} md={7}>
                <Grid container spacing={2} sx={{ p: 2, mb: 2 }}>
                  {nft?.metadata?.attributes &&
                  nft?.metadata?.attributes?.length > 0 ? (
                    nft?.metadata?.attributes.map((attr, index) => (
                      <Grid item xs={6} md={4} key={index}>
                        <AttributeButton fullWidth>
                          <div style={{ padding: "6px" }}>
                            <div
                              style={{
                                color: "#AF22C8",
                                fontSize: "18px",
                                fontFamily: "Good Times",
                                textTransform: "capitalize",
                              }}
                            >
                              {attr?.trait_type
                                ? attr?.trait_type?.length > 9
                                  ? attr?.trait_type
                                      ?.substring(0, 1)
                                      .toUpperCase() +
                                    attr?.trait_type
                                      ?.substring(1, 9)
                                      ?.toLowerCase() +
                                    "..."
                                  : attr?.trait_type
                                      ?.substring(0, 1)
                                      .toUpperCase() +
                                    attr?.trait_type
                                      ?.substring(1)
                                      ?.toLowerCase()
                                : ""}
                            </div>
                            <div
                              style={{
                                color: "white",
                                fontSize: "15px",
                                fontFamily: "Good Times",
                                textTransform: "capitalize",
                              }}
                            >
                              {attr?.value?.length > 9
                                ? attr?.value?.substring(0, 9)?.toLowerCase() +
                                  "..."
                                : attr?.value?.toLowerCase()}
                            </div>
                          </div>
                        </AttributeButton>
                      </Grid>
                    ))
                  ) : (
                    // Optionally render something else if attributes are null or empty
                    <div>No attributes available</div>
                  )}
                </Grid>
                <Tabs
                  sx={{ p: 1, mb: 1, ml: 1 }}
                  value={tabValue}
                  onChange={handleTabChange}
                  variant="fullWidth"
                  TabIndicatorProps={{ style: { display: "none" } }} // Hide the underline
                >
                  <Tab
                    label="Sell Offers"
                    sx={{
                      backgroundColor: tabValue === 0 ? "#AF22C8" : "inherit",
                      color: tabValue === 0 ? "white !important" : "#AF22C8",
                      borderRadius: "3px",
                      fontFamily: "Good Times",
                    }}
                  />
                  <Tab
                    label="Offers"
                    sx={{
                      backgroundColor: tabValue === 1 ? "#AF22C8" : "inherit",
                      color: tabValue === 1 ? "white !important" : "#AF22C8",
                      borderRadius: "3px",
                      fontFamily: "Good Times",
                    }}
                  />

                  <Tab
                    label="Token"
                    sx={{
                      backgroundColor: tabValue === 2 ? "#AF22C8" : "inherit",
                      color: tabValue === 2 ? "white !important" : "#AF22C8",
                      borderRadius: "3px",
                      fontFamily: "Good Times",
                    }}
                  />
                  {/* <Tab
                    label="History"
                    sx={{
                      backgroundColor: tabValue === 3 ? "#AF22C8" : "inherit",
                      color: tabValue === 3 ? "white !important" : "#AF22C8",
                      borderRadius: "3px",
                      fontFamily: "Good Times",
                    }}
                  /> */}
                </Tabs>
                {/* Content  of Tabs */}
                <TabPanel value={tabValue} index={0}>
                  <InfoContent nftDetail={nft} onClose={onClose} />
                </TabPanel>
                <TabPanel value={tabValue} index={1}>
                  <Grid item xs={12}>
                    <OffersTab data={offersData} onClose={onClose} />
                  </Grid>
                </TabPanel>
                <TabPanel value={tabValue} index={2}>
                  <TokenInfo nftDetail={nft} />
                </TabPanel>

                {/* <TabPanel value={tabValue} index={3}>
                  <HistoryTab nftDetail={nft} />
                </TabPanel> */}

                {/* Content of Tabs */}
              </Grid>
            </Grid>
          </Box>
        </Dialog>
      )}

      {/* APPROVE IN WALLET COMPONENT */}
      <div>
        {visible ? (
          <div
            style={{
              position: "fixed",
              left: 0,
              bottom: 0,
              width: "100%",
              zIndex: 99999999,
              ...animationStyle,
            }}
          >
            <Button
              sx={{
                background: "#AF22C8",
                width: "100%", // Set the button width to 100%
                textAlign: "center",
                p: 2,
                color: "white", // Text color
                // You can add more styles here if needed
                "&:hover": {
                  bgcolor: "#AF22C8", // Keeps the background color same on hover
                  // Add any additional hover styles here
                },
              }}
              onClick={isUserOwner ? handleSellClick : handleOfferClick}
            >
              <FingerprintIcon sx={{ mr: 1 }} />
              Approve in Wallet
            </Button>
          </div>
        ) : (
          ""
        )}
      </div>
      <div>
        {visibleTransfer && (
          <div
            style={{
              position: "fixed",
              left: 0,
              bottom: 0,
              width: "100%",
              zIndex: 99999999,
              ...animationStyle,
            }}
          >
            <Button
              sx={{
                background: "#AF22C8",
                width: "100%", // Set the button width to 100%
                textAlign: "center",
                p: 2,
                color: "white", // Text color
                // You can add more styles here if needed
                "&:hover": {
                  bgcolor: "#AF22C8", // Keeps the background color same on hover
                  // Add any additional hover styles here
                },
              }}
              onClick={handleTransfer}
            >
              <FingerprintIcon sx={{ mr: 1 }} />
              Approve from Wallet
            </Button>
          </div>
        )}
      </div>
    </>
  );
};

export default NFTDialog;
