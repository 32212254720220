import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

import { axiosRequestAuth, axiosRequest } from "../../api/api";

export const getNftDetail = createAsyncThunk(
  "nft/getNftDetail",
  async (nftData, { rejectWithValue }) => {
    try {
      // Assuming saleDetails contains 'user_account' and 'offset'
      const response = await axiosRequestAuth(
        "post",
        "v2/nft/details",
        nftData
      );
      // Check if the response is successful
      // (This depends on how your axiosRequestAuth is structured)

      //console.log("API getNftDetail", response);
      if (response.status !== 200) {
        throw new Error("API request failed");
      }
      return response.data; // Assuming the response data is the payload you want
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const recentlyForSaleNft = createAsyncThunk(
  "nft/recentlyForSale",
  async (dataDetails, { rejectWithValue }) => {
    try {
      //console.log(dataDetails, "dataDetails");
      // Assuming saleDetails contains 'user_account' and 'offset'
      const response = await axiosRequest(
        "get",
        "v2/sell/nft-all",
        null,
        dataDetails
      );
      // Check if the response is successful
      // (This depends on how your axiosRequestAuth is structured)

      //console.log("API recentlyForSaleNft", response);
      if (response.status !== 200) {
        throw new Error("API request failed");
      }
      return response.data; // Assuming the response data is the payload you want
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const getFeaturedNft = createAsyncThunk(
  "nft/getFeaturedNft",
  async (dataDetails, { rejectWithValue }) => {
    try {
      // Assuming saleDetails contains 'user_account' and 'offset'
      const response = await axiosRequest(
        "get",
        "featured/dashboard",
        null,
        dataDetails
      );
      // Check if the response is successful
      // (This depends on how your axiosRequestAuth is structured)

      //console.log("API getFeaturedNft", response);
      if (response.status !== 200) {
        throw new Error("API request failed");
      }
      return response.data; // Assuming the response data is the payload you want
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const approveOffer = createAsyncThunk(
  "nft/approveOffer",
  async (combinedData, { rejectWithValue }) => {
    try {
      // Assuming saleDetails contains 'user_account' and 'offset'
      const response = await axiosRequestAuth(
        "post",
        "v2/sell/nft/accept-offer",
        combinedData
      );
      // Check if the response is successful
      // (This depends on how your axiosRequestAuth is structured)

      //console.log("API approveOffer", response);
      if (response.status !== 200) {
        throw new Error("API request failed");
      }
      return response.data; // Assuming the response data is the payload you want
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const getAllCollectionNFT = createAsyncThunk(
  "nft/collectionNft",
  async (combinedData, { rejectWithValue }) => {
    try {
      // Assuming saleDetails contains 'user_account' and 'offset'
      const response = await axiosRequest(
        "post",
        "collection/collection-nfts",
        combinedData
      );
      // Check if the response is successful
      // (This depends on how your axiosRequestAuth is structured)

      //console.log("API getAllCollectionNFT", response);
      if (response.status !== 200) {
        throw new Error("API request failed");
      }
      return response.data; // Assuming the response data is the payload you want
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const getAllCollectionNFTVanity = createAsyncThunk(
  "nft/collectionNftVanity",
  async (combinedData, { rejectWithValue }) => {
    try {
      // Assuming saleDetails contains 'user_account' and 'offset'
      const response = await axiosRequest(
        "post",
        "v2/collection/vanity-collection-nfts",
        combinedData
      );
      // Check if the response is successful
      // (This depends on how your axiosRequestAuth is structured)

      //console.log("API getAllCollectionNFTVanity", response);
      if (response.status !== 200) {
        throw new Error("API request failed");
      }
      return response.data; // Assuming the response data is the payload you want
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const getAllCollection = createAsyncThunk(
  "nft/getAllCollection",
  async (body, { rejectWithValue }) => {
    try {
      // Assuming saleDetails contains 'user_account' and 'offset'
      const response = await axiosRequestAuth(
        "post",
        "v2/collection/all",
        body
      );
      // Check if the response is successful
      // (This depends on how your axiosRequestAuth is structured)

      //console.log("API getAllCollection", response);
      if (response.status !== 200) {
        throw new Error("API request failed");
      }
      return response.data; // Assuming the response data is the payload you want
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const getUSDCollection = createAsyncThunk(
  "nft/getUSDCollection",
  async (body, { rejectWithValue }) => {
    try {
      // Assuming saleDetails contains 'user_account' and 'offset'
      const response = await axiosRequestAuth("get", "v2/collection/usd", body);
      // Check if the response is successful
      // (This depends on how your axiosRequestAuth is structured)

      //console.log("API getUSDCollection", response);
      if (response.status !== 200) {
        throw new Error("API request failed");
      }
      return response.data; // Assuming the response data is the payload you want
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const getUSDCollectionDetails = createAsyncThunk(
  "nft/getUSDCollectionDetails",
  async (body, { rejectWithValue }) => {
    try {
      // Assuming saleDetails contains 'user_account' and 'offset'
      const response = await axiosRequestAuth(
        "post",
        "v2/collection/usd-collection-details",
        body
      );
      // Check if the response is successful
      // (This depends on how your axiosRequestAuth is structured)

      //console.log("API getUSDCollectionDetails", response);
      if (response.status !== 200) {
        throw new Error("API request failed");
      }
      return response.data; // Assuming the response data is the payload you want
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const usdCollectionMint = createAsyncThunk(
  "nft/usdCollectionMint",
  async (body, { rejectWithValue }) => {
    try {
      // Assuming saleDetails contains 'user_account' and 'offset'
      const response = await axiosRequestAuth("post", "v2/usd-mint", body);
      // Check if the response is successful
      // (This depends on how your axiosRequestAuth is structured)

      //console.log("API usdCollectionMint", response);
      if (response.status !== 200) {
        throw new Error("API request failed");
      }
      return response.data; // Assuming the response data is the payload you want
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const gameCollectionMint = createAsyncThunk(
  "nft/gameCollectionMint",
  async (body, { rejectWithValue }) => {
    try {
      // Assuming saleDetails contains 'user_account' and 'offset'
      const response = await axiosRequestAuth("post", "v2/usd-mint/game", body);
      // Check if the response is successful
      // (This depends on how your axiosRequestAuth is structured)

      //console.log("API gameCollectionMint", response);
      if (response.status !== 200) {
        throw new Error("API request failed");
      }
      return response.data; // Assuming the response data is the payload you want
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const getCollectionById = createAsyncThunk(
  "nft/getCollectionById",
  async (body, { rejectWithValue }) => {
    //console.log(body, "body");
    try {
      // Assuming saleDetails contains 'user_account' and 'offset'
      const response = await axiosRequestAuth(
        "post",
        "v2/collection/nfts",
        body
      );
      // Check if the response is successful
      // (This depends on how your axiosRequestAuth is structured)

      //console.log("API getCollectionById", response);
      if (response.status !== 200) {
        throw new Error("API request failed");
      }
      return response.data; // Assuming the response data is the payload you want
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const getCollectionDetailById = createAsyncThunk(
  "nft/getCollectionDetailById",
  async (body, { rejectWithValue }) => {
    //console.log(body, "body");
    try {
      // Assuming saleDetails contains 'user_account' and 'offset'
      const response = await axiosRequestAuth(
        "post",
        "v2/collection/by-id",
        body
      );
      // Check if the response is successful
      // (This depends on how your axiosRequestAuth is structured)

      //console.log("API getCollectionById", response);
      if (response.status !== 200) {
        throw new Error("API request failed");
      }
      return response.data; // Assuming the response data is the payload you want
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const buyNFT = createAsyncThunk(
  "nft/buyNFT",
  async (nftDetail, { rejectWithValue }) => {
    try {
      // Assuming saleDetails contains 'user_account' and 'offset'
      const response = await axiosRequestAuth("post", "v2/buy/nft", nftDetail);
      // Check if the response is successful
      // (This depends on how your axiosRequestAuth is structured)

      //console.log("API getListedNft", response);
      if (response.status !== 200) {
        throw new Error("API request failed");
      }
      return response.data; // Assuming the response data is the payload you want
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const bulkTransferNFTs = createAsyncThunk(
  "nft/bulkTransferNFTs",
  async (nftDetail, { rejectWithValue }) => {
    try {
      // Assuming saleDetails contains 'user_account' and 'offset'
      const response = await axiosRequestAuth(
        "post",
        "v2/transfer/bulk-transfer",
        nftDetail
      );
      // Check if the response is successful
      // (This depends on how your axiosRequestAuth is structured)

      //console.log("API getListedNft", response);
      if (response.status !== 200) {
        throw new Error("API request failed");
      }
      return response.data; // Assuming the response data is the payload you want
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const getAllBulkTransferNFTs = createAsyncThunk(
  "nft/getAllBulkTransferNFTs",
  async (nftDetail, { rejectWithValue }) => {
    try {
      // Assuming saleDetails contains 'user_account' and 'offset'
      const response = await axiosRequestAuth(
        "get",
        "v2/transfer/bulk-transfer",
        nftDetail
      );
      // Check if the response is successful
      // (This depends on how your axiosRequestAuth is structured)

      //console.log("API getListedNft", response);
      if (response.status !== 200) {
        throw new Error("API request failed");
      }
      return response.data; // Assuming the response data is the payload you want
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const cancelTransferRequest = createAsyncThunk(
  "nft/cancelTransferRequest",
  async (nftDetail, { rejectWithValue }) => {
    try {
      // Assuming saleDetails contains 'user_account' and 'offset'
      const response = await axiosRequestAuth(
        "post",
        "v2/offer/cancel",
        nftDetail
      );
      // Check if the response is successful
      // (This depends on how your axiosRequestAuth is structured)

      //console.log("API getListedNft", response);
      if (response.status !== 200) {
        throw new Error("API request failed");
      }
      return response.data; // Assuming the response data is the payload you want
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

const nftSlice = createSlice({
  name: "nft",
  initialState: {
    approveOfferNft: { data: [], loading: false, error: null },
    collectionDetailState: { data: [], loading: false, error: null },
    bulkTransferState: { data: [], loading: false, error: null },
    getAllBulkTransferState: { data: [], loading: false, error: null },
    cnacelTransferState: { data: [], loading: false, error: null },
    usdMintState: { data: [], loading: false, error: null },

    collectionDetail: { data: [], loading: false, error: null },
    collection: [],
    collectionUsd: [],
    collectionById: [],
    featuredNft: [],
    myOffersNft: [],
    nftDetails: [],
    waitingApproval: [],
    res: "",
    forSaleNft: [],
    loading: false,
    loadingNft: false,
    nfts: [],
    VanityNfts: [],
    error: null,
  },
  reducers: {
    resetbuyNFTState: (state) => {
      state.buyNFTState = "";
    },
    approveOfferState: (state) => {
      state.approveOfferNft = "";
    },
    resetnftDetailsState: (state) => {
      state.nftDetails = "";
    },
    resetCancelTransfersState: (state) => {
      state.cnacelTransferState.data = [];
    },
    resetBulkTransferState: (state) => {
      state.bulkTransferState.data = [];
    },
    resetUSDMintState: (state) => {
      state.usdMintState.data = [];
    },
  },
  extraReducers: {
    // All collections nft
    [getAllCollection.pending]: (state) => {
      state.loading = true;
    },
    [getAllCollection.fulfilled]: (state, action) => {
      state.loading = false;
      state.collection = action.payload;
      // Update state based on the successful win
      // ...
    },
    [getAllCollection.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },

    // All collections by Id nft
    [getCollectionById.pending]: (state) => {
      state.loading = true;
    },
    [getCollectionById.fulfilled]: (state, action) => {
      state.loading = false;
      state.collectionById = action.payload;
      // Update state based on the successful win
      // ...
    },
    [getCollectionById.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },

    // recentlyForSaleNft nft waiting
    [recentlyForSaleNft.pending]: (state) => {
      state.loading = true;
      state.forSaleNft = [];
    },
    [recentlyForSaleNft.fulfilled]: (state, action) => {
      state.loading = false;
      state.forSaleNft = action.payload;
    },
    [recentlyForSaleNft.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },

    // Details nft
    [getNftDetail.pending]: (state) => {
      state.loading = true;
    },
    [getNftDetail.fulfilled]: (state, action) => {
      state.loading = false;
      state.nftDetails = action.payload;
      // Update state based on the successful win
      // ...
    },
    [getNftDetail.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
    // featured Details nft
    [getFeaturedNft.pending]: (state) => {
      state.loading = true;
    },
    [getFeaturedNft.fulfilled]: (state, action) => {
      state.loading = false;
      state.featuredNft = action.payload;
      // Update state based on the successful win
      // ...
    },
    [getFeaturedNft.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
    // approve offer

    // getAllCollectionNFTVanity
    [getAllCollectionNFTVanity.pending]: (state) => {
      state.loading = true;
    },
    [getAllCollectionNFTVanity.fulfilled]: (state, action) => {
      state.loading = false;
      state.VanityNfts = action.payload;
    },
    [getAllCollectionNFTVanity.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
    //buy NFT
    [buyNFT.pending]: (state) => {
      state.loading = true;
    },
    [buyNFT.fulfilled]: (state, action) => {
      state.loading = false;
      state.buyNFTState = action.payload;
      // Update state based on the successful win
      // ...
    },
    [buyNFT.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
    // get approveOffer offers ////////////////////

    [approveOffer.pending]: (state) => {
      state.loading = true;
    },
    [approveOffer.fulfilled]: (state, action) => {
      state.loading = false;
      state.approveOfferNft.data = action.payload;
    },
    [approveOffer.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },

    // get USD Collection ////////////////////

    [getUSDCollection.pending]: (state) => {
      state.loading = true;
    },
    [getUSDCollection.fulfilled]: (state, action) => {
      state.loading = false;
      state.collectionUsd = action.payload;
    },
    [getUSDCollection.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },

    // get USD Collection Details  ///////////////
    [getUSDCollectionDetails.pending]: (state) => {
      state.collectionDetailState.loading = true;
    },
    [getUSDCollectionDetails.fulfilled]: (state, action) => {
      state.collectionDetailState.loading = false;
      state.collectionDetailState.data = action.payload;
    },
    [getUSDCollectionDetails.rejected]: (state, action) => {
      state.collectionDetailState.loading = false;
      state.collectionDetailState.error = action.payload;
    },

    // Create bulkTransferNFTs  ///////////////
    [bulkTransferNFTs.pending]: (state) => {
      state.bulkTransferState.loading = true;
    },
    [bulkTransferNFTs.fulfilled]: (state, action) => {
      state.bulkTransferState.loading = false;
      state.bulkTransferState.data = action.payload;
    },
    [bulkTransferNFTs.rejected]: (state, action) => {
      state.bulkTransferState.loading = false;
      state.bulkTransferState.error = action.payload;
    },

    // get getAllBulkTransferNFTs Details  ///////////////
    [getAllBulkTransferNFTs.pending]: (state) => {
      state.getAllBulkTransferState.loading = true;
    },
    [getAllBulkTransferNFTs.fulfilled]: (state, action) => {
      state.getAllBulkTransferState.loading = false;
      state.getAllBulkTransferState.data = action.payload;
    },
    [getAllBulkTransferNFTs.rejected]: (state, action) => {
      state.getAllBulkTransferState.loading = false;
      state.getAllBulkTransferState.error = action.payload;
    },

    // get getAllBulkTransferNFTs Details  ///////////////
    [cancelTransferRequest.pending]: (state) => {
      state.cnacelTransferState.loading = true;
    },
    [cancelTransferRequest.fulfilled]: (state, action) => {
      state.cnacelTransferState.loading = false;
      state.cnacelTransferState.data = action.payload;
    },
    [cancelTransferRequest.rejected]: (state, action) => {
      state.cnacelTransferState.loading = false;
      state.cnacelTransferState.error = action.payload;
    },
    // get usd Collection Mint Details  ///////////////
    [usdCollectionMint.pending]: (state) => {
      state.usdMintState.loading = true;
    },
    [usdCollectionMint.fulfilled]: (state, action) => {
      state.usdMintState.loading = false;
      state.usdMintState.data = action.payload;
    },
    [usdCollectionMint.rejected]: (state, action) => {
      state.usdMintState.loading = false;
      state.usdMintState.error = action.payload;
    },
    // get usd Collection Mint Details  ///////////////
    [gameCollectionMint.pending]: (state) => {
      state.usdMintState.loading = true;
    },
    [gameCollectionMint.fulfilled]: (state, action) => {
      state.usdMintState.loading = false;
      state.usdMintState.data = action.payload;
    },
    [gameCollectionMint.rejected]: (state, action) => {
      state.usdMintState.loading = false;
      state.usdMintState.error = action.payload;
    },

    [getCollectionDetailById.pending]: (state) => {
      state.collectionDetail.loading = true;
    },
    [getCollectionDetailById.fulfilled]: (state, action) => {
      state.collectionDetail.loading = false;
      state.collectionDetail.data = action.payload;
    },
    [getCollectionDetailById.rejected]: (state, action) => {
      state.collectionDetail.loading = false;
      state.collectionDetail.error = action.payload;
    },
  },
});
export default nftSlice.reducer;
export const {
  resetbuyNFTState,
  approveOfferState,
  resetnftDetailsState,
  resetBulkTransferState,
  resetCancelTransfersState,
  resetUSDMintState,
} = nftSlice.actions;
