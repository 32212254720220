// BackgroundVideo.js
import React from "react";

const BackgroundVideo = ({ src, isVisible }) => {
  if (!isVisible) return null;

  return (
    <video
      autoPlay
      muted
      loop
      playsInline // Prevents fullscreen on iOS
      style={{
        position: "absolute",
        top: 0,
        left: 0,
        width: "100%",
        height: "100%",
        objectFit: "cover",
        opacity: 0.5,
        zIndex: 1, // Adjusted to keep it in the background
        pointerEvents: "none", // Ensure it doesn’t capture touch events
      }}
    >
      <source src={src} type="video/mp4" />
      Your browser does not support the video tag.
    </video>
  );
};

export default BackgroundVideo;
