import React, { useState } from "react";
import Button from "@mui/material/Button";
import styles from "./Card.module.css";

import { Paper, Typography, Box, Avatar } from "@mui/material";
import MoneyIcon from "@mui/icons-material/Money";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import CustomInput from "../../shared/customInput/customInput";
import { axiosRequestAuth, axiosRequest } from "../../../services/api/api";
import { toast } from "react-hot-toast";

import { getNftDetail } from "../../../services/redux/NftApiReducer/NftApiReducer";
import { useDispatch, useSelector } from "react-redux";
import NFTDialog from "../../features/NFTDialog/NFTDialog";
import { setText } from "../../../services/redux/TextReducer/TextReducer";

const SearchCard = ({ data, forSale, isFeatured }) => {
  const bgcolor = "rgba(104, 103, 103, 0.5)";
  const txtcolor = "#f1f1f1";
  const [nftDataOffer, setNftDataOffer] = useState([]);
  const [nftData, setNftData] = useState([]);
  const [openDetailModal, setOpenDetailModal] = useState();
  const [loading, setLoading] = useState(false);
  // const [isFeatured, setIsFeatured] = useState(false);
  const [ModelImage, setModelImage] = useState(null);
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [sellNftObject, setSellNftObject] = useState();

  const [sellingState, setSellingSate] = useState(false);

  const dispatch = useDispatch();
  const { nftDetails } = useSelector((state) => state.nft);

  const SetDetail = (e) => {
    const apiDta = {
      nft_id: e.id,
    };

    dispatch(getNftDetail(apiDta));
    dispatch(setText(""));
    // dispatch (resetnftDetailsState)
    if (nftDetails) {
      setModelImage(e?.metadata?.image);
      setIsDialogOpen(true);
    }
    // Logs the entire nft object to the console
  };
  const truncateText = (text, maxLength) => {
    if (text?.length > maxLength) {
      return text.substring(0, maxLength) + "...";
    }
    return text;
  };
  const handleCloseDialog = () => {
    setIsDialogOpen(false);
  };
  const schema = yup
    .object({
      salePrice: yup
        .number("must be a number")
        .required("required a number")
        .typeError("A number is required"),
    })
    .required();

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  // useEffect(() => {
  //   if (location.pathname === "/") {
  //     setIsFeatured(true);
  //   } else {
  //     setIsFeatured(false);
  //   }
  // }, [location]);
  const onSubmit = (e) => {
    nftForSell(e?.salePrice, sellNftObject);
  };
  const handleSellingSatet = () => {
    // Set the state variable to true when the onClick event occurs
    setSellingSate(true);
  };
  async function nftForSell(price, nftData) {
    let accnum = localStorage.getItem("accnum");
    nftData.user_account = accnum;
    nftData.price = price;
    setLoading(true);
    try {
      const response = await axiosRequestAuth(
        "post",
        "market/sell-nft",
        nftData
      );
      setLoading(false);
      setSellingSate(false);

      toast.success(response.data.message);
    } catch (error) {
      setLoading(false);
      setSellingSate(false);
      toast.error("Something went wrong. Please try again!");
    }
  }

  return (
    <>
      {/* <Card sx={{ width: height, borderRadius: 4, minHeight: "200px" }}> */}

      <Paper
        sx={{
          // width: 1,
          p: 0,
          background: "black",
          borderRadius: 2,
          border: "solid 2px #af22c8",
          width: 300,
        }}
      >
        <Box
          sx={{
            // background: "#af22c8",
            display: "flex",
            flexDirection: "row",

            border: "solid 10px black",
            borderRadius: 4,
            cursor: "pointer",
            position: "relative",
          }}
          onClick={() => SetDetail(data)}
        >
          <Box sx={{ display: "flex", flexDirection: "row" }}>
            {!data?.metadata?.image ? (
              <Avatar sx={{ width: 80, height: 80 }} variant="rounded">
                <video
                  style={{
                    width: "100%",
                    height: "100%",
                    padding: 0,
                    cursor: "pointer",
                    objectFit: "contain",
                  }}
                  src={data?.metadata?.video}
                  autoPlay
                  muted
                  loop
                />
              </Avatar>
            ) : (
              <Avatar sx={{ width: 80, height: 80 }} variant="rounded">
                <img
                  style={{
                    width: "100%",
                    height: "100%",
                    padding: 0,
                    cursor: "pointer",
                    objectFit: "contain",
                  }}
                  src={data?.metadata?.image}
                  title="green iguana"
                />
              </Avatar>
            )}
          </Box>

          {/* <Box
          sx={{
            display: "flex",
            direction: "row",
            ml: 2,
            justifyContent: "space-between",
          }}
        > */}
          <Box sx={{ pl: 2, mt: 1 }}>
            <Typography variant="body2" sx={{ color: "#AF22C8" }}>
              {truncateText(data?.metadata?.collection?.name, 20)}
            </Typography>

            <Box sx={{ mr: 2, display: "flex", direction: "row" }}>
              <Typography variant="body2" sx={{ color: "black" }}>
                {truncateText(data?.featured?.metadata?.collection?.family, 20)}
              </Typography>
            </Box>

            <Box sx={{ display: "flex", textAlign: "center", pb: 0 }}>
              <Typography variant="body2" sx={{ color: "white" }}>
                {truncateText(data?.metadata?.name, 20)}
              </Typography>
            </Box>

            <Box
              sx={{
                display: "flex",
                direction: "row",
                justifyContent: "center",
                pb: 2,
              }}
            >
              <Box>
                <Box sx={{ display: "flex", justifyContent: "center", pb: 2 }}>
                  <Typography variant="body2" sx={{ color: "white" }}>
                    <span style={{ color: "#AF22C8" }}>Price:</span>{" "}
                    {data?.price} XRP
                  </Typography>
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
      </Paper>

      {/* </Card> */}
      {isDialogOpen && (
        <NFTDialog
          open={isDialogOpen}
          onClose={handleCloseDialog}
          imageUrl={ModelImage}
          nft={nftDetails?.data?.nft}
          offersData={nftDetails?.data?.nft}
        />
      )}
    </>
  );
};

export default SearchCard;
