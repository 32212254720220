import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  collectionId: 0,
  collectionName: "",
  taxonId: 0,
};

const collectionSlice = createSlice({
  name: "collection",
  initialState,
  reducers: {
    setCollectionId(state, action) {
      state.collectionId = action.payload;
    },
    setTaxonId(state, action) {
      state.taxonId = action.payload;
    },
    setCollectionName(state, action) {
      state.collectionName = action.payload;
    },
    setTaxonIdToNull(state, action) {
      state.taxonId = action.payload;
    },
    setCollectionIdToNull(state, action) {
      state.collectionId = 0;
    },
  },
});

export const {
  setCollectionId,
  setTaxonId,
  setCollectionName,
  setTaxonIdToNull,
  setCollectionIdToNull,
} = collectionSlice.actions;

export default collectionSlice.reducer;
