import React from "react";
import style from "./Footer.module.css";
import {
  Box,
  Button,
  Typography,
  TextField,
  Grid,
  Menu,
  Fade,
  useMediaQuery,
  MenuItem,
} from "@mui/material";

// import Logo from "../../../../assets/logos/DeLuxMintLogo.svg";
import Logo from "../../../../assets/logos/DeLuxMintLogo.png";

import TickTokIcon from "../../../../assets/SocailMediaIcons/TikTokIcon.svg";
import YoutubeIcon from "../../../../assets/SocailMediaIcons/YoutubeIcon.svg";
import InstagramIcon from "../../../../assets/SocailMediaIcons/InstagramIcon.svg";
import TwitchIcon from "../../../../assets/SocailMediaIcons/TwitchIcon.svg";
import DiscordIcon from "../../../../assets/SocailMediaIcons/DiscordIcon.svg";
import TwitterIcon from "../../../../assets/SocailMediaIcons/TwitterIcon.svg";
import { useNavigate } from "react-router-dom";

const Footer = () => {
  const navigate = useNavigate();

  let textColor = "#FFFFFF61";
  const isBelow1024px = useMediaQuery("(max-width: 1024px)");

  const handleLogoClick = () => {
    // Navigate to the home page
    navigate("/");
    // Scroll to the top of the page
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  return (
    <Box sx={{ minHeight: 200, background: "#121212" }}>
      <Grid container paddingBottom={4}>
        <Grid item lg={5} sm={12}>
          <Box sx={{ textAlign: "centre", pl: isBelow1024px ? 0 : 6 }}>
            <Box
              sx={{
                pt: 4,
                pl: 0,
                display: "flex",
                justifyContent: isBelow1024px ? "center" : "left",
              }}
            >
              <img
                src={Logo}
                alt={"logo"}
                height={100}
                className="pointer-on-hover"
                onClick={handleLogoClick}
              />
            </Box>
            <Box
              sx={{
                pt: 2,
                px: 2,
                textAlign: isBelow1024px ? "center" : "left",
              }}
            >
              <Typography sx={{ color: "#FFFFFFA6", fontSize: 14 }}>
                {
                  "The world’s first multichain crosschain digital marketplace for crypto collectibles and non-fungible tokens (NFTs). Buy, sell, and discover exclusive digital items."
                }
              </Typography>
            </Box>
          </Box>
        </Grid>

        {/* /////////////////////////////////////////////////// Section Links //////////////////////////*/}

        <Grid item lg={3} sm={6} xs={6}>
          <Box display={"flex"} justifyContent={"center"}>
            <Box
              width={100}
              height={90}
              onClick={() => {
                navigate("privacy-policy");
              }}
            >
              <Typography
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  textAlign: "center",
                  color: "white",
                  fontSize: 14,
                  fontWeight: 600,
                  pt: 6,
                  cursor: "pointer", // This makes the cursor change to a pointer on hover
                }}
              >
                {"Privacy"}
              </Typography>
            </Box>
          </Box>
          <Box display={"flex"} justifyContent={"center"}>
            <Box
              width={100}
              height={90}
              onClick={() => {
                navigate("terms");
              }}
            >
              <Typography
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  textAlign: "center",
                  color: "white",
                  fontSize: 14,
                  fontWeight: 600,
                  pt: 6,
                  cursor: "pointer", // This makes the cursor change to a pointer on hover
                }}
              >
                {"Terms"}
              </Typography>
            </Box>
          </Box>
          <Box>
            <Typography
              sx={{
                display: "flex",
                justifyContent: "center",
                textAlign: "center",
                color: "white",
                fontSize: 14,
                fontWeight: 600,
                pt: 6,
                cursor: "pointer", // This makes the cursor change to a pointer on hover
              }}
            >
              {"Support"}
            </Typography>
          </Box>
        </Grid>

        {/* /////////////////////////////////// Section Socail Media /////////////////////////////////*/}

        <Grid
          item
          lg={3}
          sm={6}
          xs={6}
          sx={{
            display: "flex",
            direction: "column",
            justifyContent: isBelow1024px ? "center" : "right",
            pt: 4,
          }}
        >
          <Box>
            <Box sx={{ p: 2 }}>
              <img
                src={TickTokIcon}
                alt={"logo"}
                height={30}
                style={{ paddingInline: 8 }}
                className="pointer-on-hover"
              />
              <img
                src={YoutubeIcon}
                alt={"logo"}
                height={30}
                style={{ paddingInline: 8 }}
                className="pointer-on-hover"
              />{" "}
            </Box>{" "}
            <Box sx={{ p: 2 }}>
              <img
                src={TwitchIcon}
                alt={"logo"}
                style={{ paddingInline: 8 }}
                height={30}
                className="pointer-on-hover"
              />
              <img
                src={InstagramIcon}
                alt={"logo"}
                style={{ paddingInline: 8 }}
                height={30}
                className="pointer-on-hover"
              />
            </Box>
            <Box sx={{ p: 2 }}>
              <img
                src={DiscordIcon}
                alt={"logo"}
                style={{ paddingInline: 8 }}
                height={30}
                className="pointer-on-hover"
              />
              <img
                src={TwitterIcon}
                alt={"logo"}
                style={{ paddingInline: 8 }}
                height={30}
                className="pointer-on-hover"
              />
            </Box>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default Footer;
