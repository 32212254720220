import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  nftCount: 0,
  userApi: true,
  token: "",
  isAdmin: false,
  userId: 0,
  allCounts: {},
};

const nftCountSlice = createSlice({
  name: "userNft",
  initialState,
  reducers: {
    setUserId(state, action) {
      state.userId = action.payload;
    },
    setCountData(state, action) {
      state.allCounts = action.payload;
    },
    setCountNFT(state, action) {
      state.nftCount = action.payload;
    },
    resetCountNFT(state) {
      state.nftCount = 0;
    },
    setApiTimer(state, action) {
      state.userApi = action.payload;
    },
    setIsAdmin(state, action) {
      state.isAdmin = action.payload;
    },
    setToken(state, action) {
      state.token = action.payload;
      console.log(action, "socket");
    },
  },
});

export const {
  setCountData,
  setCountNFT,
  resetCountNFT,
  setApiTimer,
  setIsAdmin,
  setToken,
  setUserId,
} = nftCountSlice.actions;

export default nftCountSlice.reducer;
