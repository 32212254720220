import React, { useEffect, useState } from "react";
import { Box, Button, TextField, Grid, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { toast } from "react-hot-toast";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useSelector, useDispatch } from "react-redux";
import BackgroundWrapper from "../../components/layout/Shared/BackgroundWrapper/BackgroundWrapper";
import CustomLoader from "../../components/shared/customLoader/customLoader";
import {
  resetSyncNftId,
  syncNftAPI,
} from "../../services/redux/MyNftApiReducer/MyNftApiReducer";
import { axiosRequestAuth } from "../../services/api/api";

const SyncNfts = () => {
  const bgcolor = "rgba(104, 103, 103, 0.5)";
  const txtcolor = "#f1f1f1";
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [nftId, setNftId] = useState(""); // Single field for NFT ID
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState({});
  const [valErr, setValErr] = useState("");
  const { syncNftId, loading: loader } = useSelector((state) => state.mynft);
  function validateNftId(nftId) {
    let newErrors = {};

    // Check if NFT ID is empty
    if (nftId.length === 0) {
      newErrors.nftId = "NFT ID is required.";
      setValErr(newErrors.nftId);
      return false;
    }

    // Check if NFT ID is a string
    if (typeof nftId !== "string") {
      newErrors.nftId = "NFT ID must be a string.";
      setValErr(newErrors.nftId);
      return false;
    }

    // Check if NFT ID is at least 10 characters long
    if (nftId.trim().length != 64) {
      newErrors.nftId = "NFT ID must be 64 characters long.";
      setValErr(newErrors.nftId);
      return false;
    }

    // If no validation errors, clear error state
    setValErr("");
    return true; // Validation passed
  }
  // const validate = () => {
  //   const newErrors = {};

  //   // Check for empty NFT ID field
  //   if (!nftId) {
  //     newErrors.nftId = "NFT ID is required.";
  //   }
  //   // Check for minimum length of 10 characters
  //   else if (nftId.length < 10) {
  //     newErrors.nftId = "NFT ID must be at least 10 characters long.";
  //   }

  //   setErrors(newErrors);
  //   if (Object.keys(newErrors).length > 0) {
  //     toast.error(newErrors.nftId);
  //   }

  //   return Object.keys(newErrors).length === 0;
  // };
  const handleChange = (e) => {
    const value = e.target.value.replace(/\s/g, "");
    setNftId(value);
    validateNftId(value);
  };
  const handleSubmit = async (event) => {
    event.preventDefault();
    setLoading(true);
    if (!validateNftId(nftId)) {
      setLoading(false);
      return;
    }

    const data = { nftId };
    setLoading(false);

    console.log("Submit function called with data:", data);
    dispatch(syncNftAPI({ NftokenId: nftId }));
  };

  useEffect(() => {
    if (Object.keys(syncNftId?.data).length > 0) {
      if (syncNftId?.data?.status === true) {
        toast.success(syncNftId?.data?.message);
        dispatch(resetSyncNftId());
      } else if (syncNftId?.data?.status === false) {
        toast.error(syncNftId?.data?.message);
        dispatch(resetSyncNftId());
      }
    }
  }, [syncNftId?.data?.status]);

  useEffect(() => {
    setLoading(loader);
  }, [loader]);

  async function getCollections() {
    setLoading(true);
    try {
      const response = await axiosRequestAuth(
        "get",
        "v2/collection/dropdown-for-order"
      );
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  }

  useEffect(() => {
    getCollections();
  }, []);

  return (
    <BackgroundWrapper>
      {loading ? (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            minHeight: "80vh", // Set minimum height to fill the entire viewport
          }}
        >
          <CustomLoader width={"60%"} text={"Please wait ..."} />
        </Box>
      ) : (
        <Box>
          <Grid container spacing={2}>
            <Grid item lg={2}>
              {" "}
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "start",
                  pt: 1,
                  ml: 1,
                  pl: 6,
                }}
              >
                <Button
                  size="large"
                  sx={{ color: txtcolor }}
                  onClick={() => navigate(-1)}
                >
                  <ArrowBackIcon fontSize="large" sx={{ color: txtcolor }} />
                </Button>
              </Box>
            </Grid>
            <Grid item lg={8}>
              <Box sx={{ display: "flex", justifyContent: "center", pt: 2 }}>
                <Typography
                  variant="h4"
                  sx={{
                    color: txtcolor,
                    fontWeight: 600,
                    fontFamily: "goodTime",
                  }}
                >
                  SYNC NFT
                </Typography>
              </Box>
            </Grid>
            <Grid item lg={2}></Grid>
          </Grid>

          <Grid
            container
            spacing={2}
            sx={{
              display: "flex",
              direction: "row",
              justifyContent: "center",
              pt: 2,
            }}
          >
            <Grid
              item
              xl={2}
              lg={2}
              sm={12}
              sx={{
                p: 2,
              }}
            />

            <Grid
              item
              xl={6}
              lg={8}
              sm={12}
              sx={{
                p: 2,
              }}
            >
              <Box>
                <form onSubmit={handleSubmit}>
                  <Grid
                    container
                    spacing={2}
                    sx={{
                      maxHeight: "70vh",
                      overflowY: "auto",
                      pb: 2,
                    }}
                  >
                    <Grid item xs={8}>
                      <Box sx={{ pt: 2 }}>
                        <Typography
                          sx={{ color: "white", fontFamily: "goodTime" }}
                        >
                          NFT ID
                        </Typography>
                      </Box>

                      <Box>
                        <TextField
                          required
                          size="small"
                          value={nftId}
                          onChange={(e) => handleChange(e)}
                          fullWidth
                          sx={{
                            "& .MuiOutlinedInput-root": {
                              backgroundColor: bgcolor, // Background color when not active
                              "&:hover fieldset": {
                                borderColor: "purple", // Border color on hover
                              },
                              "&.Mui-focused fieldset": {
                                borderColor: "purple", // Border color on focus
                              },
                            },
                            "& .MuiInputBase-input": {
                              color: txtcolor, // Text color
                            },
                          }}
                        />
                        {errors.nftId ||
                          (valErr && (
                            <Typography color="error">
                              {errors.nftId || valErr}
                            </Typography>
                          ))}
                      </Box>
                      <Box
                        sx={{
                          display: "flex",
                          mt: 1,
                          justifyContent: "flex-end",
                        }}
                      >
                        <Button
                          variant="contained"
                          sx={{
                            width: "100px",
                            bgcolor: "#af22c8",
                            "&:hover": {
                              bgcolor: "#62136f", // Adjust the darkness level as needed
                            },
                          }}
                          type="submit"
                          // disabled={valErr ? true : false}
                        >
                          Sync
                        </Button>
                      </Box>
                    </Grid>
                  </Grid>
                </form>
              </Box>
            </Grid>
            <Grid
              item
              lg={6}
              xl={2}
              sm={12}
              sx={{
                p: 2,
              }}
            />
          </Grid>
        </Box>
      )}
    </BackgroundWrapper>
  );
};

export default SyncNfts;
