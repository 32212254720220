import React from "react";
import BulkMintingForm from "../../components/features/Admins/Mint/BulkMint/BulkMint";
import { Box } from "@mui/material";

const BulkMinting = () => {
  return (
    <Box>
      <BulkMintingForm />
    </Box>
  );
};

export default BulkMinting;
