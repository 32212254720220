import React from "react";
import { Toaster } from "react-hot-toast";
const ToastConfig = () => {
  return (
    <div>
      <Toaster
        toastOptions={{
          success: {
            style: {
              background: "linear-gradient(to right, #7D3CE0, #af22c8)",
              color: "#f1f1f1",
              marginTop: "20px",
            },
            iconTheme: {
              primary: "white",
              secondary: "#af22c8",
            },
            position: "top-right",
          },
          error: {
            style: {
              background: "linear-gradient(to right, #FF5733, #FF0000)",
              color: "#f1f1f1",
              marginTop: "20px",
            },
            iconTheme: {
              primary: "white",
              secondary: "red",
            },
            position: "top-right",
          },
        }}
      />
    </div>
  );
};

export default ToastConfig;
