import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  TextField,
  Grid,
  MenuItem,
  Typography,
  IconButton,
} from "@mui/material";
// import "./Mint.css";

// import styles from "./Mint.module.css";
import { useNavigate } from "react-router-dom";
import BackgroundWrapper from "../../../layout/Shared/BackgroundWrapper/BackgroundWrapper";
import { toast } from "react-hot-toast";
import { axiosRequestAuth } from "../../../../services/api/api";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import DeleteIcon from "@mui/icons-material/Delete";
import CustomLoader from "../../../shared/customLoader/customLoader";
import { useSelector, useDispatch } from "react-redux";
import {
  bulkTransferNFTs,
  resetBulkTransferState,
} from "../../../../services/redux/NftApiReducer/NftApiReducer";
// import CustomInput from "../../components/shared/customInput/customInput";

let flags = [
  { key: 8, value: "transferable" },
  { key: 1, value: "brunable" },
];

const BulkTrasnferForm = () => {
  const bgcolor = "rgba(104, 103, 103, 0.5)";
  const txtcolor = "#f1f1f1";
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [fields, setFields] = useState([""]); // Initial state with an empty string
  const [destination, setDestination] = useState();
  const { bulkTransferState } = useSelector((state) => state.nft);

  /////////////////////////////////////////////////////////
  const handleChange = (index, value) => {
    const newFields = [...fields];
    newFields[index] = value;
    setFields(newFields);
  };

  const handleChangeDestination = (value) => {
    setDestination(value);
  };

  const handleAddField = () => {
    setFields([...fields, ""]);
  };

  const handleRemoveField = (index) => {
    const newFields = [...fields];
    newFields.splice(index, 1);
    setFields(newFields);
  };
  function checkDuplicates(arr) {
    const uniqueSet = new Set(arr);
    return arr.length !== uniqueSet.size;
  }

  // const  handleSubmit  = (event) => {
  //   event.preventDefault();
  //   console.log("NFtokenIDs:", fields, destination);
  //   const apiBody = {
  //     NFtokenIDs: fields,
  //     destination: destination,
  //   };

  //  let checkDuplicatesArray = await  function checkDuplicates(arr) {
  //     const uniqueSet = new Set(arr);
  //     return arr.length !== uniqueSet.size;
  //   }
  //   if(checkDuplicatesArray){

  //   }
  //   else{
  //   dispatch(bulkTransferNFTs(apiBody));
  //   }
  //   // You can perform further actions with the fields array here
  // };

  const handleSubmit = async (event) => {
    event.preventDefault();
    console.log("NFtokenIDs:", fields, destination);
    const apiBody = {
      NFtokenIDs: fields,
      destination: destination,
    };

    const checkDuplicatesArray = checkDuplicates(fields);
    if (checkDuplicatesArray) {
      // Handle case where duplicates are found
      toast.error("Please remove duplicates before proceeding.");
    } else {
      // If no duplicates, proceed with dispatching the action
      dispatch(bulkTransferNFTs(apiBody));
    }
    // You can perform further actions with the fields array here
  };

  // ///////////////////////////////////////////////////
  useEffect(() => {
    // getAllCollection();
    console.log(bulkTransferState, "bulkTransferNFTs");
  }, [bulkTransferState]);

  useEffect(() => {
    if (bulkTransferState?.data?.status === true) {
      toast.success(bulkTransferState?.data?.message);
      dispatch(resetBulkTransferState());
      setDestination("");
      setFields([""]);
    } else if (bulkTransferState?.data?.status === false) {
      dispatch(resetBulkTransferState());
      toast.error(bulkTransferState?.data?.message);
      // dispatch(resetAcceptTransferRes());
    }
  }, [bulkTransferState]);

  return (
    <BackgroundWrapper>
      {bulkTransferState?.loading ? (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            minHeight: "80vh", // Set minimum height to fill the entire viewport
          }}
        >
          <CustomLoader width={"60%"} text={"Transfering NFTs"} />
        </Box>
      ) : (
        <Box>
          <Grid container spacing={2}>
            <Grid item lg={2}>
              {" "}
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "start",
                  pt: 1,
                  ml: 1,
                  pl: 6,
                }}
              >
                <Button
                  size="large"
                  sx={{ color: txtcolor }}
                  onClick={() => navigate(-1)}
                >
                  <ArrowBackIcon fontSize="large" sx={{ color: txtcolor }} />
                </Button>
              </Box>
            </Grid>
            <Grid item lg={8}>
              <Box sx={{ display: "flex", justifyContent: "center", pt: 2 }}>
                <Typography
                  variant="h4"
                  sx={{ color: txtcolor, fontWeight: 600 }}
                >
                  Bulk Transfer
                </Typography>
              </Box>
            </Grid>
            <Grid item lg={2}></Grid>
          </Grid>

          <Grid
            container
            spacing={2}
            sx={{
              display: "flex",
              direction: "row",
              justifyContent: "center",
              pt: 2,
            }}
          >
            <Grid
              item
              xl={2}
              lg={2}
              sm={12}
              sx={{
                p: 2,
              }}
            />

            <Grid
              item
              xl={6}
              lg={8}
              sm={12}
              sx={{
                p: 2,
              }}
            >
              <Box>
                <form onSubmit={handleSubmit}>
                  <Grid container spacing={2}>
                    <Grid item xs={6}>
                      <Box sx={{}}>
                        <Typography sx={{ color: "white" }}>
                          Destination Address
                        </Typography>
                      </Box>

                      <TextField
                        // label={`Field ${index + 1}`}
                        required
                        size="small"
                        value={destination}
                        onChange={(e) =>
                          handleChangeDestination(e.target.value)
                        }
                        fullWidth
                        sx={{
                          "& .MuiOutlinedInput-root": {
                            backgroundColor: bgcolor, // Background color when not active
                            "&:hover fieldset": {
                              borderColor: "purple", // Border color on hover
                            },
                            "&.Mui-focused fieldset": {
                              borderColor: "purple", // Border color on focus
                            },
                          },
                          "& .MuiInputBase-input": {
                            color: txtcolor, // Text color
                          },
                        }}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <Box sx={{ pt: 2 }}>
                        <Typography sx={{ color: "white" }}>
                          Add NFT ID
                        </Typography>
                      </Box>

                      <Box
                        sx={{
                          maxHeight: "70vh",
                          overflowY: "auto",
                          pb: 2,
                        }}
                      >
                        {fields.map((field, index) => (
                          <Box sx={{ display: "flex", pb: 1 }}>
                            <TextField
                              required
                              // label={`Field ${index + 1}`}
                              size="small"
                              value={field}
                              onChange={(e) =>
                                handleChange(index, e.target.value)
                              }
                              fullWidth
                              sx={{
                                "& .MuiOutlinedInput-root": {
                                  backgroundColor: bgcolor, // Background color when not active
                                  "&:hover fieldset": {
                                    borderColor: "purple", // Border color on hover
                                  },
                                  "&.Mui-focused fieldset": {
                                    borderColor: "purple", // Border color on focus
                                  },
                                },
                                "& .MuiInputBase-input": {
                                  color: txtcolor, // Text color
                                },
                              }}
                            />

                            <IconButton
                              onClick={() => handleRemoveField(index)}
                            >
                              <DeleteIcon sx={{ color: "red" }} />
                            </IconButton>
                          </Box>
                        ))}
                      </Box>
                    </Grid>
                  </Grid>
                  <Box sx={{ display: "flex", mt: 1 }}>
                    <Box sx={{ mr: 2 }}>
                      <Button
                        variant="contained"
                        sx={{
                          width: "120px",
                          bgcolor: "#af22c8",
                          "&:hover": {
                            bgcolor: "#62136f", // Adjust the darkness level as needed
                          },
                        }}
                        onClick={handleAddField}
                      >
                        Add NFT ID
                      </Button>
                    </Box>
                    <Box>
                      <Button
                        variant="contained"
                        sx={{
                          width: "100px",
                          bgcolor: "#af22c8",
                          "&:hover": {
                            bgcolor: "#62136f", // Adjust the darkness level as needed
                          },
                        }}
                        type="submit"
                      >
                        Submit
                      </Button>
                    </Box>
                  </Box>
                </form>
              </Box>
            </Grid>
            <Grid
              item
              lg={6}
              xl={2}
              sm={12}
              sx={{
                p: 2,
              }}
            />
          </Grid>
        </Box>
      )}
    </BackgroundWrapper>
  );
};

export default BulkTrasnferForm;
