import React, { useEffect, useState } from "react";
import { Box, Grid, Typography, Button, Divider } from "@mui/material";

import { useDispatch } from "react-redux";
import { toast } from "react-hot-toast";

import { useSelector } from "react-redux/es/hooks/useSelector";

import { useNavigate, useParams } from "react-router-dom";

import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";

import CustomLoader from "../../../components/shared/customLoader/customLoader";
import { resetText } from "../../../services/redux/TextReducer/TextReducer";
import { getAllBulkTransferNFTs } from "../../../services/redux/NftApiReducer/NftApiReducer";
import TransferView from "../../../components/features/TransferView/TransferView";
import BulkTransferView from "../../../components/features/BulkTransferView/BulkTransferView";

const containerStyle = {
  position: "relative",
  width: "100%",
  minHeight: "100vh",
  background:
    "linear-gradient(225deg, #731686 1%, transparent 10%, transparent 80%, #af22c8 100%)", // Linear gradient background
  backgroundSize: "cover",
  backgroundRepeat: "no-repeat",
  backgroundPosition: "center",
  paddingBottom: "20px",
};
const contentStyle = {
  position: "relative",
  top: "0px",
  width: "100%",
};

const BulkTransferDetails = () => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const [loader, setLoader] = useState(false);
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const [transferData, setTransferData] = useState([]);
  const txtcolor = "#f1f1f1";
  const navigate = useNavigate();
  const smallScreen = useMediaQuery("(max-width: 724px)");
  const {
    loading,
    getTransferOffer: transferList,
    acceptTransferRes,
  } = useSelector((state) => state.mynft);

  const { getAllBulkTransferState, cnacelTransferState } = useSelector(
    (state) => state.nft
  );

  useEffect(() => {
    dispatch(resetText(""));
    dispatch(getAllBulkTransferNFTs());
  }, []);

  useEffect(() => {
    setTransferData(transferList?.data?.data?.nfts);
  }, [transferList]);

  useEffect(() => {
    setLoader(getAllBulkTransferState.loading || cnacelTransferState.loading);
  }, [getAllBulkTransferState.loading, cnacelTransferState.loading]);

  const { text } = useSelector((state) => state.text);

  return (
    <div style={containerStyle}>
      <div style={contentStyle}>
        <>
          <Grid container spacing={2}>
            <Grid item xs={2}></Grid>

            <Grid item xs={8}>
              <Box sx={{ py: 2, pb: 0 }}>
                {isMobile ? (
                  // Mobile view
                  <Typography
                    variant="h5"
                    sx={{
                      fontWeight: 600,
                      color: "white",
                      textAlign: "center",
                      fontFamily: "GoodTime",
                      fontSize: "28px",
                    }}
                  >
                    {"Transfer NFT's NFT's"}
                  </Typography>
                ) : (
                  // Desktop view
                  <Typography
                    variant="h5"
                    sx={{
                      fontWeight: 600,
                      color: "white",
                      fontFamily: "GoodTime",
                      textAlign: "center",
                      py: 2,
                    }}
                  >
                    {"Bulk Transfer NFT's"}
                  </Typography>
                )}
              </Box>
            </Grid>

            <Grid item lg={2}>
              <Box sx={{ display: "flex", justifyContent: "center", pt: 3 }}>
                <Button
                  size="large"
                  variant="contained"
                  sx={{
                    bgcolor: "#af22c8",
                    "&:hover": {
                      bgcolor: "#62136f", // Adjust the darkness level as needed
                    },
                  }}
                  onClick={() => navigate("/bulk-transfer")}
                >
                  {/* <AddIcon fontSize="large" sx={{ color: txtcolor }} /> */}
                  Bulk Transfer
                </Button>
              </Box>
            </Grid>
          </Grid>

          {loader ? (
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                minHeight: "80vh", // Set minimum height to fill the entire viewport
              }}
            >
              <CustomLoader
                width={"60%"}
                text={text || text !== "" ? text : "Please wait ..."}
              />
            </Box>
          ) : (
            <>
              <Box sx={{ pb: 4 }}>
                <BulkTransferView
                  data={getAllBulkTransferState?.data?.data?.transfer_offer}
                />
              </Box>
            </>
          )}
        </>
      </div>
    </div>
  );
};
export default BulkTransferDetails;
