import React, { useState, useEffect } from "react";

import { TextField, MenuItem } from "@mui/material";

const CustomInput2 = ({
  name,
  bgcolor = "white",
  txtcolor = "black",
  isFullWidth = false,
  options = [],
  multiline = false,
  placeholder = "", // Add placeholder pro
  defaultValue = "",
  onChange, // Add onChange prop
  vanity = false, // Default value for vanity prop is false
  handleChange,
  index,
  error,
}) => {
  return (
    <div>
      {options.length > 0 ? (
        <TextField
          size="small"
          select
          fullWidth={isFullWidth}
          defaultValue={defaultValue}
          onChange={(e) => {
            handleChange(index, e.target.value);
          }} // Attach handleChange to onChange even
          sx={{
            "& .MuiOutlinedInput-root": {
              backgroundColor: bgcolor, // Background color when not active
              "&:hover fieldset": {
                borderColor: "purple", // Border color on hover
              },
              "&.Mui-focused fieldset": {
                borderColor: "purple", // Border color on focus
              },
            },
            "& .MuiInputBase-input": {
              color: txtcolor, // Text color
            },
            "& .MuiSelect-icon": {
              color: "white", // Color of the dropdown icon
            },
            border: error ? "1px solid red" : !error && "1px solid transparent",
            "& .MuiOutlinedInput-root": {
              backgroundColor: bgcolor, // Background color when not active
              "&:hover fieldset": {
                borderColor: "purple", // Border color on hover
              },
              "&.Mui-focused fieldset": {
                borderColor: "purple", // Border color on focus
              },
            },
            "& .MuiInputBase-input": {
              color: txtcolor, // Text color
            },

            border: error ? "1px solid red" : !error && "1px solid transparent",

            "& .MuiOutlinedInput-root": {
              backgroundColor: bgcolor, // Background color when not active
              "&:hover fieldset": {
                borderColor: "purple", // Border color on hover
              },
              "&.Mui-focused fieldset": {
                borderColor: "purple", // Border color on focus
              },
            },
            "& .MuiInputBase-input": {
              color: txtcolor, // Text color
            },
          }}
        >
          {defaultValue && (
            <MenuItem value={defaultValue} disabled>
              {defaultValue}
            </MenuItem>
          )}
          {options.map((option) => (
            <MenuItem key={option} value={option.id}>
              {option.name}
            </MenuItem>
          ))}
        </TextField>
      ) : (
        <TextField
          size="small"
          multiline={multiline}
          fullWidth={isFullWidth}
          placeholder={placeholder}
          onChange={(e) => {
            handleChange(index, e.target.value);
          }}
          sx={{
            "& .MuiOutlinedInput-root": {
              backgroundColor: bgcolor, // Background color when not active
              "&:hover fieldset": {
                borderColor: "purple", // Border color on hover
              },
              "&.Mui-focused fieldset": {
                borderColor: "purple", // Border color on focus
              },
            },
            "& .MuiInputBase-input": {
              color: txtcolor, // Text color
            },
          }}
        />
      )}
    </div>
  );
};

export default CustomInput2;
