import React from "react";
import { Box } from "@mui/material";
import TopBanner from "./TopBanner/TopBanner";
import MidBanner from "./MidBanner/MidBanner";

const Banners = () => {
  return (
    <Box sx={{ background: "none" }}>
      <TopBanner />
      <MidBanner />
    </Box>
  );
};

export default Banners;
