import React, { useState } from "react";
import Creators from "../../../../../assets/TopBannerIcons/Creator.svg";
import Collector from "../../../../../assets/TopBannerIcons/Collector.svg";
import Holders from "../../../../../assets/TopBannerIcons/Holders.svg";

import { Box, Typography, Grid, useMediaQuery } from "@mui/material";
import dummyData from "./dummydata2";
const TopBanner = () => {
  // Initial state with the first image
  const isBelow1024px = useMediaQuery("(max-width: 1020px)");
  const isBelow1420px = useMediaQuery("(max-width: 1420px)");
  return (
    <Box sx={{ pt: -2 }}>
      <Grid
        container
        spacing={2}
        sx={{ p: 1, height: "300px", background: "none" }}
        display={"flex"}
        // justifyContent={"center"}
        // alignItems={"center"}
      >
        <>
          <Grid item lg={4} sm={4} xs={4}>
            <Box sx={{ p: 0 }}>
              <Box sx={{ textAlign: isBelow1420px ? "center" : "right" }}>
                <img
                  src={Creators}
                  style={{ textAlign: isBelow1420px ? "center" : "right" }}
                  alt={"creators"}
                  height={isBelow1024px ? 110 : isBelow1420px ? 160 : 220}
                  // className="pointer-on-hover"
                />
              </Box>
              <Box
                sx={{ textAlign: isBelow1420px ? "center" : "right", mt: -2 }}
              >
                <Typography
                  variant={isBelow1024px ? "body1" : "h5"}
                  sx={{
                    color: "#8E50D6",
                    pl: 1,
                    pr: isBelow1420px ? 0 : 3,
                    fontWeight: 600,
                    // fontSize: isBelow1420px ? 14 : 20,
                    fontFamily: "goodTime",
                  }}
                >
                  {"Creators"}
                </Typography>
              </Box>
            </Box>
          </Grid>
          <Grid item lg={4} sm={4} xs={4}>
            <Box sx={{ p: 0 }}>
              <Box sx={{ textAlign: "center" }}>
                <img
                  src={Collector}
                  style={{ textAlign: "center" }}
                  alt={"creators"}
                  height={isBelow1024px ? 110 : isBelow1420px ? 160 : 220}
                  // className="pointer-on-hover"
                />
              </Box>
              <Box sx={{ textAlign: "center", mt: -2 }}>
                <Typography
                  variant={isBelow1024px ? "body1" : "h5"}
                  sx={{
                    color: "#8E50D6",
                    pl: 1,
                    fontWeight: 600,
                    // fontSize: isBelow1420px ? 14 : 20,
                    fontFamily: "goodTime",
                  }}
                >
                  {"Collector"}
                </Typography>
              </Box>
            </Box>
          </Grid>
          <Grid item lg={4} sm={4} xs={4}>
            <Box sx={{ p: 0 }}>
              <Box sx={{ textAlign: isBelow1420px ? "center" : "left" }}>
                <img
                  src={Holders}
                  // style={{ textAlign: isBelow1420px ? "center" : "left" }}
                  alt={"creators"}
                  height={isBelow1024px ? 110 : isBelow1420px ? 160 : 220}
                  // className="pointer-on-hover"
                />
              </Box>
              <Box
                sx={{ textAlign: isBelow1420px ? "center" : "left", mt: -2 }}
              >
                <Typography
                  variant={isBelow1024px ? "body1" : "h5"}
                  sx={{
                    color: "#8E50D6",
                    pl: isBelow1420px ? 1 : 5,

                    fontWeight: 600,
                    // fontSize: isBelow1420px ? 14 : 20,
                    fontFamily: "goodTime",
                  }}
                >
                  {"Holders"}
                </Typography>
              </Box>
            </Box>
          </Grid>
        </>
      </Grid>
    </Box>
  );
};

export default TopBanner;
