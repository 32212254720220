import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { Box, Button, Grid, Typography, TextField } from "@mui/material";
// import "./Mint.css";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
// import styles from "./Mint.module.css";
import { useNavigate, useParams } from "react-router-dom";
import BackgroundWrapper from "../../../layout/Shared/BackgroundWrapper/BackgroundWrapper";
import { toast } from "react-hot-toast";
import { axiosRequestAuth, imageURL } from "../../../../services/api/api";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import CustomInput from "../../../shared/customInput/customInput";

import CustomLoader from "../../../shared/customLoader/customLoader";
import {
  getCollectionById,
  getCollectionDetailById,
} from "../../../../services/redux/NftApiReducer/NftApiReducer";
import { useDispatch, useSelector } from "react-redux";

const EditCollectionForm = () => {
  const { collectionDetail, VanityNfts } = useSelector((state) => state.nft);

  const [loading, setLoading] = useState(false);
  const bgcolor = "rgba(104, 103, 103, 0.5)";
  const txtcolor = "#f1f1f1";
  const navigate = useNavigate();
  const [categories, setCategories] = useState([]);
  const [isUSD, setIsUSD] = useState("");
  const params = useParams();
  const [formDataImage, setFormDataImage] = useState(null); // setState for selected image

  const [selectedImage, setSelectedImage] = useState(null); // setState for selected image
  const [showImageError, setShowImageError] = useState(false); // setState for selected image

  // testing ////////////////////////

  const [firstPart, setFirstPart] = useState(
    `/collection/${localStorage.getItem("accnum")}/`
  ); // Change 'FixedText' to your desired initial value
  const dispatch = useDispatch();
  const handleImageClick = () => {
    // Programmatically trigger the file input when the box is clicked
    document.getElementById("image-upload-input").click();
  };

  const schema = yup
    .object({
      collectionName: yup
        .string()
        .default(collectionDetail?.data?.data?.collection?.name)
        .required("Collection Name is required"),

      description: yup.string("Description is required"),

      royalty: yup
        .number()
        .test(
          "conditional-validation",
          "Royality is required for collection type USD",
          function (value) {
            const collectionType = this.parent.collectionType;

            if (typeof value !== "number") {
              throw this.createError({
                message: "Royality is required for collection type USD",
                path: this.path,
              });
            }

            return true; // Return true to indicate validation passed
          }
        ),
      taxonId: yup.string("ID us required"),

      // royality: yup.number(),
      // .required("Royalty is required")
      // .typeError("Royalty must be a number"),
    })
    .required();

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setFormDataImage(file);

      // You can perform additional checks here, e.g., file type, size, etc.

      // Read the selected file as a data URL and set it as the selected image
      const reader = new FileReader();
      reader.onload = (e) => {
        setSelectedImage(e.target.result);
      };
      reader.readAsDataURL(file);
    }
  };

  let gamifiedOptions = [
    { id: 1, name: "Yes" },
    { id: 0, name: "No" },
  ];

  let collectionMediaTypeOptions = [
    { id: "image", name: "Image" },
    { id: "audio", name: "Audio" },
    { id: "video", name: "Video" },
  ];
  let collectionTypeOptions = [
    { id: "A", name: "XRP" },
    { id: "B", name: "USD" },
  ];
  const onSubmit = (data) => {
    if (!selectedImage) {
      toast.error("Please select image");
    }

    let formData = new FormData();

    formData.append("collection_name", data?.collectionName);
    formData.append("collection_id", params.id);
    formData.append("taxon_id", data?.taxonId);

    formData.append("royalty", data?.royalty);
    formData.append("category_id", data?.category);
    formData.append("description", data?.description);
    formData.append("thumbnail", formDataImage);
    formData.append("price", data?.price);

    if (selectedImage) createCollection(formData);
  };

  const {
    register,
    reset,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });
  useEffect(() => {
    if (collectionDetail?.data?.data?.collection) {
      const {
        name,
        category_id,
        vanity_url,
        description,
        user_account,
        s3_bucket_link,
        price,
        royalty,
        taxon_id,
        collection_media_type,
        collection_type,
      } = collectionDetail?.data?.data?.collection;

      setValue("collectionName", name || "");
      setValue("category", category_id || "");
      setValue("vanityURL", vanity_url || "");
      setValue("description", description || "");
      setValue("issuer", user_account || "");
      setValue("bucket", s3_bucket_link || "");
      setValue("price", price || "");
      setValue("royalty", royalty || "");
      setValue("taxonId", taxon_id || "");
      setValue("collectionMediaType", collection_media_type || "");
      setValue("collectionType", collection_type || "A");
      setIsUSD(collection_type);
      setSelectedImage(
        `${imageURL}${collectionDetail?.data?.data?.collection?.thumbnail}`
      );
    }
  }, [collectionDetail, setValue]);

  useEffect(() => {
    getCategories();
  }, []);

  useEffect(() => {
    const apiData = {
      collection_id: params.id,
    };
    dispatch(getCollectionDetailById(apiData));
  }, [params.id]);

  async function getCategories() {
    try {
      const response = await axiosRequestAuth("get", "category");
      // setLoading(false);

      // toast.success(response.data.message);
      setCategories(response?.data?.data);
    } catch (error) {
      setLoading(false);
    }
  }

  async function createCollection(formData) {
    setLoading(true);

    try {
      const response = await axiosRequestAuth(
        "post",
        "v2/collection/edit",
        formData
      );
      reset();
      setFormDataImage();
      setSelectedImage();
      setLoading(false);
      navigate(-1);
      toast.success(response?.data?.message);
    } catch (error) {
      setLoading(false);
      toast.error(error?.response?.data?.message);
    }
  }

  const checkIfUSD = (price) => {
    setIsUSD(price);
  };

  console.log(
    collectionDetail?.data?.data?.collection,
    "collectionById",
    params
  );

  useEffect(() => {
    setLoading(collectionDetail.loading);
  }, [collectionDetail.loading]);
  return (
    <BackgroundWrapper>
      <Box>
        {loading ? (
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              minHeight: "80vh", // Set minimum height to fill the entire viewport
            }}
          >
            <CustomLoader width={"60%"} text={"Please wait ..."} />{" "}
          </Box>
        ) : (
          <>
            <Grid container spacing={2}>
              <Grid item lg={2}>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "start",
                    pt: 1,
                    ml: 1,
                  }}
                >
                  <Button
                    size="large"
                    sx={{ color: txtcolor, mt: 2 }}
                    onClick={() => navigate(-1)}
                  >
                    <ArrowBackIcon fontSize="large" sx={{ color: txtcolor }} />
                  </Button>
                </Box>
              </Grid>
              <Grid item lg={8}>
                <Box sx={{ display: "flex", justifyContent: "center", pt: 2 }}>
                  <Typography
                    variant="h4"
                    sx={{ color: txtcolor, fontWeight: 600, mt: 2 }}
                  >
                    Edit Collection
                  </Typography>
                </Box>
              </Grid>
              <Grid item lg={2}></Grid>
            </Grid>
            <Grid container spacing={2}>
              <Grid item lg={12} sm={12} sx={{ p: 1 }}>
                <Grid
                  item
                  lg={12}
                  sm={12}
                  sx={{
                    p: 2,
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      direction: "row",
                      justifyContent: "center",
                      pt: -2,
                    }}
                  >
                    <Box
                      sx={{
                        p: 2,
                        mt: 4,
                        display: "flex",
                        justifyContent: "center",
                      }}
                    >
                      <div>
                        {/* Hidden file input */}
                        <input
                          type="file"
                          accept="image/*"
                          {...register("image")}
                          error={!!errors.image}
                          helperText={errors.image?.message}
                          onChange={handleImageChange}
                          style={{ display: "none" }}
                          id="image-upload-input"
                        />

                        {/* Clickable box with image icon */}
                        <div
                          onClick={handleImageClick}
                          style={{
                            maxWidth: "100%",
                            minWidth: "250px",
                            height: "240px",
                            // border: "2px dashed purple",
                            borderRadius: 4,
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                            justifyContent: "center",
                            cursor: "pointer",
                            background: bgcolor,
                            border: `2px solid ${
                              !selectedImage && showImageError
                                ? "red"
                                : "transparent"
                            }`,
                          }}
                        >
                          {selectedImage ? (
                            <img
                              src={selectedImage}
                              alt="Selected"
                              style={{ maxWidth: "100%", maxHeight: "100%" }}
                            />
                          ) : (
                            <CloudUploadIcon style={{ fontSize: 48 }} />
                          )}
                        </div>
                        {!selectedImage && showImageError && (
                          <p style={{ color: "red", fontSize: 12 }}>
                            Image is required
                          </p>
                        )}
                      </div>
                    </Box>
                    <Box
                      sx={{
                        p: 2,
                        display: "flex",
                        justifyContent: "center",
                        minWidth: "800px",
                      }}
                    >
                      <form
                        onSubmit={handleSubmit(onSubmit)}
                        style={{ width: "100%" }}
                      >
                        {/* Place the form inside a grid item */}
                        <Box sx={{ p: 1, width: "100%" }}>
                          <Typography variant="body1" sx={{ color: txtcolor }}>
                            Collection Name*
                          </Typography>

                          <CustomInput
                            name="collectionName"
                            defaultValue={
                              "  collectionDetail?.data?.data?.collection?.name"
                            }
                            register={register}
                            errors={errors}
                            bgcolor={bgcolor}
                            txtcolor={txtcolor}
                            isFullWidth={true}
                          />
                        </Box>
                        {collectionDetail ? (
                          <Grid container spacing={2} sx={{ p: 1 }}>
                            <Grid item xs={12}>
                              <Typography
                                variant="body1"
                                sx={{ color: txtcolor }}
                              >
                                Taxon ID
                              </Typography>
                              <CustomInput
                                name="taxonId"
                                register={register}
                                errors={errors}
                                bgcolor={bgcolor}
                                txtcolor={txtcolor}
                                isFullWidth={true}
                              />
                            </Grid>
                          </Grid>
                        ) : (
                          <Grid container spacing={2} sx={{ p: 1 }}>
                            <Grid item xs={6}>
                              <Typography
                                variant="body1"
                                sx={{ color: txtcolor }}
                              >
                                Gamified or not?
                              </Typography>
                              <CustomInput
                                name="gamified"
                                register={register}
                                errors={errors}
                                bgcolor={bgcolor}
                                txtcolor={txtcolor}
                                isFullWidth={true}
                                options={gamifiedOptions}
                              />
                            </Grid>
                            <Grid item xs={6}>
                              <Typography
                                variant="body1"
                                sx={{ color: txtcolor }}
                              >
                                Taxon ID
                              </Typography>
                              <CustomInput
                                name="taxonId"
                                register={register}
                                errors={errors}
                                bgcolor={bgcolor}
                                txtcolor={txtcolor}
                                isFullWidth={true}
                              />
                            </Grid>
                          </Grid>
                        )}

                        <Grid
                          container
                          spacing={2}
                          display={"flex"}
                          direction={"row"}
                          p={1}
                        >
                          <Grid item xs={isUSD === "B" ? 3 : 6}>
                            <Typography
                              variant="body1"
                              sx={{ color: txtcolor }}
                            >
                              Collection Type
                            </Typography>
                            <CustomInput
                              name="collectionType"
                              register={register}
                              errors={errors}
                              bgcolor={bgcolor}
                              txtcolor={txtcolor}
                              isFullWidth={true}
                              defaultValue={isUSD}
                              options={collectionTypeOptions}
                              onChange={checkIfUSD}
                            />
                          </Grid>
                          {isUSD === "B" ? (
                            <Grid item xs={3}>
                              <Typography
                                variant="body1"
                                sx={{ color: txtcolor }}
                              >
                                Price $
                              </Typography>
                              <CustomInput
                                name="price"
                                register={register}
                                errors={errors}
                                bgcolor={bgcolor}
                                txtcolor={txtcolor}
                                isFullWidth={true}
                              />
                            </Grid>
                          ) : (
                            ""
                          )}

                          <>
                            <Grid item xs={6}>
                              <Typography
                                variant="body1"
                                sx={{ color: txtcolor }}
                              >
                                Royality
                              </Typography>
                              <CustomInput
                                name="royalty"
                                register={register}
                                errors={errors}
                                bgcolor={bgcolor}
                                txtcolor={txtcolor}
                                isFullWidth={true}
                              />
                            </Grid>
                          </>
                        </Grid>

                        <Box sx={{ p: 1 }}>
                          <Typography variant="body1" sx={{ color: txtcolor }}>
                            Description
                          </Typography>
                          <CustomInput
                            name="description"
                            register={register}
                            errors={errors}
                            bgcolor={bgcolor}
                            txtcolor={txtcolor}
                            isFullWidth={true}
                            multiline={true}
                          />
                        </Box>
                        <Box sx={{ p: 2 }}>
                          <Button
                            type="submit"
                            variant="contained"
                            sx={{
                              background: "#EFA61C",
                              color: "white",

                              "&:hover": {
                                backgroundColor: "#b2790d",
                                color: "f1f1f1",
                                "& .MuiSvgIcon-root": {
                                  color: "black", // Change the icon color on hover
                                },
                              },
                              "&:active": {
                                backgroundColor: "#e3ab12",
                                color: "f1f1f1",
                                "& .MuiSvgIcon-root": {
                                  color: "black", // Change the icon color on click
                                },
                              },
                              "&:disabled": {
                                backgroundColor: "#e3e3e3",
                                cursor: "not-allowed",
                              },
                            }}
                            onClick={() => {
                              setShowImageError(true);
                            }}
                          >
                            Update
                          </Button>
                        </Box>
                      </form>
                    </Box>
                  </Box>
                  {/* </Paper>{" "} */}
                </Grid>
                <Grid item lg={2} sm={0}></Grid>
              </Grid>{" "}
            </Grid>{" "}
          </>
        )}
      </Box>
    </BackgroundWrapper>
  );
};

export default EditCollectionForm;
