import React from "react";
import { Box, Grid, Typography, Link, Tooltip } from "@mui/material";
import LinkIcon from "@mui/icons-material/Link";
import { format } from "date-fns";
let bitOhmLink = "https://bithomp.com/";
const InfoItem = ({ title, value, isLink, linkValue }) => (
  <>
    <Grid item xs={12} md={4}>
      <Typography variant="subtitle1" sx={{ fontSize: "14px" }}>
        {title}:
      </Typography>
    </Grid>
    <Grid item xs={12} md={8}>
      {isLink ? (
        <Tooltip title={value} placement="top" arrow>
          <Link
            href="#"
            color="#AF22C8"
            underline="hover"
            sx={{ display: "flex", alignItems: "center", overflow: "hidden" }}
          >
            <LinkIcon
              sx={{ verticalAlign: "middle", mr: 1, fontSize: "20px" }}
            />
            <Typography
              variant="body2"
              noWrap
              sx={{
                color: "#af22c8",
                fontWeight: 500,
                fontSize: 14,
                textDecoration: "none",
              }}
              component="a"
              href={linkValue}
              target={"_blanK"}
            >
              {value}
            </Typography>
          </Link>
        </Tooltip>
      ) : (
        <Typography variant="body2" sx={{ fontSize: "13px" }}>
          {value}
        </Typography>
      )}
    </Grid>
  </>
);

const TokenInfo = ({ nftDetail }) => {
  const burnable = nftDetail?.flags?.burnable === true ? "Yes" : "No";
  const ownerWallet = nftDetail?.Owner || nftDetail?.issuer;
  const dateMinted = nftDetail?.createdAt || "N/A";

  return (
    <Box>
      <Grid container spacing={2}>
        <InfoItem
          title="Date Minted"
          value={format(new Date(dateMinted), "MM/dd/yyyy")}
        />
        <InfoItem
          title="Token ID"
          value={nftDetail?.nftokenID || "N/A"}
          isLink
          linkValue={`${bitOhmLink}/nft/${nftDetail?.nftokenID}`}
        />
        <InfoItem title="Is NFT burnable" value={burnable} />
        <InfoItem
          title="Creator Wallet"
          value={nftDetail?.issuer || "N/A"}
          isLink
          linkValue={`${bitOhmLink}/account/${nftDetail?.issuer}`}
        />
        <InfoItem
          title="Owner Wallet"
          value={nftDetail?.owner || "N/A"}
          isLink
          linkValue={`${bitOhmLink}/account/${nftDetail?.owner}`}
        />
      </Grid>
    </Box>
  );
};

export default TokenInfo;
