import React, { useEffect, useState } from "react";
import CustomTable from "../../../components/shared/customTable/customTable";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import BackgroundWrapper from "../../../components/layout/Shared/BackgroundWrapper/BackgroundWrapper";
import {
  Grid,
  Box,
  Typography,
  Button,
  Modal,
  Paper,
  IconButton,
} from "@mui/material";
import { axiosRequestAuth } from "../../../services/api/api";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import toast from "react-hot-toast";
import CloseIcon from "@mui/icons-material/Close";
import { useNavigate } from "react-router-dom";
import AddIcon from "@mui/icons-material/Add";
import CustomModal from "../../../components/shared/customModal/customModal";
import CustomInput from "../../../components/shared/customInput/customInput";
import CustomLoader from "../../../components/shared/customLoader/customLoader";
function AdminFeatured() {
  const [loading, setLoading] = useState(false);
  const [featuredNfts, setFeaturedNfts] = useState();
  const [deleteId, setDeleteId] = useState();
  const [openModal, setOpenModal] = useState(false);
  const [openSecondModal, setOpenSecondModal] = useState(false);

  // Example user data
  const navigate = useNavigate();

  const bgcolor = "rgba(104, 103, 103, 0.5)";
  const txtcolor = "#f1f1f1";
  // Handle the edit action

  const handelModalOpen = () => {
    setOpenModal(true);
  };

  const handelModalClose = () => {
    reset();
    setOpenModal(false);
  };

  const schema = yup
    .object({
      nftoken_id: yup.string().required("Token ID is required"),
    })
    .required();

  const handleEditClick = (data) => {
    // Handle edit action for the selected user
    navigate(`/admin/categories/${data.id}`);
  };

  const handleDeleteClick = (data) => {
    // Handle edit action for the selected user
    deleteFeatured(data.id);
    // setOpenModal(true);
    // setDeleteId(data.id);
  };

  useEffect(() => {
    getFeaturedNft();
  }, []);

  async function getFeaturedNft() {
    setLoading(true);
    try {
      const response = await axiosRequestAuth("get", "v2/featured");

      setFeaturedNfts(response?.data?.data?.featured_nfts);
      // toast.success(response.data.message);
      setLoading(false);
    } catch (error) {
      setLoading(false);

      toast.error(error.response.data.message);
    }
  }

  const deleteFeatured = async (deleteId) => {
    setLoading(true);
    try {
      const response = await axiosRequestAuth("post", "v2/featured/delete", {
        nft_id: deleteId,
      });

      setOpenModal(false);

      // getAllCollections();
      getFeaturedNft();
      setDeleteId("");
      setLoading(false);
      toast.success(response.data.message);
    } catch (error) {
      setOpenModal(false);
      setLoading(false);
      toast.error(error.response.data.message);
    }
  };
  const onSubmit = (data) => {
    createFeatured(data);
  };

  const createFeatured = async (data) => {
    setLoading(true);
    setOpenModal(false);
    try {
      const response = await axiosRequestAuth("post", "v2/featured ", {
        nftoken_id: data.nftoken_id,
      });
      reset();
      //   setAdmins(response.data.data);
      getFeaturedNft();
      setLoading(false);
      toast.success(response.data.message);
    } catch (error) {
      toast.error(error?.response?.data?.message);
      setLoading(false);
      reset();
    }
  };

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  const columns = [
    // { key: "id", label: "ID", dataType: "number" },
    { key: "nftokenID", label: "NFT Token ID", dataType: "text" },
    // { key: "Owner", label: "Owner", dataType: "text" },
    { key: "createdAt", label: "Created At", dataType: "date" },
    { key: "price", label: "Price", dataType: "number" },
    // { key: "Issuer", label: "Issuer", dataType: "text" },
  ];

  const modalContent = (
    <Box p={2}>
      <Typography variant="h6" sx={{ color: txtcolor }}>
        Are you sure you want to delete?
      </Typography>
      {/* Any additional content you want in the modal */}
    </Box>
  );

  return (
    <BackgroundWrapper>
      <Grid container spacing={1} pl={5} pr={10}>
        <Grid item lg={2}>
          {" "}
          <Box sx={{ display: "flex", justifyContent: "start", pt: 3, ml: 1 }}>
            <Button
              size="large"
              sx={{ color: txtcolor }}
              onClick={() => navigate(-1)}
            >
              <ArrowBackIcon fontSize="large" sx={{ color: txtcolor }} />
            </Button>
          </Box>
        </Grid>
        <Grid item lg={8}>
          <Box sx={{ display: "flex", justifyContent: "center", pt: 2, pb: 4 }}>
            <Typography
              variant="h4"
              sx={{
                fontWeight: 600,
                color: "white",
                textAlign: "center",
                py: 2,
              }}
            >{`Featured Nfts`}</Typography>
          </Box>
        </Grid>
        <Grid item lg={2}>
          <Box sx={{ display: "flex", justifyContent: "center", pt: 3 }}>
            <Button
              size="large"
              variant="contained"
              sx={{
                bgcolor: "#af22c8",
                "&:hover": {
                  bgcolor: "#62136f", // Adjust the darkness level as needed
                },
              }}
              onClick={() => handelModalOpen(true)}
            >
              {/* <AddIcon fontSize="large" sx={{ color: txtcolor }} /> */}
              Add Featured NFT
            </Button>
          </Box>
        </Grid>
      </Grid>
      <Grid container spacing={2}>
        <Grid xl={2} lg={2} md={2} sm={0} xs={0} />
        <Grid xl={8} lg={8} md={8} sm={12} xs={12}>
          <>
            {!loading ? (
              <CustomTable
                data={featuredNfts}
                columns={columns}
                onEditClick={handleEditClick}
                onDeleteClick={handleDeleteClick}
                showVisibleIcon={true}
                actions={true}
              />
            ) : (
              <CustomLoader width="20%" text={"Please wait ..."} />
            )}
          </>
        </Grid>
        <Grid xl={2} lg={2} md={2} sm={0} xs={0} />
      </Grid>
      <Modal
        componentsProps={{
          backdrop: { style: { backgroundColor: "rgba(0, 0, 0, 0.8)" } },
        }}
        open={openModal}
        onClose={handelModalClose}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            direction: "column",
            alignItems: "center",
            position: "absolute",
            left: "50%",
            top: "50%",
            transform: "translate(-50%, -50%)",
            pt: 6,
            flexDirection: "column",
          }}
        >
          <Paper
            elevation={2}
            sx={{
              bgcolor: "black",
              border: "solid 2px #af22c8",
              borderRadius: 4,
              color: "white",
              p: 4,
              width: "700px",
            }}
          >
            <form onSubmit={handleSubmit(onSubmit)} style={{ width: "100%" }}>
              <Box
                sx={{
                  justifyContent: "center",
                  flexDirection: "column",
                  alignItems: "center", // Center items vertically
                  py: 1,
                  // background: "grey",
                }}
              >
                <Grid container>
                  <Grid item xs={12}>
                    <Box
                      display={"flex"}
                      justifyContent={"space-between"}
                      alignItems={"center"}
                      sx={{ py: 0 }}
                    >
                      <Box />
                      <Box display={"flex"} textAlign={"center"} sx={{ pt: 1 }}>
                        <Typography variant="h6" sx={{ color: txtcolor }}>
                          Add Featured NFT
                        </Typography>
                      </Box>
                      <Box justifyContent={"end"} sx={{ mt: -8, mr: -2 }}>
                        <IconButton
                          sx={{
                            bgcolor: "none",
                            color: "white",
                            border: "2px solid #af22c8",
                            borderRadius: "8px", // Adjust the value to make it more square-ish
                            padding: "2px", // Adjust padding as needed
                          }}
                          onClick={handelModalClose}
                        >
                          <CloseIcon />
                        </IconButton>
                      </Box>
                    </Box>
                  </Grid>
                </Grid>
                {/* <Typography variant="body1" sx={{ color: txtcolor }}>
                  NFT Token ID*
                </Typography> */}
                <Box sx={{ py: 2 }}>
                  <CustomInput
                    name="nftoken_id"
                    register={register}
                    errors={errors}
                    bgcolor={bgcolor}
                    txtcolor={txtcolor}
                    placeholder={"NFT ID"}
                    isFullWidth={true}
                  />
                </Box>
                <Box textAlign={"center"}>
                  <Typography
                    variant="body1"
                    sx={{ fontSize: 12, color: "yellow" }}
                  >
                    Please ensure that the NFT ID you provide is listed for
                    sale.
                  </Typography>
                </Box>
                <Box display={"flex"} justifyContent={"center"} sx={{ pt: 2 }}>
                  <Button
                    fullWidth
                    variant="contained"
                    type="submit"
                    sx={{
                      width: "100px",
                      bgcolor: "#af22c8",
                      "&:hover": {
                        bgcolor: "#62136f", // Adjust the darkness level as needed
                      },
                    }}
                  >
                    Add
                  </Button>
                </Box>
              </Box>
            </form>
          </Paper>
        </Box>
      </Modal>
      <CustomModal
        open={openSecondModal}
        setOpenModal={setOpenSecondModal}
        content={modalContent}
        onConfirm={deleteFeatured}
      />
    </BackgroundWrapper>
  );
}

export default AdminFeatured;
