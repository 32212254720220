import React, { useEffect } from "react";
// import { makeStyles } from "@mui/styles";
import {
  Typography,
  Button,
  Grid,
  Box,
  Avatar,
  useMediaQuery,
  Divider,
} from "@mui/material";
import toast from "react-hot-toast";
import {
  accpetTransfer,
  resetAcceptTransferRes,
  getTransferOffer,
} from "../../../services/redux/MyNftApiReducer/MyNftApiReducer";
import defaultImage from "../../../assets/Default/default.png";
import { useDispatch, useSelector } from "react-redux";
import { imageURL } from "../../../services/api/api";
import {
  cancelTransferRequest,
  getAllBulkTransferNFTs,
  resetCancelTransfersState,
  resetbuyNFTState,
} from "../../../services/redux/NftApiReducer/NftApiReducer";
import {
  resetText,
  setText,
} from "../../../services/redux/TextReducer/TextReducer";
const buttonStyles = {
  background: "linear-gradient(to right, #7D3CE0, #af22c8) !important",
  color: "#fff !important",
  borderRadius: 2,
  width: "100%", // Ensures the button spans the full width
  transition: "background 0.3s ease", // Add a transition for smooth effect
  "&:hover": {
    background: "#fff !important",
    color: "#000 !important",
  },
};

const BulkTransferView = ({ data }) => {
  //   const classes = useStyles();
  const smallScreen = useMediaQuery("(max-width: 724px)");

  const LoginUser = localStorage.getItem("accnum");

  const dispatch = useDispatch();
  const { loading, cnacelTransferState } = useSelector((state) => state.nft);

  const handleCancleAccpet = (nft) => {
    dispatch(setText("Waiting xaman approval ..."));
    const apiData = {
      offer_id: nft.id,
    };
    dispatch(cancelTransferRequest(apiData));
  };
  // useEffect(() => {
  //   if (getTransferNftState?.status === true) {
  //     toast.success(getTransferNftState?.message);

  //     setTimeout(() => {
  //       dispatch(getTransferOffer());
  //     }, 100);
  //     dispatch(resetAcceptTransferRes());
  //   } else if (getTransferNftState?.status === false) {
  //     dispatch(getTransferOffer());
  //     toast.error(getTransferNftState?.message);
  //     // dispatch(resetAcceptTransferRes());
  //   }
  // }, [getTransferNftState]);

  useEffect(() => {
    console.log(cnacelTransferState, "acceptTransferRes");
    if (cnacelTransferState?.data?.status === true) {
      toast.success(cnacelTransferState?.data?.message);
      dispatch(resetText(""));
      dispatch(resetCancelTransfersState());
      dispatch(getAllBulkTransferNFTs());
    } else if (cnacelTransferState?.data?.status === false) {
      console.log(cnacelTransferState, "transferNft");
      toast.error(cnacelTransferState?.data?.message);
      dispatch(resetCancelTransfersState());
      dispatch(resetText(""));
    }
  }, [cnacelTransferState]);

  // acceptTransferRes

  console.log(data, "data");

  if (!data || data.length === 0) {
    return (
      <Box
        sx={{
          p: 4,
          pt: 8,
          display: "flex",
          justifyContent: "center",
          textAlign: "center",
        }}
      >
        <Typography
          sx={{ color: "white", fontFamily: "GoodTime" }}
          variant="body1"
        >
          No request available.
        </Typography>
      </Box>
    );
  }

  return (
    <Box
      sx={{
        p: 4,

        display: "flex",
        justifyContent: "center",
        textAlign: "center",
        flexDirection: "column",
      }}
    >
      {data.map((item, index) => (
        <>
          <Grid
            container
            spacing={2}
            sx={{ my: 2, p: 2 }}
            alignItems="center"
            key={index}
          >
            <Grid item lg={1} xs={12}>
              <Typography
                variant="h6"
                sx={{ fontSize: 14, fontWeight: 600, color: "white" }}
              >
                {index + 1}
              </Typography>
            </Grid>
            <Grid
              key={index}
              item
              lg={1}
              xs={12}
              sx={{
                justifyContent: "center",
                textAlign: "center",
                px: smallScreen ? 6 : 0,
              }}
            >
              {item?.user_nft?.metadata?.image ? (
                <Avatar
                  alt="Remy Sharp"
                  variant="rounded"
                  src={item.user_nft.metadata.image}
                />
              ) : (
                <Avatar alt="Remy Sharp" variant="rounded" src={defaultImage} />
              )}
            </Grid>
            <Grid item lg={8} xs={12}>
              <Typography
                variant="h6"
                align="center"
                sx={{
                  color: "white",
                  fontFamily: "goodTime",
                  fontSize: smallScreen ? 8 : 12,
                }}
              >
                <span style={{ color: "#af22c8" }}>NFT ID :</span>{" "}
                {item?.user_nft?.nftokenID}
              </Typography>
            </Grid>
            <Grid
              item
              lg={1}
              xs={12}
              sx={{
                justifyContent: "center",
                textAlign: "center",
                px: smallScreen ? 6 : 0,
              }}
            >
              {item?.wallet_address === LoginUser && (
                <Button
                  variant="contained"
                  onClick={() => {
                    handleCancleAccpet(item);
                  }}
                  sx={{
                    ml: 1,
                    background: "#818589",
                    color: "white",
                    width: "100%",
                    border: "solid 1px white",
                    "&:hover": {
                      backgroundColor: "white",
                      color: "black",
                      "& .MuiSvgIcon-root": {
                        color: "black",
                      },
                    },
                  }}
                >
                  <Typography
                    variant="h6"
                    sx={{ fontSize: 12, fontWeight: 600 }}
                  >
                    Cancel
                  </Typography>
                </Button>
              )}
            </Grid>
          </Grid>
          <Divider variant="middle" sx={{ bgcolor: "white" }} />
        </>
      ))}
    </Box>
  );
};

export default BulkTransferView;
