import React, { useEffect } from "react";
import {
  Table,
  TableContainer,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Button,
  Typography,
  Paper,
  Pagination,
  Box,
  PaginationItem,
  Tooltip,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import SyncIcon from "@mui/icons-material/Sync";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { format } from "date-fns";
import EditIcon from "@mui/icons-material/Edit";
import { axiosRequestAuth, imageURL } from "../../../services/api/api";
import ViewCarouselIcon from "@mui/icons-material/ViewCarousel";
import ShoppingBasketIcon from "@mui/icons-material/ShoppingBasket";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import { useState } from "react";
import { DragDropContext, Draggable, Droppable } from "@hello-pangea/dnd";

function CustomTable({
  draggable,
  data,
  columns,
  onEditClick,
  editable,
  onDeleteClick,
  onViewClick,
  actions,
  showVisibleIcon,
  useBooleanContent,
  showDeleteIcon,
  type,
  sync,
  onSyncClick,
  orderList,
  setOrderList,
  freeMint,
  freeMintClick,
  freeMintByOrder,
  freeMintByOrderClick,
  totalCount,
  handleChangePage,
  page,
  pagination,
}) {
  const cellStyle = {
    textAlign: "center",
    color: "#EFE1D1",
    fontWeight: "bold",
    padding: "2px 2px",
    height: "40px",
    minWidth: "100px",
  };

  const cellStyleHeader = {
    textAlign: "center",
    color: "#EFE1D1",
    fontWeight: "bold",
    fontSize: 16,
    padding: "2px 2px",
    height: "20px",
  };
  // console.log(data, "datadata");

  const [rows, setRows] = useState(data || []);
  const [orderArray, setOrderArray] = useState(orderList?.sorted_ids);
  useEffect(() => {
    setRows(data);
    setOrderArray(orderList?.sorted_ids);
  }, [data, orderList]);

  const handleDragEnd = async (result) => {
    if (!result.destination) return;
    if (result.destination.index === result.source.index) return;

    // Convert source and destination indices from rows array to orderArray
    const sourceId = orderedRows[result.source.index]?.id;
    const destinationId = orderedRows[result.destination.index]?.id;

    const sourceIndex = orderArray.indexOf(sourceId);
    const destinationIndex = orderArray.indexOf(destinationId);

    // Perform reordering in orderArray
    const items = Array.from(orderArray);
    const [reorderedItem] = items.splice(sourceIndex, 1);
    items.splice(destinationIndex, 0, reorderedItem);

    setOrderArray(items);

    try {
      let route = "category/updateorder";
      let data = { id: orderList?.id, sorted_ids: items };
      const response = await axiosRequestAuth("put", route, data);

      // setLoading(false);

      // toast.success(response.data.message);
    } catch (error) {}
  };
  const orderedRows = orderArray
    ?.map((orderId) => rows.find((row) => row?.id === orderId))
    ?.filter((item) => item !== undefined);

  return (
    <>
      {draggable ? (
        <DragDropContext onDragEnd={handleDragEnd}>
          <TableContainer
            component={Paper}
            sx={{
              background: "#3e3e3e",
              border: "1px solid #ccc",
              borderRadius: "10px",
              maxHeight: "70vh",
            }}
          >
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell sx={cellStyleHeader}>No.</TableCell>
                  {columns.map((column, index) => (
                    <TableCell key={index} sx={cellStyleHeader}>
                      <Typography
                        variant="body1"
                        sx={{ fontWeight: 600, fontSize: 16 }}
                      >
                        {column.label}
                      </Typography>
                    </TableCell>
                  ))}
                  {actions === true ? (
                    <TableCell sx={cellStyleHeader}>
                      <Typography
                        variant="body1"
                        sx={{ fontWeight: 600, fontSize: 18 }}
                      >
                        Action
                      </Typography>
                    </TableCell>
                  ) : (
                    ""
                  )}
                </TableRow>
              </TableHead>

              <Droppable droppableId="table">
                {(provided) => (
                  <TableBody
                    ref={provided.innerRef}
                    {...provided.droppableProps}
                  >
                    {orderedRows?.map((item, index) => (
                      <Draggable
                        key={item?.id}
                        draggableId={item?.id?.toString()}
                        index={index}
                      >
                        {(provided) => (
                          <TableRow
                            key={index}
                            sx={{
                              background: "transparent",
                              "&:hover": { background: "#2c2c2c" },
                            }}
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                          >
                            <TableCell sx={cellStyle}>
                              {page ? index + 1 + (page - 1) * 10 : index + 1}
                            </TableCell>
                            {columns.map((column, columnIndex) => (
                              <TableCell key={columnIndex} sx={cellStyle}>
                                {column.dataType === "image" ? (
                                  <img
                                    src={`${imageURL}${item[column.key]}`}
                                    alt="Image"
                                    width="50"
                                    height="50"
                                  />
                                ) : column.dataType === "number" ? (
                                  Number(item[column.key]).toLocaleString()
                                ) : column.dataType === "date" ? (
                                  format(
                                    new Date(item[column.key]),
                                    "MM/dd/yyyy"
                                  )
                                ) : column.dataType && // Check if useBooleanContent is true
                                  typeof item[column.key] === "boolean" ? ( // If it's a boolean
                                  item[column.key] ? (
                                    "Yes"
                                  ) : (
                                    "No"
                                  )
                                ) : (
                                  item[column.key] || "NA"
                                )}
                              </TableCell>
                            ))}
                            {actions === true && (
                              <TableCell sx={cellStyle}>
                                {sync &&
                                item?.collection_type !== "A" &&
                                type === "collection" ? (
                                  <Button
                                    variant="outlined"
                                    color="primary"
                                    sx={{
                                      border: "none",
                                      color: "#af22c8",
                                      p: 0,
                                      m: 0,
                                    }}
                                    onClick={() => onSyncClick(item?.id)}
                                  >
                                    <SyncIcon sx={{ p: 0, m: 0 }} />
                                  </Button>
                                ) : (
                                  type === "collection" && (
                                    <Button
                                      disabled={true}
                                      variant="outlined"
                                      color="primary"
                                      sx={{
                                        border: "none",
                                        color: "#af22c8",
                                        p: 0,
                                        m: 0,
                                      }}
                                      onClick={() => onSyncClick(item?.id)}
                                    >
                                      <SyncIcon sx={{ p: 0, m: 0 }} />
                                    </Button>
                                  )
                                )}
                                {editable === true &&
                                item?.collection_type !== "A" ? (
                                  <Button
                                    variant="outlined"
                                    color="primary"
                                    sx={{
                                      border: "none",
                                      color: "#af22c8",
                                      p: 0,
                                      m: 0,
                                    }}
                                    onClick={() => onEditClick(item)}
                                  >
                                    <EditIcon />
                                  </Button>
                                ) : (
                                  <Button
                                    disabled={true}
                                    variant="outlined"
                                    color="primary"
                                    sx={{
                                      border: "none",
                                      color: "#af22c8",
                                      p: 0,
                                      m: 0,
                                    }}
                                    onClick={() => onEditClick(item)}
                                  >
                                    <EditIcon />
                                  </Button>
                                )}
                                {!showDeleteIcon && (
                                  <Button
                                    variant="outlined"
                                    color="primary"
                                    sx={{
                                      border: "none",
                                      color: "#af22c8",
                                      p: 0,
                                      m: 0,
                                    }}
                                    onClick={() => onDeleteClick(item)}
                                  >
                                    <DeleteIcon sx={{ p: 0, m: 0 }} />
                                  </Button>
                                )}

                                {type === "collection" && (
                                  <Button
                                    disabled={showVisibleIcon}
                                    variant="outlined"
                                    color="primary"
                                    sx={{
                                      border: "none",
                                      color: "#af22c8",
                                      p: 0,
                                      m: 0,
                                    }}
                                    onClick={() => onViewClick(item)}
                                  >
                                    <ViewCarouselIcon sx={{ p: 0, m: 0 }} />
                                  </Button>
                                )}
                              </TableCell>
                            )}
                          </TableRow>
                        )}
                      </Draggable>
                    ))}
                    {provided.placeholder}
                  </TableBody>
                )}
              </Droppable>
            </Table>
          </TableContainer>
        </DragDropContext>
      ) : (
        <TableContainer
          sx={{
            background: "#3e3e3e",
            border: "1px solid #ccc",
            borderRadius: "10px",
            maxHeight: "70vh",
          }}
        >
          <Table>
            <TableHead>
              <TableRow>
                <TableCell sx={cellStyleHeader}>No.</TableCell>
                {columns.map((column, index) => (
                  <TableCell key={index} sx={cellStyleHeader}>
                    <Typography
                      variant="body1"
                      sx={{ fontWeight: 600, fontSize: 16 }}
                    >
                      {column.label}
                    </Typography>
                  </TableCell>
                ))}
                {actions === true ? (
                  <TableCell sx={cellStyleHeader}>
                    <Typography
                      variant="body1"
                      sx={{ fontWeight: 600, fontSize: 18 }}
                    >
                      Action
                    </Typography>
                  </TableCell>
                ) : (
                  ""
                )}
              </TableRow>
            </TableHead>
            <TableBody>
              {data &&
                data.map((item, index) => (
                  <TableRow
                    key={index}
                    sx={{
                      background: "transparent",
                      "&:hover": { background: "#2c2c2c" },
                    }}
                  >
                    <TableCell sx={cellStyle}>
                      {page ? index + 1 + (page - 1) * 10 : index + 1}
                    </TableCell>
                    {columns.map((column, columnIndex) => (
                      <TableCell key={columnIndex} sx={cellStyle}>
                        {column.dataType === "image" ? (
                          <img
                            src={`${imageURL}${item[column.key]}`}
                            alt="Image"
                            width="50"
                            height="50"
                          />
                        ) : column.dataType === "order_check" ? (
                          item["order_id"] ? (
                            "Wordpress"
                          ) : (
                            "Manual"
                          )
                        ) : column.dataType === "order_id" ? (
                          item["order_id"] ? (
                            Number(item[column.key])
                          ) : (
                            Number(item["id"])
                          )
                        ) : column.dataType === "number" ? (
                          item[column.key]?.length > 40 ? (
                            <Tooltip title={item[column.key]}>
                              {item[column.key]?.slice(0, 5) +
                                "..." +
                                item[column.key]?.slice(
                                  item[column.key]?.length - 5,
                                  item[column.key]?.length
                                )}
                            </Tooltip>
                          ) : (
                            Number(item[column.key]).toLocaleString()
                          )
                        ) : column.dataType === "date" ? (
                          format(new Date(item[column.key]), "MM/dd/yyyy")
                        ) : column.dataType && // Check if useBooleanContent is true
                          typeof item[column.key] === "boolean" ? ( // If it's a boolean
                          item[column.key] ? (
                            "Yes"
                          ) : (
                            "No"
                          )
                        ) : item[column.key]?.length > 40 ? (
                          <Tooltip title={item[column.key]}>
                            {item[column.key]?.slice(0, 5) +
                              "..." +
                              item[column.key]?.slice(
                                item[column.key]?.length - 5,
                                item[column.key]?.length
                              )}
                          </Tooltip>
                        ) : (
                          item[column.key] || "NA"
                        )}
                      </TableCell>
                    ))}
                    {actions === true ? (
                      <TableCell sx={cellStyle}>
                        {editable === true ? (
                          <Button
                            variant="outlined"
                            color="primary"
                            sx={{
                              border: "none",
                              color: "#af22c8",
                              p: 0,
                              m: 0,
                            }}
                            onClick={() => onEditClick(item)}
                          >
                            {" "}
                            {item?.collection_type === "A" ? "" : <EditIcon />}
                          </Button>
                        ) : (
                          ""
                        )}
                        {!showDeleteIcon && (
                          <Button
                            variant="outlined"
                            color="primary"
                            sx={{
                              border: "none",
                              color: "#af22c8",
                              p: 0,
                              m: 0,
                            }}
                            onClick={() => onDeleteClick(item)}
                          >
                            <DeleteIcon sx={{ p: 0, m: 0 }} />
                          </Button>
                        )}

                        {!showVisibleIcon && (
                          <Button
                            variant="outlined"
                            color="primary"
                            sx={{
                              border: "none",
                              color: "#af22c8",
                              p: 0,
                              m: 0,
                            }}
                            onClick={() => onViewClick(item)}
                          >
                            <ViewCarouselIcon sx={{ p: 0, m: 0 }} />
                          </Button>
                        )}
                        {freeMint && (
                          <Button
                            variant="outlined"
                            color="primary"
                            sx={{
                              border: "none",
                              color: "#af22c8",
                              p: 0,
                              m: 0,
                            }}
                            onClick={() => freeMintClick(item)}
                          >
                            <ShoppingBasketIcon sx={{ p: 0, m: 0 }} />
                          </Button>
                        )}
                        {freeMintByOrder && (
                          <Button
                            variant="outlined"
                            color="primary"
                            sx={{
                              border: "none",
                              color: "#af22c8",
                              p: 0,
                              m: 0,
                            }}
                            onClick={() => freeMintByOrderClick(item)}
                          >
                            <RemoveRedEyeIcon sx={{ p: 0, m: 0 }} />
                          </Button>
                        )}
                      </TableCell>
                    ) : (
                      ""
                    )}
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}

      {pagination && (
        <Pagination
          count={Math.ceil(totalCount / 10)}
          page={page}
          onChange={handleChangePage}
          sx={{
            margin: "20px auto",
            display: "flex",
            justifyContent: "center",
          }}
          renderItem={(item) => (
            <PaginationItem
              {...item}
              sx={{
                color: "#ffffff",
                "&:hover": {
                  backgroundColor: "#62136f",
                },
                "&.Mui-selected": {
                  backgroundColor: "#62136f",
                  color: "#ffffff",
                  "&:hover": {
                    backgroundColor: "#62136f",
                  },
                },
                borderRadius: "50%",
                margin: "0 5px",
              }}
            />
          )}
        />
      )}
    </>
  );
}

export default CustomTable;
