import React from "react";
import { Box, Typography } from "@mui/material";

const NotFound = () => {
  return (
    <Box
      sx={{
        height: "calc(100vh - 230px)",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Typography sx={{ color: "white", fontSize: 24, fontWeight: 800 }}>
        Page Not Found
      </Typography>
    </Box>
  );
};

export default NotFound;
