import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  text: "",
};

const textSlice = createSlice({
  name: "text",
  initialState,
  reducers: {
    setText(state, action) {
      state.text = action.payload;
    },
    resetText(state, action) {
      state.text = "";
    },
  },
});

export const { setText, resetText } = textSlice.actions;

export default textSlice.reducer;
