import React, { useEffect, useState } from "react";
import CustomTable from "../../../../components/shared/customTable/customTable";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import CloseIcon from "@mui/icons-material/Close";

import BackgroundWrapper from "../../../../components/layout/Shared/BackgroundWrapper/BackgroundWrapper";
import {
  Grid,
  Box,
  Typography,
  Button,
  Modal,
  Paper,
  IconButton,
  TextField,
  InputAdornment,
  useMediaQuery,
} from "@mui/material";

import toast from "react-hot-toast";
import SearchIcon from "@mui/icons-material/Search";
import { useNavigate } from "react-router-dom";

import CustomLoader from "../../../../components/shared/customLoader/customLoader";
import { useDispatch, useSelector } from "react-redux";
import {
  getWalletAddressesAPI,
  getWalletAddressesSearchAPI,
  resetGetWalletAddressesAPI,
  resetGetWalletAddressesSearchAPI,
} from "../../../../services/redux/MyNftApiReducer/MyNftApiReducer";
import UserOrder from "./UserOrder";

function AddUser() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(true);
  const [openModal, setOpenModal] = useState(false);
  const [order_id, setOrder_id] = useState(null);
  const is1024ScreenSize = useMediaQuery("(min-width:1024px)");
  const {
    getWalletAddressesData,
    addWalletAddressesData,
    getWalletAddressesSearchData,
    loading: loader,
  } = useSelector((state) => state.mynft);
  const [walletAddresses, setWalletAddresses] = useState([]);

  const [totalCount, setTotalCount] = useState(0);
  const [page, setPage] = useState(1);
  const [search, setSearch] = useState(null);
  const [searchClick, setSearchClick] = useState(false);
  const txtcolor = "#f1f1f1";
  // Handle the edit action
  const bgcolor = "rgba(104, 103, 103, 0.5)";
  const columns = [
    //{ key: "order_item_id", label: "Order Item ID", dataType: "number" },
    { key: "order_id", label: "Order ID", dataType: "order_id" },
    { key: "ord", label: "Order Type", dataType: "order_check" },
    { key: "wallet_address", label: "Wallet Address", dataType: "text" },
    { key: "collection_name", label: "Collection Name", dataType: "text" },
    { key: "minted", label: "Minted", dataType: "number" },
    { key: "available_mints", label: "Total", dataType: "number" },
  ];
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  useEffect(() => {
    if (searchClick) {
      if (search)
        dispatch(
          getWalletAddressesSearchAPI({
            search: search,
            offset: page,
            limit: 10,
          })
        );
      else if (!search)
        dispatch(getWalletAddressesAPI({ offset: page, limit: 10 }));
    }
  }, [searchClick]);

  useEffect(() => {
    if (search)
      dispatch(
        getWalletAddressesSearchAPI({ search: search, offset: page, limit: 10 })
      );
    else dispatch(getWalletAddressesAPI({ offset: page, limit: 10 }));
  }, [page]);

  useEffect(() => {
    if (getWalletAddressesData?.data?.status) {
      setWalletAddresses(
        getWalletAddressesData?.data?.data?.allFreeMints || []
      );
      setSearchClick(false);
      setTotalCount(getWalletAddressesData?.data?.data?.userOrderCount || 0);
      dispatch(resetGetWalletAddressesAPI());
    }
  }, [getWalletAddressesData?.data?.status]);

  useEffect(() => {
    if (getWalletAddressesSearchData?.data?.status) {
      setWalletAddresses(
        getWalletAddressesSearchData?.data?.data?.allFreeMints || []
      );
      setTotalCount(
        getWalletAddressesSearchData?.data?.data?.userOrderCount || 0
      );
      setSearchClick(false);
      dispatch(resetGetWalletAddressesSearchAPI());
    }
  }, [getWalletAddressesSearchData?.data?.status]);

  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      // Call your function here
      if (page > 1) setPage(1);
      setSearchClick(true);
    }
  };
  const handleSearch = (e) => {
    console.log(e.target.value);
    if (e.target.value === "") {
      if (page > 1) setPage(1);
      setSearch(null);
    } else {
      setSearch(e.target.value);
    }
  };

  useEffect(() => {
    setLoading(loader);
  }, [loader]);

  const handleClose = () => {
    setOrder_id(null);
    setOpenModal(false);
  };
  const handelModalOpen = () => {
    setOpenModal(true);
  };
  const handleFreeMintByOrderClick = (data) => {
    setOrder_id(data.order_id);
    handelModalOpen();
  };

  return (
    <BackgroundWrapper>
      <Grid container spacing={1} pl={5}>
        <Grid item lg={2}>
          {" "}
          <Box sx={{ display: "flex", justifyContent: "start", pt: 3, ml: 1 }}>
            <Button
              size="large"
              sx={{ color: txtcolor }}
              onClick={() => navigate(-1)}
            >
              <ArrowBackIcon fontSize="large" sx={{ color: txtcolor }} />
            </Button>
          </Box>
        </Grid>
        <Grid item lg={7}>
          <Box sx={{ display: "flex", justifyContent: "center", pt: 2, pb: 4 }}>
            <Typography
              variant="h4"
              sx={{
                fontWeight: 600,
                color: "white",
                textAlign: "center",
                py: 2,
              }}
            >{`User Minting Orders`}</Typography>
          </Box>
        </Grid>
        <Grid
          item
          lg={3}
          gap={2}
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Box sx={{ display: "flex", justifyContent: "center" }}>
            <Button
              size="medium"
              variant="contained"
              sx={{
                bgcolor: "#af22c8",
                "&:hover": {
                  bgcolor: "#62136f", // Adjust the darkness level as needed
                },
              }}
              onClick={() => {
                navigate("/bulk-add-wallet");
                //handelModalOpen();
              }}
            >
              {/* <AddIcon fontSize="large" sx={{ color: txtcolor }} /> */}
              Add Orders
            </Button>
          </Box>
          <Box sx={{ display: "flex", justifyContent: "center" }}>
            <Button
              size="medium"
              variant="contained"
              sx={{
                bgcolor: "#af22c8",
                "&:hover": {
                  bgcolor: "#62136f", // Adjust the darkness level as needed
                },
              }}
              onClick={() => {
                navigate("/bulk-add-mints");
                //handelModalOpen();
              }}
            >
              {/* <AddIcon fontSize="large" sx={{ color: txtcolor }} /> */}
              Add Manual Orders
            </Button>
          </Box>
        </Grid>
      </Grid>
      <Grid container justifyContent={"center"} spacing={2} pb={4}>
        <Grid item>
          <Box
            sx={{
              ...(is1024ScreenSize ? { pt: 3.5, mr: 4 } : { pt: 3.5, mr: 2 }), // Conditionally apply styles
            }}
          >
            <TextField
              //key={openModal}
              style={{ width: "230px" }}
              size="small"
              placeholder="Search wallet address"
              onKeyPress={handleKeyPress}
              onChange={handleSearch}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon sx={{ color: "#D7D1D1" }} />
                  </InputAdornment>
                ),
                sx: {
                  color: "#D7D1D1",
                  backgroundColor: "rgba(255, 255, 255, 0.2)",
                  borderRadius: 6,
                  "& .MuiOutlinedInput-notchedOutline": {},
                  "&:hover .MuiOutlinedInput-notchedOutline": {
                    border: " 2px solid #7F6C6C",
                  },
                  "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                    border: " 2px solid #7F6C6C",
                  },
                  height: "35px",
                },
              }}
              autoComplete="off" // Add this line to turn off auto-fill
            />
          </Box>
        </Grid>
      </Grid>
      <Grid container spacing={2}>
        <Grid xl={2} lg={2} md={2} sm={0} xs={0} />
        <Grid xl={8} lg={8} md={8} sm={12} xs={12}>
          <>
            {!loading ? (
              <CustomTable
                data={walletAddresses}
                editable={false}
                columns={columns}
                // onEditClick={handleEditClick}
                // onDeleteClick={handleDeleteClick}
                showVisibleIcon={true}
                actions={false}
                pagination={true}
                totalCount={totalCount}
                handleChangePage={handleChangePage}
                page={page}

                //freeMintByOrderClick={handleFreeMintByOrderClick}
                //freeMintByOrder={true}
              />
            ) : (
              loading &&
              !openModal && (
                <CustomLoader width="20%" text={"Please wait ..."} />
              )
            )}
          </>
        </Grid>
        <Grid xl={2} lg={2} md={2} sm={0} xs={0} />
      </Grid>

      <UserOrder
        handleClose={handleClose}
        openModal={openModal}
        id={order_id}
      />
    </BackgroundWrapper>
  );
}

export default AddUser;
