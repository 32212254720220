import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Box, Typography, useMediaQuery } from "@mui/material";

const SimpleSliderCampaigns = ({ images, selected, setselected }) => {
  const isBelow1824px = useMediaQuery("(max-width: 1824px)");
  const isBelow724px = useMediaQuery("(max-width: 724px)");

  const settings = {
    dots: false,
    infinite: false,
    focusOnSelect: true,
    lazyLoad: true,
    speed: 1000,
    autoplay: true,
    slidesToShow: 5,
    slidesToScroll: 1,
    responsive: [
      { breakpoint: 3840, settings: { slidesToShow: 6 } },
      { breakpoint: 1980, settings: { slidesToShow: 5 } },
      { breakpoint: 999, settings: { slidesToShow: 4 } },
      { breakpoint: 768, settings: { slidesToShow: 2 } },
      { breakpoint: 480, settings: { slidesToShow: 1 } },
    ],
  };

  return (
    <Box sx={{ pt: 2, pb: 2, position: "relative", zIndex: 5 }}>
      <Box sx={{ textAlign: "center", mb: 2 }}>
        <Typography
          variant="h4"
          sx={{
            color: "white",
            fontWeight: "bold",
            fontFamily: "goodTime",
            fontSize: { xs: 18, lg: 24 },
            paddingY: "10px",
          }}
        >
          {"OPEN PACK"}
        </Typography>
      </Box>

      <Box sx={{ px: 2 }}>
        <Slider {...settings}>
          {images?.map((image, index) => (
            <Box
              key={index}
              sx={{
                display: "flex",
                justifyContent: "center",
                cursor: "pointer",
                objectFit: "contain",
              }}
              onClick={() => setselected(image)}
            >
              <Box
                sx={{
                  p: "4px",
                  borderRadius: "10px",
                  background:
                    selected === image
                      ? "linear-gradient(45deg, #DA00FF, #00FF7F)"
                      : "transparent",
                  display: "inline-block",
                }}
              >
                <img
                  src={
                    "https://ipfs.io/ipfs/" +
                    image?.content?.image?.split("/").pop()
                  }
                  alt={`slide-${index}`}
                  style={{
                    width: "100%",
                    borderRadius: "8px",
                    display: "block",
                  }}
                />
              </Box>
            </Box>
          ))}
        </Slider>
      </Box>
    </Box>
  );
};

export default SimpleSliderCampaigns;
