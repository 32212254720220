// src/theme.js (or any other filename you prefer)
import { createTheme } from "@mui/material/styles";

// Import your custom font files
import CustomFontRegular from "./assets/Fonts/goodtimesrg.otf";
import CustomFontBold from "./assets/Fonts/goodtimesrg.otf";

// Define your custom theme
const theme = createTheme({
  typography: {
    fontFamily: "CustomFont, sans-serif", // Replace 'CustomFont' with a suitable name
    fontWeightRegular: 400,
    fontWeightBold: 700,
  },
  overrides: {
    MuiCssBaseline: {
      "@global": {
        "@font-face": [
          {
            fontFamily: "GoodTimes",
            fontStyle: "normal",
            fontWeight: 400,
            src: `
              local('CustomFont-Regular'),
              url(${CustomFontRegular}) format('otf')
            `,
          },
          {
            fontFamily: "GoodTimes",
            fontStyle: "normal",
            fontWeight: 700,
            src: `
              local('CustomFont-Bold'),
              url(${CustomFontBold}) format('otf')
            `,
          },
        ],
      },
    },
  },
});

export default theme;
