import React, { useEffect, useState } from "react";
import CustomTable from "../../../../components/shared/customTable/customTable";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import CloseIcon from "@mui/icons-material/Close";

import BackgroundWrapper from "../../../../components/layout/Shared/BackgroundWrapper/BackgroundWrapper";
import {
  Grid,
  Box,
  Typography,
  Button,
  Modal,
  Paper,
  IconButton,
} from "@mui/material";

import toast from "react-hot-toast";

import { useNavigate } from "react-router-dom";

import CustomLoader from "../../../../components/shared/customLoader/customLoader";
import { useDispatch, useSelector } from "react-redux";
import {
  getWalletAddressesAPI,
  getWalletAddressesByOrderAPI,
  resetGetWalletAddressesAPI,
  resetGetWalletAddressesByOrderAPI,
} from "../../../../services/redux/MyNftApiReducer/MyNftApiReducer";

function UserOrder({ openModal, handleClose, id }) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(true);
  const {
    getWalletAddressesByOrderData,
    addWalletAddressesData,
    loading: loader,
  } = useSelector((state) => state.mynft);
  const [walletAddresses, setWalletAddresses] = useState([]);
  const txtcolor = "#f1f1f1";
  // Handle the edit action
  const bgcolor = "rgba(104, 103, 103, 0.5)";
  const columns = [
    //{ key: "order_item_id", label: "Order Item ID", dataType: "number" },
    //{ key: "order_id", label: "Order ID", dataType: "number" },
    { key: "collection_name", label: "Collection Name", dataType: "text" },
    { key: "wallet_address", label: "Wallet Address", dataType: "text" },
    { key: "NFTTAXONID", label: "Taxon ID", dataType: "text" },
    {
      key: "remaining_free_mints",
      label: "Remaining Mints",
      dataType: "number",
    },
    //{ key: "number_of_mints", label: "No. Of Mints", dataType: "number" },
  ];

  useEffect(() => {
    if (id) {
      dispatch(getWalletAddressesByOrderAPI({ order_id: id }));
    }
  }, [id]);

  useEffect(() => {
    if (getWalletAddressesByOrderData?.data?.status) {
      setWalletAddresses(getWalletAddressesByOrderData?.data?.data || []);
      dispatch(resetGetWalletAddressesByOrderAPI());
    }
  }, [getWalletAddressesByOrderData?.data?.status]);

  useEffect(() => {
    setLoading(loader);
  }, [loader]);

  return (
    <Modal
      componentsProps={{
        backdrop: { style: { backgroundColor: "rgba(0, 0, 0, 0.8)" } },
      }}
      open={openModal}
      onClose={handleClose}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          direction: "column",
          alignItems: "center",
          position: "absolute",
          left: "50%",
          top: "50%",
          transform: "translate(-50%, -50%)",
          pt: 6,

          flexDirection: "column",
        }}
      >
        <Paper
          elevation={2}
          sx={{
            bgcolor: "black",
            border: "solid 2px #af22c8",
            borderRadius: 4,
            color: "white",
            p: 4,
            width: "80vw",
          }}
        >
          <Box
            display={"flex"}
            justifyContent={"space-between"}
            alignItems={"center"}
            sx={{ py: 1 }}
          >
            <Box />

            <Box
              justifyContent={"end"}
              sx={{
                mt: { xs: -1, sm: -2, md: -4, lg: -4 },
                mr: { xs: 1, sm: 0, md: -2, lg: -2 },
              }}
            >
              <IconButton
                sx={{
                  bgcolor: "none",
                  color: "white",
                  border: "2px solid #af22c8",
                  borderRadius: "8px", // Adjust the value to make it more square-ish
                  padding: "2px", // Adjust padding as needed
                }}
                onClick={handleClose}
              >
                <CloseIcon />
              </IconButton>
            </Box>
          </Box>
          <Grid container spacing={2}>
            <Grid xl={2} lg={2} md={2} sm={0} xs={0} />
            <Grid xl={8} lg={8} md={8} sm={12} xs={12}>
              <>
                {!loading ? (
                  <CustomTable
                    data={walletAddresses}
                    editable={false}
                    columns={columns}
                    // onEditClick={handleEditClick}
                    // onDeleteClick={handleDeleteClick}
                    showVisibleIcon={true}
                    actions={false}
                  />
                ) : (
                  <CustomLoader width="20%" text={"Please wait ..."} />
                )}
              </>
            </Grid>
            <Grid xl={2} lg={2} md={2} sm={0} xs={0} />
          </Grid>
        </Paper>
      </Box>
    </Modal>
  );
}

export default UserOrder;
