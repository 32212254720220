import React from "react";
import { Box, Grid, Typography, Paper, useMediaQuery } from "@mui/material";
import dummyData from "./dummydata2";
import "./BottomBanner.css";
import Partners1 from "../../../../../assets/PartenersIcons/Partners1.svg";
import Partners2 from "../../../../../assets/PartenersIcons/Partners2.svg";
import Partners3 from "../../../../../assets/PartenersIcons/Partners3.png";
import Partners4 from "../../../../../assets/PartenersIcons/Partners4.svg";

const BottomBanner = () => {
  const isBelow1024px = useMediaQuery("(max-width: 1024px)");
  const isBelow510px = useMediaQuery("(max-width: 1024px)");

  return (
    <Box sx={{ background: "#121212", mt: 6 }}>
      <Typography
        variant="h5"
        sx={{
          pt: 4,
          fontWeight: 600,
          color: "white",
          display: "flex",
          justifyContent: "center",
          fontSize: 28,
          fontFamily: "goodTime",
        }}
      >
        {"Our Partners"}
      </Typography>
      <Grid container sx={{ mb: 2, pt: 6, px: 1 }}>
        <Grid item xs={12}>
          <Paper
            sx={{
              width: "100%",
              height: isBelow1024px ? 90 : 125,
              borderRadius: 6,
              px: 1,
            }}
            // elevation={4}
          >
            <Grid container rowSpacing={4} columnSpacing={1} sx={{ p: 0 }}>
              <Grid item lg={2} sm={2} xs={2}>
                {/* <Box sx={{ px: 0, pb: 20, justifyContent: "center" }}>
                  <Box sx={{ textAlign: "center" }}>
                    <img
                      src={Partners1}
                      alt={"Partners1"}
                      height={isBelow1024px ? 28 : 60}
                      // className="pointer-on-hover"
                    />
                  </Box>
                </Box> */}
              </Grid>
              <Grid item lg={4} sm={4} xs={4}>
                <Box sx={{ px: 0, justifyContent: "center" }}>
                  <Box sx={{ textAlign: "center" }}>
                    <img
                      src={Partners2}
                      alt={"Partners2"}
                      height={isBelow1024px ? 28 : 60}
                      // className="pointer-on-hover"
                    />
                  </Box>
                </Box>
              </Grid>
              <Grid item lg={4} sm={4} xs={4}>
                <Box sx={{ px: 0, justifyContent: "center" }}>
                  <Box sx={{ textAlign: "center" }}>
                    <img
                      src={Partners3}
                      alt={"Partners3"}
                      height={isBelow1024px ? 34 : 90}
                      // className="pointer-on-hover"
                    />
                  </Box>
                </Box>
              </Grid>
              <Grid item lg={2} sm={2} xs={2}>
                {/* <Box sx={{ px: 0, justifyContent: "center" }}>
                  <Box sx={{ textAlign: "center" }}>
                    <img
                      src={Partners4}
                      alt={"Partners4"}
                      height={isBelow1024px ? 28 : 60}
                      // className="pointer-on-hover"
                    />
                  </Box>
                </Box> */}
              </Grid>
            </Grid>
          </Paper>
        </Grid>
      </Grid>
    </Box>
  );
};

export default BottomBanner;
