import React, { useState, useEffect } from "react";
import {
  Box,
  Grid,
  Typography,
  Divider,
  Button,
  TextField,
  InputAdornment,
} from "@mui/material";
import CustomLoader from "../../components/shared/customLoader/customLoader";

import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  getUSDCollectionDetails,
  resetUSDMintState,
  usdCollectionMint,
} from "../../services/redux/NftApiReducer/NftApiReducer";
import CollectionHeader from "../../components/layout/Shared/CollectionHeader/CollectionHeader";
import io from "socket.io-client";
import ShoppingBasketIcon from "@mui/icons-material/ShoppingBasket";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import { imageURL } from "../../services/api/api";
import UsdModal from "../../components/features/UsdModal/UsdModal";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import { setCollectionId } from "../../services/redux/CollectionReducer/CollectionReducer";
import {
  resetText,
  setText,
} from "../../services/redux/TextReducer/TextReducer";
import toast from "react-hot-toast";
import { TroubleshootSharp } from "@mui/icons-material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import {
  addFreeMintAPI,
  resetAddFreeMintAPI,
} from "../../services/redux/MyNftApiReducer/MyNftApiReducer";
import { address } from "../../services/api/api";
const containerStyle = {
  position: "relative",
  width: "100%",
  minHeight: "100vh",
  background:
    "linear-gradient(225deg, #731686 1%, transparent 10%, transparent 80%, #af22c8 100%)", // Linear gradient background
  backgroundSize: "cover",
  backgroundRepeat: "no-repeat",
  backgroundPosition: "center",
  paddingBottom: "20px",
};
const contentStyle = {
  position: "relative",
  top: "0px",
  width: "100%",
};
function UsdCollectionDetails() {
  const navigate = useNavigate();
  const [noOfItems, setNoOfItems] = useState(1);
  const [openModal, setOpenModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const accnumVal = localStorage.getItem("accnum");

  const { collectionDetailState, usdMintState } = useSelector(
    (state) => state.nft
  );
  const [totalprice, setTotalPrice] = useState(
    parseFloat(collectionDetailState?.data?.data?.collection?.price)
  );
  const { text } = useSelector((state) => state.text);
  const { addFreeMintData, loading: loader } = useSelector(
    (state) => state.mynft
  );

  const { token: isAuthenticated } = useSelector((state) => state.userNft);
  const params = useParams();
  const dispatch = useDispatch();
  const handleNFTDetails = (e) => {
    dispatch(
      setCollectionId(collectionDetailState?.data?.data?.collection?.id)
    );
    navigate(
      `/category-collection/${collectionDetailState?.data?.data?.collection?.vanity_name}`
    );
  };
  // const [collectionHeader, setCollectionHeader] = useState();
  //console.log("collectionUsd", collectionDetailState);

  const handleBuyModal = () => {
    // setOpenModal(true);
    if (isAuthenticated) {
      const apiBody = {
        collection_id: collectionDetailState?.data?.data?.collection?.id,
        count: noOfItems,
      };
      dispatch(setText("Waiting xaman approval ..."));

      dispatch(usdCollectionMint(apiBody));
    } else {
      toast.error("You need to login first to access NFTs");
    }
  };
  const handleCloseModal = () => {
    setOpenModal(false);
  };
  useEffect(() => {
    let accnum = localStorage.getItem("accnum");
    const apiBody = {
      collection_id: parseInt(params.id),
      userAccount: accnum,
    };
    dispatch(getUSDCollectionDetails(apiBody));
  }, [addFreeMintData?.data?.status]);

  useEffect(() => {
    if (usdMintState?.data?.status === true) {
      toast.success(`Request approved!` || usdMintState?.data?.message);
      setOpenModal(true);
      dispatch(resetText(""));
      dispatch(resetUSDMintState());
    } else if (addFreeMintData?.data?.status === true) {
      setOpenModal(true);
      dispatch(resetText(""));
      dispatch(resetAddFreeMintAPI());
    } else if (usdMintState?.data?.status === false) {
      toast.error(usdMintState?.data?.message);
      dispatch(resetText(""));
      dispatch(resetUSDMintState());
    }
  }, [usdMintState, addFreeMintData]);

  useEffect(() => {
    setLoading(collectionDetailState.loading || usdMintState.loading || loader);
  }, [collectionDetailState.loading, usdMintState.loading, loader]);

  // const connectSocket = () => {
  //   let socketio = io("", {
  //     autoConnect: false,
  //     auth: { token },
  //   });
  //   socketio.connect();

  //   socketsReq(socketio);
  // };

  // const socketsReq = (socket) => {
  //   socket.on("connect", () => {
  //     console.log("socket Connected");
  //   });

  //   socket.on("disconnect", (reason) => {
  //     console.log("socket is disconnected", reason);
  //   });

  //   // socket.on("burn/signed", (data) => {
  //   //   setMintCheck(true);
  //   //   dispatch(setLoader(true));
  //   //   console.log(" burn", data?.didUserSign);
  //   //   if (data?.didUserSign === false) {
  //   //     setMintCheck(false);
  //   //     dispatch(
  //   //       setSnackbar({
  //   //         snackbarOpen: true,
  //   //         snackbarType: "error",
  //   //         snackbarMessage: "Burn request rejected from Xumm",
  //   //       })
  //   //     );
  //   //     setOpenModal(false);
  //   //   } else {
  //   //     dispatch(
  //   //       setSnackbar({
  //   //         snackbarOpen: true,
  //   //         snackbarType: "success",
  //   //         snackbarMessage: "Burn request accepted from Xumm",
  //   //       })
  //   //     );
  //   //   }
  //   // });
  // };

  useEffect(() => {
    setTotalPrice(
      parseFloat(collectionDetailState?.data?.data?.collection?.price) *
        parseFloat(noOfItems)
    );
  }, [noOfItems, collectionDetailState]);

  async function handleFreeMint(data) {
    if (isAuthenticated) {
      let accnum = localStorage.getItem("accnum");
      dispatch(
        addFreeMintAPI({
          collection_id: data?.collection?.id,
          userAccount: accnum,
          count: 1,
        })
      );
    } else {
      toast.error("You need to login first to access NFTs");
    }
  }

  useEffect(() => {
    if (Object.keys(addFreeMintData?.data).length > 0) {
      if (addFreeMintData?.data?.status === true) {
        toast.success(addFreeMintData?.data?.message);
      } else if (addFreeMintData?.data?.status === false) {
        toast.error(addFreeMintData?.data?.message);
        dispatch(resetAddFreeMintAPI());
      }
    }
  }, [addFreeMintData?.data?.status]);

  function renderContent() {
    return (
      <Grid container spacing={1}>
        <Grid
          item
          lg={4}
          md={5}
          sm={12}
          xs={12}
          // style={{
          //   display: "flex",
          //   justifyContent: "center",
          //   alignItems: "center",
          //   flexDirection: "column",
          // }}
        >
          <Box
            sx={{
              border: "2px solid #FF9A02",
              maxWidth: "16rem",
              margin: "auto",
              minHeight: 100,
              // maxHeight: 345,
              // minWidth: 100,
              // maxWidth: 325,
              p: 0.5,
            }}
          >
            <img
              style={{
                width: "100%",
                height: "100%",
                padding: 0,
                cursor: "pointer",
                objectFit: "contain",
              }}
              src={`${imageURL}${collectionDetailState?.data?.data?.collection?.thumbnail}`}
              //   title="green iguana"
            />
          </Box>
        </Grid>
        <Grid
          item
          lg={8}
          md={7}
          sm={12}
          xs={12}
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: { xs: "center", sm: "center", md: "start" },
          }}
        >
          <Box>
            <Typography
              variant="body1"
              sx={{
                fontSize: 14,
                color: "white",
                textAlign: "center",
                fontFamily: "goodTime",
              }}
            >
              Description:{" "}
              <span style={{ color: "#FF9A02" }}>
                {collectionDetailState?.data?.data?.collection?.description
                  ? collectionDetailState?.data?.data?.collection?.description
                      ?.length > 50
                    ? `${collectionDetailState?.data?.data?.collection?.description?.slice(
                        0,
                        50
                      )}...`
                    : collectionDetailState?.data?.data?.collection?.description
                  : "N/A"}
              </span>
            </Typography>
          </Box>
          <Box sx={{ m: { xs: 1, sm: 2 } }}>
            <Typography
              variant="body1"
              sx={{
                fontSize: 14,
                color: "white",
                textAlign: "center",
                fontFamily: "goodTime",
                px: 4,
              }}
            >
              "Random Mint"{" "}
              <span style={{ color: "#FF9A02" }}>
                Make sure you have XRP equivalent to{" "}
                {collectionDetailState?.data?.data?.collection?.price} USD in
                your wallet to purchase an NFT from this collection.
              </span>
            </Typography>
          </Box>
          <Box
            sx={{
              display: { sm: "flex" },
              justifyContent: "space-evenly",
              mt: 2,
            }}
          >
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                flexDirection: "column",
                alignItems: "center",
                // width: 400,
              }}
            >
              {accnumVal != address &&
                (collectionDetailState?.data?.data?.collection_info
                  ?.free_mint === 0 ||
                  !collectionDetailState?.data?.data?.collection_info
                    ?.free_mint) && (
                  <>
                    <Box
                      sx={
                        {
                          // display: "flex",
                          // justifyContent: "center",
                          // flexDirection: "column",
                          // alignItems: "center",
                        }
                      }
                    >
                      <Typography
                        variant="body1"
                        sx={{
                          fontSize: 14,
                          color: "white",
                          // textAlign: "center",
                          fontFamily: "goodTime",
                        }}
                      >
                        Select No. of NFTs
                      </Typography>

                      <Box
                        //onClick={handleBuyModal}

                        sx={{
                          backgroundColor: "#AF22C8",
                          fontFamily: "goodTime", // Same as above
                          color: "white",
                          "&:hover": {
                            backgroundColor: "#AF22C8",
                          },

                          borderRadius: "20px",
                          textTransform: "none",
                          display: "flex",
                          justifyContent: "space-between",
                          width: "200px",
                          mt: 0.5,
                          px: 3,
                          py: 0.7,
                        }}
                      >
                        <RemoveIcon
                          sx={{
                            cursor: "pointer",
                          }}
                          onClick={() => {
                            if (noOfItems > 1) setNoOfItems(noOfItems - 1);
                          }}
                        />
                        {noOfItems}
                        <AddIcon
                          sx={{
                            cursor: "pointer",
                          }}
                          onClick={() => {
                            if (noOfItems < 5) setNoOfItems(noOfItems + 1);
                          }}
                        />
                      </Box>
                    </Box>
                    <Box>
                      <Button
                        disabled={
                          collectionDetailState?.data?.data?.collection_info
                            ?.remainingItems === 0 ||
                          noOfItems >
                            collectionDetailState?.data?.data?.collection_info
                              ?.remainingItems
                        }
                        variant="contained"
                        startIcon={<ShoppingBasketIcon />}
                        onClick={handleBuyModal}
                        sx={{
                          width: { xs: "fit-content", sm: "100%" },
                          backgroundColor: "#AF22C8",
                          fontFamily: "goodTime", // Same as above
                          color: "white",
                          "&:hover": {
                            backgroundColor: "#AF22C8",
                          },
                          "&:disabled": {
                            backgroundColor: "#AF22C8",
                          },
                          borderRadius: "20px",
                          textTransform: "none",
                          mt: { xs: 1, sm: 2 },
                        }}
                      >
                        Buy Random NFT for ${totalprice} usd
                      </Button>
                    </Box>
                  </>
                )}
              {accnumVal != address && (
                <Box>
                  {collectionDetailState?.data?.data?.collection_info
                    ?.free_mint > 0 &&
                    collectionDetailState?.data?.data?.collection_info
                      ?.free_mint && (
                      <>
                        <Button
                          disabled={
                            collectionDetailState?.data?.data?.collection_info
                              ?.free_mint === 0 ||
                            !collectionDetailState?.data?.data?.collection_info
                              ?.free_mint ||
                            collectionDetailState?.data?.data?.collection_info
                              ?.remainingItems === 0 ||
                            !collectionDetailState?.data?.data?.collection_info
                              ?.remainingItems
                          }
                          variant="contained"
                          startIcon={<ShoppingBasketIcon />}
                          onClick={() => {
                            handleFreeMint(collectionDetailState?.data?.data);
                          }}
                          sx={{
                            width: { xs: "fit-content", sm: "100%" },
                            backgroundColor: "#AF22C8",
                            fontFamily: "goodTime", // Same as above
                            color: "white",
                            "&:hover": {
                              backgroundColor: "#AF22C8",
                            },
                            "&:disabled": {
                              backgroundColor: "#AF22C8",
                            },
                            borderRadius: "20px",
                            textTransform: "none",
                          }}
                        >
                          Free Mint
                        </Button>
                        <Typography
                          sx={{
                            pt: 0.4,
                            fontFamily: "goodTime",
                            fontSize: "11px",
                            color: "#FF9A02",
                          }}
                        >
                          You have{" "}
                          <span style={{ fontWeight: "bolder" }}>
                            (
                            {
                              collectionDetailState?.data?.data?.collection_info
                                ?.free_mint
                            }
                            )
                          </span>{" "}
                          free mints available
                        </Typography>
                      </>
                    )}
                </Box>
              )}
            </Box>
          </Box>
        </Grid>
      </Grid>
    );
  }

  return (
    <div style={containerStyle}>
      <div style={contentStyle}>
        <Box>
          <Grid container spacing={2}>
            <Grid item xs={2}>
              {" "}
              <Box
                sx={{ display: "flex", justifyContent: "start", pt: 3, ml: 1 }}
              >
                <Button
                  size="large"
                  sx={{ color: "white", pl: 1, py: 0 }}
                  onClick={() => navigate(-1)}
                >
                  <ArrowBackIcon fontSize="medium" sx={{ color: "white" }} />
                </Button>
              </Box>
            </Grid>

            <Grid item xs={8}>
              <>
                {/* Desktop view */}
                <Box>
                  <CollectionHeader
                    Data={collectionDetailState?.data?.data}
                    loading={collectionDetailState.loading}
                  />
                </Box>
              </>
            </Grid>

            <Grid item xs={2}></Grid>
          </Grid>
          <Divider
            variant="middle"
            sx={{ mt: 0, backgroundColor: "#AF22C8", mx: 0, mb: 0 }}
          />
          <Box sx={{ p: 1, pb: 4, display: "flex", justifyContent: "center" }}>
            <Button
              sx={{ color: "#FF9A02" }}
              startIcon={<ArrowForwardIcon />}
              onClick={handleNFTDetails}
            >
              {" "}
              view Collection Item{" "}
            </Button>
          </Box>
          {/* display: "flex", justifyContent: "center" */}
          {/* ml: { xs: 2, sm: 4, md: 6, lg: 8 }, */}
          <Box sx={{ p: 1, pb: 4 }}>
            {!loading ? (
              <Box sx={{ paddingBottom: 6 }}>{renderContent()}</Box>
            ) : (
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  minHeight: "80vh", // Set minimum height to fill the entire viewport
                }}
              >
                <CustomLoader
                  width={"60%"}
                  text={text === "" || !text ? "Please wait ..." : text}
                />{" "}
              </Box>
            )}
          </Box>
        </Box>
        {openModal && (
          <UsdModal
            openModal={openModal}
            loading={false}
            onClose={handleCloseModal}
            noOfItems={noOfItems}
          />
        )}
      </div>
    </div>
  );
}
// openModal, setOpenModal, imageUrl, nft, offersData;

export default UsdCollectionDetails;
