import React from "react";
import { Box, Skeleton, Grid, Typography } from "@mui/material";
import TestImage from "../../../../assets/MainCarousel/HardcoreLions.png";
import { imageURL } from "../../../../services/api/api";
import { useSelector } from "react-redux";
import defaultImage from "../../../../assets/Default/default.png";

const CollectionHeader = ({ Data, loading }) => {
  // console.log(Data, "datatata");
  return (
    <Box sx={{ pt: 2, pb: 2 }}>
      <Grid container>
        <Grid
          item
          xs={12}
          sm={4}
          sx={{ display: "flex", justifyContent: "center" }}
        >
          <Box sx={{ p: 1 }}>
            {loading ? (
              <Skeleton variant="rectangular" width={150} height={150} />
            ) : (
              <img
                style={{ width: "100%", height: "110px", objectFit: "contain" }}
                src={
                  Data?.collection?.thumbnail
                    ? `${imageURL}${Data.collection.thumbnail}`
                    : defaultImage
                }
                // height={150}
                alt="image"
              />
            )}
          </Box>
        </Grid>
        <Grid item xs={12} sm={8}>
          <Box sx={{ py: 2 }}>
            <Typography
              variant="h5"
              sx={{
                fontWeight: 600,
                color: "white",
                textAlign: "center",
                fontFamily: "goodTime",
                mt: 1,
              }}
            >
              {loading ? (
                <Box
                  display="flex"
                  flexDirection="row"
                  justifyContent="center"
                  alignItems="center"
                >
                  <Skeleton variant="text" width={200} />
                </Box>
              ) : (
                <>{Data?.collection?.name}</>
              )}
            </Typography>
          </Box>

          <Grid xs={12}>
            <Grid container spacing={2}>
              <Grid item xs={6} md={3}>
                <Typography
                  variant="body1"
                  sx={{
                    fontSize: 12,
                    color: "white",
                    textAlign: "center",
                    fontFamily: "goodTime",
                  }}
                >
                  Floor Price
                </Typography>
                <Typography
                  variant="body1"
                  sx={{
                    fontSize: 12,
                    color: "white",
                    textAlign: "center",
                    fontFamily: "goodTime",
                  }}
                >
                  {loading ? (
                    <Box
                      display="flex"
                      flexDirection="row"
                      justifyContent="center"
                      alignItems="center"
                    >
                      <Skeleton variant="text" width={50} height={50} />
                    </Box>
                  ) : (
                    <span style={{ color: "#FF9A02" }}>
                      {Data?.collection_info?.floor_price &&
                      Data?.collection_info?.floor_price >= 0
                        ? Data?.collection_info?.floor_price
                        : "N/A"}
                    </span>
                  )}
                </Typography>
              </Grid>
              <Grid item xs={6} md={3}>
                <Typography
                  variant="body1"
                  sx={{
                    fontSize: 12,
                    color: "white",
                    textAlign: "center",
                    fontFamily: "goodTime",
                  }}
                >
                  {Data?.collection_info?.totalkeys >= 0 &&
                  Data?.collection_info?.remainingItems >= 0
                    ? "Remaining Items"
                    : "Item"}
                </Typography>
                <Typography
                  variant="body1"
                  sx={{
                    fontSize: 12,
                    color: "white",
                    textAlign: "center",
                    fontFamily: "goodTime",
                  }}
                >
                  {loading ? (
                    <Box
                      display="flex"
                      flexDirection="row"
                      justifyContent="center"
                      alignItems="center"
                    >
                      <Skeleton variant="text" width={50} height={50} />
                    </Box>
                  ) : (
                    <span style={{ color: "#FF9A02" }}>
                      {Data?.collection_info?.totalkeys >= 0 &&
                      Data?.collection_info?.remainingItems >= 0
                        ? Data?.collection_info?.remainingItems
                        : Data?.collection_info?.total_nfts >= 0
                        ? Data?.collection_info?.total_nfts
                        : "N/A"}
                    </span>
                  )}
                </Typography>
              </Grid>

              <Grid item xs={6} md={3}>
                <Typography
                  variant="body1"
                  sx={{
                    fontSize: 12,
                    color: "white",
                    textAlign: "center",
                    fontFamily: "goodTime",
                  }}
                >
                  For Sale
                </Typography>
                <Typography
                  variant="body1"
                  sx={{
                    fontSize: 12,
                    color: "white",
                    textAlign: "center",
                    fontFamily: "goodTime",
                  }}
                >
                  {loading ? (
                    <Box
                      display="flex"
                      flexDirection="row"
                      justifyContent="center"
                      alignItems="center"
                    >
                      <Skeleton variant="text" width={50} height={50} />
                    </Box>
                  ) : (
                    <span style={{ color: "#FF9A02" }}>
                      {Data?.collection_info?.total_nfts_for_sale >= 0
                        ? Data?.collection_info?.total_nfts_for_sale
                        : Data?.collection_info?.for_sale >= 0
                        ? Data?.collection_info?.for_sale
                        : "N/A"}
                    </span>
                  )}
                </Typography>
              </Grid>

              <Grid item xs={6} md={3}>
                <Typography
                  variant="body1"
                  sx={{
                    fontSize: 12,
                    color: "white",
                    textAlign: "center",
                    fontFamily: "goodTime",
                  }}
                >
                  Volume
                </Typography>
                <Typography
                  variant="body1"
                  sx={{
                    fontSize: 12,
                    color: "white",
                    textAlign: "center",
                    fontFamily: "goodTime",
                  }}
                >
                  {" "}
                  {loading ? (
                    <Box
                      display="flex"
                      flexDirection="row"
                      justifyContent="center"
                      alignItems="center"
                      sx={{ wordWrap: "anywhere !important" }}
                    >
                      <Skeleton variant="text" width={50} height={50} />
                    </Box>
                  ) : (
                    <span style={{ color: "#FF9A02" }}>
                      {Data?.collection_info?.volume &&
                      Data?.collection_info?.volume >= 0
                        ? Data?.collection_info?.volume.toFixed(2)
                        : "N/A"}
                    </span>
                  )}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
};

export default CollectionHeader;
