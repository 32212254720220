import React, { useEffect, useState } from "react";
import {
  Box,
  Grid,
  Typography,
  Container,
  FormControl,
  FormLabel,
  FormGroup,
  FormHelperText,
} from "@mui/material";
import MediaCard from "../../features/CategorryCard/Card";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  getCategories,
  setCategoryId,
} from "../../../services/redux/LandingPageApiReducer/LandingPageApiReducer";
import CustomLoader from "../../shared/customLoader/customLoader";

const Category = () => {
  const txtcolor = "#f1f1f1";
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { categoriesData } = useSelector((state) => state.landingPage);

  const [categoryData, setCollectionData] = useState(
    categoriesData?.data?.data?.categories || []
  );
  const [orderList, setOrderList] = useState(
    categoriesData?.data?.data?.order?.sorted_ids
  );

  const orderedRows = categoriesData?.data?.data?.order?.sorted_ids
    ?.map((orderId) =>
      categoriesData?.data?.data?.categories?.find((row) => row?.id === orderId)
    )
    ?.filter((item) => item !== undefined);

  useEffect(() => {
    dispatch(getCategories());
  }, []);

  const handleData = (e) => {
    dispatch(setCategoryId(e.id));
    let name = e?.name?.replace(" ", "");
    console.log(name, "name");
    navigate(`/category/${e.name}`);
  };

  return (
    <Box>
      <Container maxWidth={false}>
        <Grid container spacing={4}></Grid>
      </Container>

      <Box sx={{ display: "flex", justifyContent: "center", pt: 3 }}>
        <Typography
          variant="h5"
          sx={{
            color: txtcolor,
            fontWeight: 600,
            fontSize: 28,
            fontFamily: "goodTime",
            justifyContent: "center",
          }}
        >
          Categories
        </Typography>
      </Box>

      <Grid container spacing={2}>
        <Grid item lg={2}>
          {" "}
          <Box
            sx={{ display: "flex", justifyContent: "start", pt: 3, ml: 6 }}
          ></Box>
        </Grid>
        <Grid item lg={8}></Grid>
        <Grid item lg={2}>
          <Box sx={{ display: "flex", justifyContent: "center", pt: 4 }}>
            <Box sx={{ pt: 4, pr: 2 }}></Box>
          </Box>
        </Grid>
      </Grid>

      {categoriesData?.loading ? (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            minHeight: "80vh", // Set minimum height to fill the entire viewport
          }}
        >
          <CustomLoader width={"60%"} text={"Please wait ..."} />{" "}
        </Box>
      ) : (
        <Grid
          container
          // spacing={4}
          sx={{
            // px: "10%",

            maxWidth: "1200px",
            mx: "auto",
          }}
        >
          {categoryData &&
            orderedRows?.map((items, index) => (
              <Grid
                item
                lg={4}
                sm={12}
                xs={12}
                sx={{ display: "flex", justifyContent: "center" }}
                key={index}
              >
                <MediaCard data={items} onConfirm={handleData} />
              </Grid>
            ))}
        </Grid>
      )}
    </Box>
  );
};

export default Category;
