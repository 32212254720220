import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

import { axiosRequestAuth, axiosRequest } from "../../api/api";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";

////////////////////////////////////////////////////
export const getFreeMintAPI = createAsyncThunk(
  "admin/getFreeMintAPI",
  async (dataDetails, { rejectWithValue }) => {
    try {
      // Assuming saleDetails contains 'user_account' and 'offset'
      const response = await axiosRequestAuth("get", "v2/free-mint/user");
      // Check if the response is successful
      // (This depends on how your axiosRequestAuth is structured)

      //console.log("API recentlyForSaleNft", response);
      if (response.status !== 200) {
        throw new Error("API request failed");
      }
      return response.data; // Assuming the response data is the payload you want
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);
export const addFreeMintAPI = createAsyncThunk(
  "admin/addFreeMintAPI",
  async (data, { rejectWithValue }) => {
    try {
      // Assuming saleDetails contains 'user_account' and 'offset'
      const response = await axiosRequestAuth(
        "post",
        "v2/free-mint/mint",
        data
      );
      // Check if the response is successful
      // (This depends on how your axiosRequestAuth is structured)

      //console.log("API recentlyForSaleNft", response);
      if (response.status !== 200) {
        throw new Error("API request failed");
      }
      return response.data; // Assuming the response data is the payload you want
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const syncNftAPI = createAsyncThunk(
  "admin/syncNftAPI",
  async (data, { rejectWithValue }) => {
    console.log(data, "data");
    try {
      // Assuming saleDetails contains 'user_account' and 'offset'
      const response = await axiosRequestAuth("post", "v2/nft/save", data);
      // Check if the response is successful
      // (This depends on how your axiosRequestAuth is structured)

      //console.log("API recentlyForSaleNft", response);
      if (response.status !== 200) {
        throw new Error("API request failed");
      }
      return response.data; // Assuming the response data is the payload you want
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const addFreeGameMintAPI = createAsyncThunk(
  "admin/addFreeGameMintAPI",
  async (data, { rejectWithValue }) => {
    try {
      // Assuming saleDetails contains 'user_account' and 'offset'
      const response = await axiosRequestAuth(
        "post",
        "v2/free-mint/game",
        data
      );
      // Check if the response is successful
      // (This depends on how your axiosRequestAuth is structured)

      //console.log("API recentlyForSaleNft", response);
      if (response.status !== 200) {
        throw new Error("API request failed");
      }
      return response.data; // Assuming the response data is the payload you want
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);
export const getWalletAddressesSearchAPI = createAsyncThunk(
  "admin/getWalletAddressesSearchAPI",
  async (dataDetails, { rejectWithValue }) => {
    try {
      // Assuming saleDetails contains 'user_account' and 'offset'
      const response = await axiosRequestAuth(
        "get",
        `v2/free-mint/${dataDetails.search}/${dataDetails.offset}/${dataDetails.limit}`
      );
      // Check if the response is successful
      // (This depends on how your axiosRequestAuth is structured)

      //console.log("API recentlyForSaleNft", response);
      if (response.status !== 200) {
        throw new Error("API request failed");
      }
      return response.data; // Assuming the response data is the payload you want
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);
export const getWalletAddressesAPI = createAsyncThunk(
  "admin/getWalletAddressesAPI",
  async (dataDetails, { rejectWithValue }) => {
    try {
      // Assuming saleDetails contains 'user_account' and 'offset'
      const response = await axiosRequestAuth(
        "get",
        `v2/free-mint/all/${dataDetails.offset}/${dataDetails.limit}`
      );
      // Check if the response is successful
      // (This depends on how your axiosRequestAuth is structured)

      //console.log("API recentlyForSaleNft", response);
      if (response.status !== 200) {
        throw new Error("API request failed");
      }
      return response.data; // Assuming the response data is the payload you want
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);
export const getWalletAddressesByOrderAPI = createAsyncThunk(
  "admin/getWalletAddressesByOrderAPI",
  async (data, { rejectWithValue }) => {
    try {
      // Assuming saleDetails contains 'user_account' and 'offset'
      const response = await axiosRequestAuth(
        "post",
        "v2/free-mint/order-details",
        data
      );
      // Check if the response is successful
      // (This depends on how your axiosRequestAuth is structured)

      //console.log("API recentlyForSaleNft", response);
      if (response.status !== 200) {
        throw new Error("API request failed");
      }
      return response.data; // Assuming the response data is the payload you want
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);
export const addWalletAddressesAPI = createAsyncThunk(
  "admin/addWalletAddressesAPI",
  async (data, { rejectWithValue }) => {
    try {
      const response = await axiosRequestAuth("post", "v2/free-mint", data);

      //console.log("API recentlyForSaleNft", response);
      if (response.status !== 200) {
        throw new Error("API request failed");
      }
      return response.data; // Assuming the response data is the payload you want
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);
export const addMintsAPI = createAsyncThunk(
  "admin/addMintsAPI",
  async (data, { rejectWithValue }) => {
    try {
      const response = await axiosRequestAuth(
        "post",
        "v2/free-mint/add-with-out-order",
        data
      );

      //console.log("API recentlyForSaleNft", response);
      if (response.status !== 200) {
        throw new Error("API request failed");
      }
      return response.data; // Assuming the response data is the payload you want
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);
export const getFreeMintAllUsersAPI = createAsyncThunk(
  "user/getFreeMintAllUsersAPI",
  async (dataDetails, { rejectWithValue }) => {
    try {
      // Assuming saleDetails contains 'user_account' and 'offset'
      const response = await axiosRequestAuth("get", "v2/free-mint/history");
      // Check if the response is successful
      // (This depends on how your axiosRequestAuth is structured)

      //console.log("API recentlyForSaleNft", response);
      if (response.status !== 200) {
        throw new Error("API request failed");
      }
      return response.data; // Assuming the response data is the payload you want
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);
///////////////////////////////////////////////////////////

// Async thunk for "TRansfer"
export const freeTransferNFT = createAsyncThunk(
  "nft/transferNFT",
  async (userData, { rejectWithValue }) => {
    try {
      // Assuming saleDetails contains 'user_account' and 'offset'
      const response = await axiosRequestAuth("post", "v2/transfer", userData);
      // Check if the response is successful
      // (This depends on how your axiosRequestAuth is structured)

      //console.log("API getListedNft", response);
      if (response.status !== 200) {
        throw new Error("API request failed");
      }
      return response.data; // Assuming the response data is the payload you want
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);
// Async thunk for "Buy NFT"
export const buyNFT = createAsyncThunk(
  "nft/buyNFT",
  async (nftDetail, { rejectWithValue }) => {
    try {
      // Assuming saleDetails contains 'user_account' and 'offset'
      const response = await axiosRequestAuth("post", "v2/buy/nft", nftDetail);
      // Check if the response is successful
      // (This depends on how your axiosRequestAuth is structured)

      //console.log("API getListedNft", response);
      if (response.status !== 200) {
        throw new Error("API request failed");
      }
      return response.data; // Assuming the response data is the payload you want
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

// Async thunk for "Buy NFT"
export const burnNFT = createAsyncThunk(
  "nft/burn",
  async (nftDetail, { rejectWithValue }) => {
    try {
      // Assuming saleDetails contains 'user_account' and 'offset'
      const response = await axiosRequestAuth("post", "v2/nft/burn", nftDetail);
      // Check if the response is successful
      // (This depends on how your axiosRequestAuth is structured)

      if (response.status !== 200) {
        throw new Error("API request failed");
      }
      return response.data; // Assuming the response data is the payload you want
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

// Async thunk for "Buy NFT"
export const buyDirectNFT = createAsyncThunk(
  "nft/buyDirectNFT",
  async (nftDetail, { rejectWithValue }) => {
    try {
      // Assuming saleDetails contains 'user_account' and 'offset'
      const response = await axiosRequestAuth(
        "post",
        "v2/buy/buy-nft",
        nftDetail
      );
      // Check if the response is successful
      // (This depends on how your axiosRequestAuth is structured)

      //console.log("API getListedNft", response);
      if (response.status !== 200) {
        throw new Error("API request failed");
      }
      return response.data; // Assuming the response data is the payload you want
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);
// Async thunk for "listed NFT"
export const getListedNft = createAsyncThunk(
  "nft/listedNft",
  async (price, { rejectWithValue }) => {
    try {
      // Assuming saleDetails contains 'user_account' and 'offset'
      const response = await axiosRequestAuth("get", "v2/user/nfts-for-sell");
      // Check if the response is successful
      // (This depends on how your axiosRequestAuth is structured)

      //console.log("API getListedNft", response);
      if (response.status !== 200) {
        throw new Error("API request failed");
      }
      return response.data; // Assuming the response data is the payload you want
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);
// Async thunk for "My offer on  NFT"

export const getMyOffersNft = createAsyncThunk(
  "nft/offersNft",
  async (price, { rejectWithValue }) => {
    try {
      // Assuming saleDetails contains 'user_account' and 'offset'
      const response = await axiosRequestAuth(
        "get",
        "v2/user/nfts-with-my-buy-offers"
      );
      // Check if the response is successful
      // (This depends on how your axiosRequestAuth is structured)

      //console.log("API called listed", response);
      if (response.status !== 200) {
        throw new Error("API request failed");
      }
      return response.data; // Assuming the response data is the payload you want
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);
// Async thunk for "Get User Collections"

export const getUserCollection = createAsyncThunk(
  "nft/getUserCollection",
  async (body, { rejectWithValue }) => {
    try {
      // Assuming saleDetails contains 'user_account' and 'offset'
      const response = await axiosRequestAuth(
        "get",
        "v2/user/collections",
        body
      );
      // Check if the response is successful
      // (This depends on how your axiosRequestAuth is structured)

      //console.log("API getUserCollection", response);
      if (response.status !== 200) {
        throw new Error("API request failed");
      }
      return response.data; // Assuming the response data is the payload you want
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);
// Async thunk for "Get NFT of a Collection"
export const getUserCollectionNFT = createAsyncThunk(
  "nft/getUserCollectionNFT",
  async (body, { rejectWithValue }) => {
    try {
      // Assuming saleDetails contains 'user_account' and 'offset'
      const response = await axiosRequestAuth(
        "post",
        "v2/user/collection-nfts",
        body
      );
      // Check if the response is successful
      // (This depends on how your axiosRequestAuth is structured)

      //console.log("API getUserCollectionNFT", response);
      if (response.status !== 200) {
        throw new Error("API request failed");
      }

      return response.data; // Assuming the response data is the payload you want
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const getUSDCollectionNFT = createAsyncThunk(
  "nft/getUSDCollectionNFT",
  async (body, { rejectWithValue }) => {
    try {
      // Assuming saleDetails contains 'user_account' and 'offset'
      const response = await axiosRequestAuth(
        "post",
        "v2/collection/usd-nfts",
        body
      );
      // Check if the response is successful
      // (This depends on how your axiosRequestAuth is structured)

      //console.log("API getUserCollectionNFT", response);
      if (response.status !== 200) {
        throw new Error("API request failed");
      }

      return response.data; // Assuming the response data is the payload you want
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);
export const saleNFT = createAsyncThunk(
  "nft/sellNFT",
  async (nftData, { rejectWithValue }) => {
    try {
      // Assuming saleDetails contains 'user_account' and 'offset'
      const response = await axiosRequestAuth("post", "v2/sell/nft", nftData);
      // Check if the response is successful
      // (This depends on how your axiosRequestAuth is structured)

      //console.log("API saleNFT", response);
      if (response.status !== 200) {
        throw new Error("API request failed");
      }
      return response.data; // Assuming the response data is the payload you want
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const getNftWaitingMyApproval = createAsyncThunk(
  "nft/nftWaitingMyApproval",

  async (nftData, { rejectWithValue }) => {
    try {
      // Assuming saleDetails contains 'user_account' and 'offset'
      const response = await axiosRequestAuth(
        "get",
        "v2/user/my-nfts-with-offers"
      );
      // Check if the response is successful
      // (This depends on how your axiosRequestAuth is structured)

      //console.log("API getNftWaitingMyApproval", response);
      if (response.status !== 200) {
        throw new Error("API request failed");
      }
      return response.data; // Assuming the response data is the payload you want
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const getNftDetail = createAsyncThunk(
  "nft/getNftDetail",
  async (nftData, { rejectWithValue }) => {
    try {
      // Assuming saleDetails contains 'user_account' and 'offset'
      const response = await axiosRequestAuth(
        "post",
        "v2/nft/details",
        nftData
      );
      // Check if the response is successful
      // (This depends on how your axiosRequestAuth is structured)

      //console.log("API getNftDetail", response);
      if (response.status !== 200) {
        throw new Error("API request failed");
      }
      return response.data; // Assuming the response data is the payload you want
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const approveOffer = createAsyncThunk(
  "nft/approveOffer",
  async (combinedData, { rejectWithValue }) => {
    try {
      // Assuming saleDetails contains 'user_account' and 'offset'
      const response = await axiosRequestAuth(
        "post",
        "v2/sell/nft/accept-offer",
        combinedData
      );
      // Check if the response is successful
      // (This depends on how your axiosRequestAuth is structured)

      //console.log("API recentlyForSaleNft", response);
      if (response.status !== 200) {
        throw new Error("API request failed");
      }
      return response.data; // Assuming the response data is the payload you want
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const accpetTransfer = createAsyncThunk(
  "nft/accpetTransfer",
  async (apiData, { rejectWithValue }) => {
    try {
      const response = await axiosRequestAuth(
        "post",
        "v2/transfer/accept",
        apiData
      );

      //console.log("API accpetTransfer", response);
      if (response.status !== 200) {
        throw new Error("API request failed");
      }
      return response.data; // Assuming the response data is the payload you want
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const getTransferOffer = createAsyncThunk(
  "nft/transferOffer",
  async (combinedData, { rejectWithValue }) => {
    try {
      // Assuming saleDetails contains 'user_account' and 'offset'
      const response = await axiosRequestAuth(
        "get",
        "v2/user/transfer-offers",
        combinedData
      );
      // Check if the response is successful
      // (This depends on how your axiosRequestAuth is structured)

      //console.log("API transferOffer", response);
      if (response.status !== 200) {
        throw new Error("API request failed");
      }
      return response.data; // Assuming the response data is the payload you want
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);
export const getLikedNfts = createAsyncThunk(
  "nft/getLikedNfts",
  async (combinedData, { rejectWithValue }) => {
    try {
      // Assuming saleDetails contains 'user_account' and 'offset'
      const response = await axiosRequestAuth(
        "post",
        "v2/like/favorate",
        combinedData
      );
      // Check if the response is successful
      // (This depends on how your axiosRequestAuth is structured)

      //console.log("API transferOffer", response);
      if (response.status !== 200) {
        throw new Error("API request failed");
      }
      return response.data; // Assuming the response data is the payload you want
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

// Async thunk for "Sync status"
export const syncStatusAPI = createAsyncThunk(
  "nft/syncStatusAPI",
  async (body, { rejectWithValue }) => {
    try {
      // Assuming saleDetails contains 'user_account' and 'offset'
      const response = await axiosRequestAuth("get", "v2/user/sync-status");
      // Check if the response is successful
      // (This depends on how your axiosRequestAuth is structured)

      //console.log("API syncStatus", response);
      if (response.status !== 200) {
        throw new Error("API request failed");
      }

      return response.data; // Assuming the response data is the payload you want
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const syncNFTAPI = createAsyncThunk(
  "nft/syncNFTAPI",
  async (body, { rejectWithValue }) => {
    try {
      // Assuming saleDetails contains 'user_account' and 'offset'
      const response = await axiosRequestAuth("get", "v2/user/sync-nfts");
      // Check if the response is successful
      // (This depends on how your axiosRequestAuth is structured)

      //console.log("API syncNFTAPI", response);
      if (response.status !== 200) {
        throw new Error("API request failed");
      }

      return response.data; // Assuming the response data is the payload you want
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

// Async thunk for "Get NFT of a Collection"
export const sellOfferCancel = createAsyncThunk(
  "nft/sellOfferCancel",
  async (body, { rejectWithValue }) => {
    try {
      // Assuming saleDetails contains 'user_account' and 'offset'
      const response = await axiosRequestAuth("post", "v2/offer/cancel", body);
      // Check if the response is successful
      // (This depends on how your axiosRequestAuth is structured)

      //console.log("API sellOfferCancel", response);
      if (response.status !== 200) {
        throw new Error("API request failed");
      }

      return response.data; // Assuming the response data is the payload you want
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

const MyNftSlice = createSlice({
  name: "mynft",
  initialState: {
    approveOfferNft: { data: [], loading: false, error: null },
    UserCollectionNFT: { data: [], loading: false, error: null },
    USDCollectionNFT: { data: [], loading: false, error: null },
    data: [], // Example state
    sellNFT: { data: [], loading: false, error: null },
    collection: [],
    listedNft: [],
    transferList: [],
    myOffersNft: [],
    nftDetails: [],
    nftSync: {},
    waitingApproval: [],
    res: "",
    forSaleNft: [],
    transferNft: { data: [], loading: false, error: null },
    getTransferOffer: { data: [], loading: false, error: null },
    getLikedNfts: { data: [], loading: false, error: null },
    syncStatus: { data: [], loading: false, error: null },

    buyNFTState: { data: [], loading: false, error: null },
    burnNFTState: { data: [], loading: false, error: null, success: false },
    buyDirectNFTState: { data: [], loading: false, error: null },
    approvRes: "",
    loading: false,
    loadingNft: false,
    nfts: [],
    acceptTransferRes: { data: [], loading: false, error: null },
    sellOfferCancelSatate: { data: "", loading: false, error: null },
    error: null,

    getFreeMintData: { data: {}, loading: false, error: null },
    addFreeMintData: { data: {}, loading: false, error: null },
    getFreeMintAllUsersData: { data: {}, loading: false, error: null },
    getWalletAddressesSearchData: { data: {}, loading: false, error: null },
    getWalletAddressesData: { data: {}, loading: false, error: null },
    getWalletAddressesByOrderData: { data: {}, loading: false, error: null },
    addWalletAddressesData: { data: {}, loading: false, error: null },
    addMintsData: { data: {}, loading: false, error: null },

    syncNftId: { data: {}, loading: false, error: null },
  },
  reducers: {
    resetAcceptTransferRes: (state) => {
      state.acceptTransferRes.data = [];
    },
    resetCollectionNft: (state) => {
      state.UserCollectionNFT.data = "";
    },
    resetSellState: (state) => {
      state.sellNFT.data = [];
    },
    resetTransferState: (state) => {
      state.transferNft.data = [];
    },
    resetApproveState: (state) => {
      state.approvRes = "";
    },
    resetTransferNftState: (state) => {
      state.transferNft.data = [];
    },
    resetsellOfferCancelState: (state) => {
      state.sellOfferCancelSatate.data = [];
    },
    resetBuyOfferState: (state) => {
      state.buyNFTState.data = [];
    },
    resetBurnState: (state) => {
      state.burnNFT.data = [];
      state.burnNFTState.success = false;
    },
    resetBuyDirectNftState: (state) => {
      state.buyDirectNFTState.data = [];
    },
    resetapproveOfferNft: (state) => {
      state.approveOfferNft.data = [];
    },
    resetUSDCollectionState: (state) => {
      state.USDCollectionNFT.data = [];
    },
    resetGetFreeMintAPI: (state) => {
      state.getFreeMintData = { data: {}, loading: false, error: null };
    },
    resetAddFreeMintAPI: (state) => {
      state.addFreeMintData = { data: {}, loading: false, error: null };
    },
    resetGetFreeMintAllUsersAPI: (state) => {
      state.getFreeMintAllUsersData = { data: {}, loading: false, error: null };
    },
    resetGetWalletAddressesAPI: (state) => {
      state.getWalletAddressesData = { data: {}, loading: false, error: null };
    },
    resetGetWalletAddressesSearchAPI: (state) => {
      state.getWalletAddressesSearchData = {
        data: {},
        loading: false,
        error: null,
      };
    },
    resetGetWalletAddressesByOrderAPI: (state) => {
      state.getWalletAddressesByOrderData = {
        data: {},
        loading: false,
        error: null,
      };
    },
    resetAddWalletAddressesAPI: (state) => {
      state.addWalletAddressesData = { data: {}, loading: false, error: null };
    },
    resetAddMintsAPI: (state) => {
      state.addMintsData = { data: {}, loading: false, error: null };
    },
    resetSyncNftId: (state) => {
      state.syncNftId = { data: {}, loading: false, error: null };
    },
  },
  extraReducers: {
    //get NFT
    [getUserCollectionNFT.pending]: (state) => {
      state.loading = true;
      state.UserCollectionNFT.data = [];
    },
    [getUserCollectionNFT.fulfilled]: (state, action) => {
      state.loading = false;
      state.UserCollectionNFT.data = action.payload;
      // Update state based on the successful win
      // ...
    },
    [getUserCollectionNFT.rejected]: (state, action) => {
      state.loading = false;
      state.UserCollectionNFT.data = [];
      state.error = action.payload;
    },

    //buy NFT
    [buyNFT.pending]: (state) => {
      state.loading = true;
    },
    [buyNFT.fulfilled]: (state, action) => {
      state.loading = false;
      state.buyNFTState.data = action.payload;
      // Update state based on the successful win
      // ...
    },
    [buyNFT.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },

    [burnNFT.pending]: (state) => {
      state.loading = true;
      state.burnNFTState.success = false;
    },
    [burnNFT.fulfilled]: (state, action) => {
      state.loading = false;

      state.burnNFTState.success = true;
      // Update state based on the successful win
      // ...
    },
    [burnNFT.rejected]: (state, action) => {
      state.loading = false;
    },
    //buy direct NFT
    [buyDirectNFT.pending]: (state) => {
      state.loading = true;
    },
    [buyDirectNFT.fulfilled]: (state, action) => {
      state.loading = false;
      state.buyDirectNFTState.data = action.payload;
      // Update state based on the successful win
      // ...
    },
    [buyDirectNFT.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },

    //get user Collection
    [getUserCollection.pending]: (state) => {
      state.loading = true;
    },
    [getUserCollection.fulfilled]: (state, action) => {
      state.loading = false;
      state.collection = action.payload;
      // Update state based on the successful win
      // ...
    },
    [getUserCollection.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },

    // sell nft
    [saleNFT.pending]: (state) => {
      state.sellNFT.loading = true;
    },
    [saleNFT.fulfilled]: (state, action) => {
      state.sellNFT.loading = false;

      state.sellNFT.data = action.payload;
      // Update state based on the successful win
      // ...
    },
    [saleNFT.rejected]: (state, action) => {
      state.sellNFT.loading = false;
      state.sellNFT.error = action.payload;
    },

    // listed nft
    [getListedNft.pending]: (state) => {
      state.loading = true;
    },
    [getListedNft.fulfilled]: (state, action) => {
      state.loading = false;
      state.listedNft = action.payload;
      // Update state based on the successful win
      // ...
    },
    [getListedNft.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },

    // My offers nft
    [getMyOffersNft.pending]: (state) => {
      state.loading = true;
    },
    [getMyOffersNft.fulfilled]: (state, action) => {
      state.loading = false;
      state.myOffersNft = action.payload;
      // Update state based on the successful win
      // ...
    },
    [getMyOffersNft.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },

    // nft waiting my approval
    [getNftWaitingMyApproval.pending]: (state) => {
      state.loading = true;
    },
    [getNftWaitingMyApproval.fulfilled]: (state, action) => {
      state.loading = false;
      state.waitingApproval = action.payload;
      // Update state based on the successful win
      // ...
    },
    [getNftWaitingMyApproval.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },

    // Details nft
    [getNftDetail.pending]: (state) => {
      state.loadingNft = true;
    },
    [getNftDetail.fulfilled]: (state, action) => {
      state.loadingNft = false;
      state.nftDetails = action.payload;
      // Update state based on the successful win
      // ...
    },
    [getNftDetail.rejected]: (state, action) => {
      state.loadingNft = false;
      state.error = action.payload;
    },

    // approve offer
    [approveOffer.pending]: (state) => {
      state.loadingNft = true;
    },
    [approveOffer.fulfilled]: (state, action) => {
      state.loadingNft = false;
      state.approveOfferNft.data = action.payload;
    },
    [approveOffer.rejected]: (state, action) => {
      state.loadingNft = false;
      state.error = action.payload;
    },

    // Transfer offer
    [getTransferOffer.pending]: (state) => {
      state.getTransferOffer.loading = true;
    },
    [getTransferOffer.fulfilled]: (state, action) => {
      state.getTransferOffer.loading = false;
      state.getTransferOffer.data = action.payload;
    },
    [getTransferOffer.rejected]: (state, action) => {
      state.getTransferOffer.loading = false;
      state.getTransferOffer.error = action.payload;
    },

    // get liked nfts

    [getLikedNfts.pending]: (state) => {
      state.getLikedNfts.loading = true;
    },
    [getLikedNfts.fulfilled]: (state, action) => {
      state.getLikedNfts.loading = false;
      state.getLikedNfts.data = action.payload;
    },
    [getLikedNfts.rejected]: (state, action) => {
      state.getLikedNfts.loading = false;
      state.getLikedNfts.error = action.payload;
    },

    // approveOfferAccpet offer

    [accpetTransfer.pending]: (state) => {
      state.acceptTransferRes.loading = true;
    },
    [accpetTransfer.fulfilled]: (state, action) => {
      state.acceptTransferRes.loading = false;
      state.acceptTransferRes.data = action.payload;
    },
    [accpetTransfer.rejected]: (state, action) => {
      state.acceptTransferRes.loading = false;
      state.acceptTransferRes.error = action.payload;
    },

    // freeTransferNFT
    [freeTransferNFT.pending]: (state) => {
      state.transferNft.loading = true;
    },
    [freeTransferNFT.fulfilled]: (state, action) => {
      state.transferNft.loading = false;
      state.transferNft.data = action.payload;
    },
    [freeTransferNFT.rejected]: (state, action) => {
      state.transferNft.loading = false;
      state.transferNft.error = action.payload;
    },

    // Sell offer Cancel
    [sellOfferCancel.pending]: (state) => {
      state.loading = true;
    },
    [sellOfferCancel.fulfilled]: (state, action) => {
      state.loading = false;
      state.sellOfferCancelSatate.data = action.payload;
    },
    [sellOfferCancel.rejected]: (state, action) => {
      state.loading = false;
      state.sellOfferCancelSatate.error = action.payload;
    },

    //sync Status

    [syncStatusAPI.pending]: (state) => {
      state.syncStatus.loading = true;
    },
    [syncStatusAPI.fulfilled]: (state, action) => {
      state.syncStatus.loading = false;
      state.syncStatus.data = action.payload;
    },
    [syncStatusAPI.rejected]: (state, action) => {
      state.syncStatus.loading = false;
      state.syncStatus.error = action.payload;
    },

    // syncNFTAPI /////

    [syncNFTAPI.pending]: (state) => {
      state.syncStatus.loading = true;
    },
    [syncNFTAPI.fulfilled]: (state, action) => {
      state.syncStatus.loading = false;
      state.nftSync = action.payload;
    },
    [syncNFTAPI.rejected]: (state, action) => {
      state.syncStatus.loading = false;
      state.syncStatus.error = action.payload;
    },

    /////////////////////////////// USD ///////////////////////////////////
    [getUSDCollectionNFT.pending]: (state) => {
      state.loading = true;
    },
    [getUSDCollectionNFT.fulfilled]: (state, action) => {
      state.loading = false;
      state.USDCollectionNFT.data = action.payload;
    },
    [getUSDCollectionNFT.rejected]: (state, action) => {
      state.loading = false;
      state.USDCollectionNFT.error = action.payload;
    },

    //////////////////////////////////////////////////////////
    [getFreeMintAPI.pending]: (state) => {
      state.loading = true;
    },
    [getFreeMintAPI.fulfilled]: (state, action) => {
      state.loading = false;
      state.getFreeMintData.data = action.payload;
    },
    [getFreeMintAPI.rejected]: (state, action) => {
      state.loading = false;
      state.getFreeMintData.error = action.payload;
    },

    [addFreeMintAPI.pending]: (state) => {
      state.loading = true;
    },
    [addFreeMintAPI.fulfilled]: (state, action) => {
      state.loading = false;
      state.addFreeMintData.data = action.payload;
    },
    [addFreeMintAPI.rejected]: (state, action) => {
      state.loading = false;
      state.addFreeMintData.error = action.payload;
    },

    [syncNftAPI.pending]: (state) => {
      state.loading = true;
    },
    [syncNftAPI.fulfilled]: (state, action) => {
      state.loading = false;
      state.syncNftId.data = action.payload;
    },
    [syncNftAPI.rejected]: (state, action) => {
      state.loading = false;
      state.syncNftId.error = action.payload;
    },

    [addFreeGameMintAPI.pending]: (state) => {
      state.loading = true;
    },
    [addFreeGameMintAPI.fulfilled]: (state, action) => {
      state.loading = false;
      state.addFreeMintData.data = action.payload;
    },
    [addFreeGameMintAPI.rejected]: (state, action) => {
      state.loading = false;
      state.addFreeMintData.error = action.payload;
    },

    [getFreeMintAllUsersAPI.pending]: (state) => {
      state.loading = true;
    },
    [getFreeMintAllUsersAPI.fulfilled]: (state, action) => {
      state.loading = false;
      state.getFreeMintAllUsersData.data = action.payload;
    },
    [getFreeMintAllUsersAPI.rejected]: (state, action) => {
      state.loading = false;
      state.getFreeMintAllUsersData.error = action.payload;
    },

    [getWalletAddressesSearchAPI.pending]: (state) => {
      state.loading = true;
    },
    [getWalletAddressesSearchAPI.fulfilled]: (state, action) => {
      state.loading = false;
      state.getWalletAddressesSearchData.data = action.payload;
    },
    [getWalletAddressesSearchAPI.rejected]: (state, action) => {
      state.loading = false;
      state.getWalletAddressesSearchData.error = action.payload;
    },

    [getWalletAddressesAPI.pending]: (state) => {
      state.loading = true;
    },
    [getWalletAddressesAPI.fulfilled]: (state, action) => {
      state.loading = false;
      state.getWalletAddressesData.data = action.payload;
    },
    [getWalletAddressesAPI.rejected]: (state, action) => {
      state.loading = false;
      state.getWalletAddressesData.error = action.payload;
    },

    [getWalletAddressesByOrderAPI.pending]: (state) => {
      state.loading = true;
    },
    [getWalletAddressesByOrderAPI.fulfilled]: (state, action) => {
      state.loading = false;
      state.getWalletAddressesByOrderData.data = action.payload;
    },
    [getWalletAddressesByOrderAPI.rejected]: (state, action) => {
      state.loading = false;
      state.getWalletAddressesByOrderData.error = action.payload;
    },

    [addWalletAddressesAPI.pending]: (state) => {
      state.loading = true;
    },
    [addWalletAddressesAPI.fulfilled]: (state, action) => {
      state.loading = false;
      state.addWalletAddressesData.data = action.payload;
    },
    [addWalletAddressesAPI.rejected]: (state, action) => {
      state.loading = false;
      state.addWalletAddressesData.error = action.payload;
    },

    [addMintsAPI.pending]: (state) => {
      state.loading = true;
    },
    [addMintsAPI.fulfilled]: (state, action) => {
      state.loading = false;
      state.addMintsData.data = action.payload;
    },
    [addMintsAPI.rejected]: (state, action) => {
      state.loading = false;
      state.addMintsData.error = action.payload;
    },
  },
});
export default MyNftSlice.reducer;
export const {
  resetAcceptTransferRes,
  resetSellState,
  resetApproveSate,
  resetTransferNftState,
  resetCollectionNft,
  resetTransferState,
  resetsellOfferCancelState,
  resetBuyOfferState,
  resetBurnState,
  resetapproveOfferNft,
  resetUSDCollectionState,
  resetBuyDirectNftState,
  resetGetFreeMintAPI,
  resetAddFreeMintAPI,
  resetGetFreeMintAllUsersAPI,
  resetGetWalletAddressesAPI,
  resetAddWalletAddressesAPI,
  resetGetWalletAddressesByOrderAPI,
  resetGetWalletAddressesSearchAPI,
  resetAddMintsAPI,
  resetSyncNftId,
} = MyNftSlice.actions;
