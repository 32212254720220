import React, { useEffect, useState } from "react";
import { Box, Grid, Typography, Divider } from "@mui/material";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux/es/hooks/useSelector";

import { useNavigate, useLocation } from "react-router-dom";
import NFTCard from "../../components/features/NFTCard/NFTCard";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import CollectionHeader from "../../components/layout/Shared/CollectionHeader/CollectionHeader";
import useScrollPagination from "../../hooks/useScrollPagination/useScrollPagination";
import CustomLoader from "../../components/shared/customLoader/customLoader";
import {
  getUSDCollectionNFT,
  getUserCollectionNFT,
  resetCollectionNft,
  resetSellState,
  resetTransferState,
  resetapproveOfferNft,
} from "../../services/redux/MyNftApiReducer/MyNftApiReducer";
import { useApiResponse } from "../../hooks/useApiResponse";
import toast from "react-hot-toast";
import ScrollToTopOnMount from "../../utils/ScrollToTop/automaticScrollToTop/automaticScrollToTop";
import { resetText } from "../../services/redux/TextReducer/TextReducer";
import { resetOffsetState } from "../../services/redux/OffsetReducer/OffsetReducer";

const containerStyle = {
  position: "relative",
  width: "100%",
  minHeight: "100vh",
  background:
    "linear-gradient(225deg, #731686 1%, transparent 10%, transparent 80%, #af22c8 100%)", // Linear gradient background
  backgroundSize: "cover",
  backgroundRepeat: "no-repeat",
  backgroundPosition: "center",
  paddingBottom: "20px",
};
const contentStyle = {
  position: "relative",
  top: "0px",
  width: "100%",
};

const USDCollectionNFTs = () => {
  let lastScrollPosition = 0;
  let is90vhReached = false;
  let heightOfPage = document.documentElement.scrollHeight;
  const theme = useTheme();
  const dispatch = useDispatch();
  // const navigate = useNavigate();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const [loadingPage, setLoadingPage] = useState(false);
  const acc = localStorage.getItem("accnum");
  const [myNFT, setMyNFT] = useState([]);

  const [offset, setOffset] = useState(1);
  const [pages, setPages] = useState(1);

  const {
    loading,
    transferNft,
    USDCollectionNFT,
    sell,
    tabValue,
    sellNFT,
    approveOfferNft,
    getTransferNftState,
    sellOfferCancelSatate,
    buyNFTState,
  } = useSelector((state) => state.mynft);
  const { text } = useSelector((state) => state.text);
  const { offset: offsetState } = useSelector((state) => state.offset);

  const { collectionId, taxonId } = useSelector((state) => state.collection);

  useEffect(() => {
    return;
    setOffset(1);
    // setMyNFT
  }, []);
  useEffect(() => {
    setOffset(1);
  }, [offsetState]);

  useEffect(() => {
    setLoadingPage(loading || sellNFT?.loading || USDCollectionNFT.loading);
  }, [loading, sellNFT]);

  useEffect(() => {
    window.scrollTo(0, 0);
    return () => {
      // Perform cleanup here, for example, resetting the data
      setMyNFT([]);
    };
  }, []);

  useEffect(() => {
    if (sellNFT?.data?.status) {
      toast.success(sellNFT?.data?.message);
      dispatch(resetSellState());
      setOffset(1);

      dispatch(resetText(""));
      // window.scrollTo(0, 0);
    } else if (sellNFT?.data?.status === false) {
      toast.error(sellNFT?.data?.message);
      dispatch(resetSellState());
    }
  }, [sellNFT?.data?.status]);

  useEffect(() => {
    if (approveOfferNft?.data?.status === true) {
      dispatch(resetapproveOfferNft());
      setOffset(1);
      dispatch(resetText(""));
    } else if (approveOfferNft?.data?.status === false) {
      dispatch(resetapproveOfferNft());
    }
  }, [approveOfferNft]);

  const fetchData = () => {
    console.log("collectionId", collectionId);

    const collectionDetails = {
      // offset: "", // Replace with actual data

      collection_id: collectionId,
      limit: 12,
      offset: offset,
    };
    dispatch(getUSDCollectionNFT(collectionDetails));
  };

  /////////////////////////  pagination  ////////////////////

  useEffect(() => {
    if (offset === 1) {
      fetchData();
    } else if (offset <= pages) {
      fetchData();
    }
  }, [
    offset,
    USDCollectionNFT?.data?.status,
    sell?.data?.status,
    tabValue?.data?.status,
    sellNFT?.data?.status,
    approveOfferNft?.data?.status,
    getTransferNftState?.data?.status,
    sellOfferCancelSatate?.data?.status,
    buyNFTState?.data?.status,
  ]);

  function handleScroll() {
    const scrollPosition = window.scrollY;
    const viewportHeight = window.innerHeight;
    const pageHeight = heightOfPage;

    if (
      !USDCollectionNFT.loading &&
      !sellNFT.loading &&
      scrollPosition >= lastScrollPosition + 0.9 * viewportHeight
    ) {
      const newOffset = Math.floor(scrollPosition / (0.9 * viewportHeight));
      setOffset(newOffset + 1); // Add 1 because indexing starts from 0
      lastScrollPosition = newOffset * 0.9 * viewportHeight;
    }

    // Check if 90vh is reached
    if (!is90vhReached && scrollPosition >= pageHeight) {
      setOffset((prevOffset) => prevOffset + 1);
      is90vhReached = true;
    }

    // Reset is90vhReached when page reaches 90vh again
    if (is90vhReached && scrollPosition < 0.9 * pageHeight) {
      is90vhReached = false;
    }
  }
  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  function mergeArrays(existingData, newData) {
    // Ensure newData is not undefined or null
    const mergedData = newData ?? [];

    // Clear the existingData if offset is 1
    if (offset === 1) {
      existingData = [];
      console.log("ifssssssss");
      dispatch(resetOffsetState());
    }

    // Iterate over the new data and push each element to the existing array
    mergedData.forEach((item) => {
      existingData.push(item);
    });

    // Return the updated existing array
    return existingData;
  }

  useEffect(() => {
    setMyNFT((prevNFTs) =>
      mergeArrays(prevNFTs, USDCollectionNFT?.data?.data?.nfts)
    );

    if (offset === 1) {
      setPages(USDCollectionNFT?.data?.data?.collection_info?.total_pages);
    }
  }, [USDCollectionNFT]);

  function renderContent() {
    if (sellNFT.loading === true) {
      return (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            minHeight: "80vh", // Set minimum height to fill the entire viewport
          }}
        >
          <CustomLoader
            width={"60%"}
            text={text || text !== "" ? text : "Please wait ..."}
          />{" "}
        </Box>
      );
    } else {
      if (loading && !myNFT?.length > 0) {
        return (
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              minHeight: "80vh", // Set minimum height to fill the entire viewport
            }}
          >
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                minHeight: "80vh", // Set minimum height to fill the entire viewport
              }}
            >
              {" "}
              <CustomLoader width={"60%"} text={"Please wait ..."} />{" "}
            </Box>
          </Box>
        );
      } else if (myNFT && myNFT?.length > 0) {
        return (
          <Box sx={{ display: "flex", flexDirection: "column" }} width={"100%"}>
            <Grid
              container
              spacing={4}
              sx={{ paddingInline: 4 }}
              justifyContent={myNFT?.length < 4 ? "center" : "start"}
            >
              {myNFT &&
                myNFT?.map((items) => (
                  <Grid
                    item
                    xl={3}
                    lg={3}
                    sm={12}
                    xs={6}
                    sx={{ display: "flex", justifyContent: "center" }}
                    key={items.id}
                  >
                    <NFTCard nft={items} borderColor="#AF22C8" />
                  </Grid>
                ))}
            </Grid>
            {loading && (
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  minHeight: "80vh", // Set minimum height to fill the entire viewport
                }}
              >
                <CustomLoader
                  width={"60%"}
                  text={text || text !== "" ? text : "Please wait ..."}
                />{" "}
              </Box>
            )}
          </Box>
        );
      } else {
        return (
          <Box sx={{ display: "flex", justifyContent: "center", pt: 6 }}>
            {!loading && (
              <Typography
                variant="h6"
                sx={{ color: "#f1f1f1", fontFamily: "goodTime" }}
              >
                No NFT's Found!
              </Typography>
            )}
          </Box>
        );
      }
    }
  }
  return (
    <div style={containerStyle}>
      <div style={contentStyle}>
        <ScrollToTopOnMount />
        <Grid container spacing={2}>
          <Grid item xs={2}>
            {" "}
          </Grid>

          <Grid item xs={8}>
            <>
              {/* Desktop view */}
              <Box>
                <CollectionHeader
                  Data={USDCollectionNFT?.data?.data}
                  loading={loading}
                />
              </Box>
            </>
          </Grid>

          <Grid item xs={2}></Grid>
        </Grid>
        <Divider
          variant="middle"
          sx={{ mt: 2, backgroundColor: "#AF22C8", mx: 4, mb: 4 }}
        />
        <Box>{renderContent()}</Box>
      </div>
    </div>
  );
};
export default USDCollectionNFTs;
