import * as React from "react";
import { styled } from "@mui/material/styles";
import Card from "@mui/material/Card";

import { Paper, Typography, Box } from "@mui/material";

const cardStyle = {
  maxWidth: 345,
  transition: "transform 0.3s ease-in-out", // Add a transition for the transform property
  "&:hover": {
    border: "4px solid purple",
    transform: "scale(1.03)", // Scale up the card on hover
  },
};

export default function MintingCard({ image, border }) {
  return (
    <Card sx={cardStyle}>
      <Box></Box>
      <Box
        sx={{
          height: 340,
          justifyContent: "center",
          textAlign: "center",
          borderRadius: 4,
          cursor: "pointer",
          position: "relative",
          boxSizing: "border-box", // Add box-sizing property
          padding: border ? 0.5 : 0,
        }}
      >
        <img
          style={{
            width: "100%",
            height: "100%",
            padding: 0,
            cursor: "pointer",
            objectFit: "contain",
          }}
          src={image}
          //   title="green iguana"
        />
      </Box>

      {/* <Collapse in={expanded} timeout="auto" unmountOnExit></Collapse> */}
    </Card>
  );
}
