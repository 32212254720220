import React, { useEffect, useState } from "react";
import {
  Box,
  Grid,
  Typography,
  Button,
  Divider,
  Menu,
  MenuItem,
  Tabs,
  Tab,
} from "@mui/material";

import { useDispatch } from "react-redux";
import { toast } from "react-hot-toast";
import TransferView from "../../components/features/TransferView/TransferView";
import { useSelector } from "react-redux/es/hooks/useSelector";

import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useNavigate, useParams } from "react-router-dom";

import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";

import Image1 from "../../assets/MainCarousel/HardcoreLions.png";
import Image2 from "../../assets/MainCarousel/LuxLionKingTier.jpg";
import Image3 from "../../assets/MainCarousel/LuxLionsHunterTier.png";
import Image4 from "../../assets/MainCarousel/LuxLionsHuntressTier.png";
import { getTransferOffer } from "../../services/redux/MyNftApiReducer/MyNftApiReducer";
import CustomLoader from "../../components/shared/customLoader/customLoader";
import { resetText } from "../../services/redux/TextReducer/TextReducer";

const containerStyle = {
  position: "relative",
  width: "100%",
  minHeight: "100vh",
  background:
    "linear-gradient(225deg, #731686 1%, transparent 10%, transparent 80%, #af22c8 100%)", // Linear gradient background
  backgroundSize: "cover",
  backgroundRepeat: "no-repeat",
  backgroundPosition: "center",
  paddingBottom: "20px",
};
const contentStyle = {
  position: "relative",
  top: "0px",
  width: "100%",
};

const TransferNFTs = () => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const [loader, setLoader] = useState(false);
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const [transferData, setTransferData] = useState([]);
  const txtcolor = "#f1f1f1";
  const navigate = useNavigate();
  const smallScreen = useMediaQuery("(max-width: 724px)");

  const [selectedTab, setSelectedTab] = useState(0);

  const handleChange = (event, newValue) => {
    setSelectedTab(newValue);
  };

  const {
    loading,
    getTransferOffer: transferList,
    acceptTransferRes,
  } = useSelector((state) => state.mynft);

  useEffect(() => {
    dispatch(resetText(""));
    dispatch(getTransferOffer());
  }, []);

  useEffect(() => {
    setTransferData(transferList?.data?.data?.nfts);
  }, [transferList]);

  useEffect(() => {
    setLoader(transferList.loading || acceptTransferRes.loading);
  }, [transferList.loading, acceptTransferRes.loading]);

  const { text } = useSelector((state) => state.text);

  return (
    <div style={containerStyle}>
      <div style={contentStyle}>
        <>
          <Grid container spacing={2}>
            <Grid item xs={2}></Grid>

            <Grid item xs={8}>
              <Box sx={{ py: 2, pb: 0 }}>
                {isMobile ? (
                  // Mobile view
                  <Typography
                    variant="h5"
                    sx={{
                      fontWeight: 600,
                      color: "white",
                      textAlign: "center",
                      fontFamily: "GoodTime",
                      fontSize: "28px",
                    }}
                  >
                    {"Transfer NFT's NFT's"}
                  </Typography>
                ) : (
                  // Desktop view
                  <Typography
                    variant="h5"
                    sx={{
                      fontWeight: 600,
                      color: "white",
                      fontFamily: "GoodTime",
                      textAlign: "center",
                      py: 2,
                    }}
                  >
                    {"Transfer NFT's"}
                  </Typography>
                )}
              </Box>
            </Grid>

            <Grid item lg={2}>
              {/* <Box sx={{ display: "flex", justifyContent: "center", pt: 3 }}>
                <Button
                  size="large"
                  variant="contained"
                  sx={{
                    bgcolor: "#af22c8",
                    "&:hover": {
                      bgcolor: "#62136f", // Adjust the darkness level as needed
                    },
                  }}
                  onClick={() => navigate("/bulk-transfer")}
                >
              
                  Bulk Transfer
                </Button>
              </Box> */}
            </Grid>
          </Grid>

          {loader ? (
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                minHeight: "80vh", // Set minimum height to fill the entire viewport
              }}
            >
              <CustomLoader
                width={"60%"}
                text={text || text !== "" ? text : "Please wait ..."}
              />
            </Box>
          ) : (
            <>
              <Box sx={{ pb: 4 }}>
                {" "}
                <TransferView data={transferList?.data?.data?.nfts} />
              </Box>
            </>
          )}
        </>
      </div>
    </div>
  );
};
export default TransferNFTs;
