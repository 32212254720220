import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  TextField,
  Grid,
  MenuItem,
  Typography,
} from "@mui/material";
// import "./Mint.css";

// import styles from "./Mint.module.css";
import { useNavigate } from "react-router-dom";
import BackgroundWrapper from "../../../../layout/Shared/BackgroundWrapper/BackgroundWrapper";
import { toast } from "react-hot-toast";
import { axiosRequestAuth } from "../../../../../services/api/api";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
// import CustomInput from "../../components/shared/customInput/customInput";

let flags = [
  { key: 8, value: "Transferable" },
  { key: 9, value: "Transferable, Burnable" },
];

const BulkMintingForm = () => {
  const [loading, setLoading] = useState(false);
  const bgcolor = "rgba(104, 103, 103, 0.5)";
  const txtcolor = "#f1f1f1";
  const navigate = useNavigate();

  const [linkState, setLinkState] = useState(null);
  const [transferFee, setTransferFee] = useState();
  const [collection, setCollection] = useState([]);
  const [collectionTaxon, setCollectionTaxon] = useState();
  const [flagsState, setFlagsState] = useState(8);
  const [price, setPrice] = useState();

  const [formValid, setFormValid] = useState(false);

  const checkFormValidity = () => {
    if (
      linkState &&
      collection &&
      collectionTaxon &&
      flagsState &&
      transferFee > 0
    ) {
      setFormValid(true);
    } else {
      setFormValid(false);
    }
  };

  const onSubmit = () => {
    MintNft();
  };

  useEffect(() => {
    getAllCollection();
  }, []);
  useEffect(() => {
    checkFormValidity();
  }, [linkState, collection, collectionTaxon, flagsState, transferFee, price]);

  const handleTransferFee = (e) => {
    const inputValue = e.target.value;

    setTransferFee(inputValue);
  };
  const handlePrice = (e) => {
    const inputValue = e.target.value;

    setPrice(inputValue);
  };
  const handleCollectionChange = (event) => {
    const selectedTaxonId = event.target.value;
    setCollectionTaxon(selectedTaxonId);
  };

  const handleLink = (event) => {
    const selectedTaxonId = event.target.value;
    setLinkState(selectedTaxonId);
  };
  const handleFlagChange = (event) => {
    const flagsVal = event.target.value;
    setFlagsState(flagsVal);
  };

  async function getAllCollection(formData) {
    setLoading(true);

    try {
      const response = await axiosRequestAuth("get", "v2/collection/drop-down");

      setLoading(false);
      setCollection(response.data.data);
    } catch (error) {
      setLoading(false);
    }
  }
  async function MintNft() {
    setLoading(true);

    try {
      const response = await axiosRequestAuth("post", "v2/bulk-mint", {
        bucket_url: linkState,
        taxon: collectionTaxon,
        flag: parseInt(flagsState),
        transfer_fee: transferFee,
        // price: price,
      });
      toast.success(response.data.message);

      setLoading(false);
      navigate("/bulk-mint-detail");
      setLinkState("");
      setTransferFee("");
      setFlagsState("");
      setCollectionTaxon("");
      setPrice("");
      // toast.success(response.data.message);
    } catch (error) {
      setLoading(false);
      toast.error(error?.response?.data?.message);
    }
  }

  return (
    <BackgroundWrapper>
      <Box>
        <Grid container spacing={2}>
          <Grid item lg={2}>
            {" "}
            <Box
              sx={{
                display: "flex",
                justifyContent: "start",
                pt: 1,
                ml: 1,
                pl: 6,
              }}
            >
              <Button
                size="large"
                sx={{ color: txtcolor }}
                onClick={() => navigate(-1)}
              >
                <ArrowBackIcon fontSize="large" sx={{ color: txtcolor }} />
              </Button>
            </Box>
          </Grid>
          <Grid item lg={8}>
            <Box sx={{ display: "flex", justifyContent: "center", pt: 2 }}>
              <Typography
                variant="h4"
                sx={{ color: txtcolor, fontWeight: 600 }}
              >
                Bulk Minting
              </Typography>
            </Box>
          </Grid>
          <Grid item lg={2}></Grid>
        </Grid>

        <Grid
          container
          spacing={2}
          sx={{
            display: "flex",
            direction: "row",
            justifyContent: "center",
            pt: 2,
          }}
        >
          <Grid
            item
            xl={4}
            lg={4}
            sm={4}
            sx={{
              p: 2,
            }}
          />

          <Grid
            item
            xl={4}
            lg={4}
            sm={4}
            sx={{
              p: 2,
            }}
          >
            <Box
              sx={{
                mt: 2,
                display: "flex",
                justifyContent: "center",
                // background: "grey",
                borderRadius: 1,
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  width: "100%",
                  pt: 4,
                }}
              >
                <Typography
                  variant="body1"
                  sx={{ color: txtcolor, fontWeight: 500 }}
                >
                  S3 Bucket Link
                </Typography>
                <TextField
                  size="small"
                  fullWidth
                  onChange={handleLink}
                  id="outlined-basic"
                  // placeholder={"Select Collection"}
                  variant="outlined"
                  sx={{
                    "& .MuiOutlinedInput-root": {
                      backgroundColor: bgcolor, // Background color when not active
                      "&:hover fieldset": {
                        borderColor: "purple", // Border color on hover
                      },
                      "&.Mui-focused fieldset": {
                        borderColor: "purple", // Border color on focus
                      },
                    },
                    "& .MuiInputBase-input": {
                      color: txtcolor, // Text color
                    },
                  }}
                />
              </Box>
            </Box>

            <Box sx={{ pt: 2 }}>
              <Typography
                variant="body1"
                sx={{ color: txtcolor, fontWeight: 500 }}
              >
                Select Collection*
              </Typography>
              <TextField
                select
                size="small"
                fullWidth
                onChange={handleCollectionChange}
                id="outlined-basic"
                placeholder={"Select Collection"}
                variant="outlined"
                sx={{
                  "& .MuiOutlinedInput-root": {
                    backgroundColor: bgcolor, // Background color when not active
                    "&:hover fieldset": {
                      borderColor: "purple", // Border color on hover
                    },
                    "&.Mui-focused fieldset": {
                      borderColor: "purple", // Border color on focus
                    },
                  },
                  "& .MuiInputBase-input": {
                    color: txtcolor, // Text color
                  },
                  "& .MuiSelect-icon": {
                    color: "white", // Color of the dropdown icon
                  },
                }}
              >
                {collection.map((collection) => (
                  <MenuItem
                    key={collection.taxon_id}
                    value={collection.taxon_id}
                  >
                    {collection.name}
                  </MenuItem>
                ))}
              </TextField>
            </Box>
            {/* <Box sx={{ pt: 2 }}>
              <Typography
                variant="body1"
                sx={{ color: txtcolor, fontWeight: 500 }}
              >
                Price*
              </Typography>
              <TextField
                size="small"
                fullWidth
                onChange={handlePrice}
                id="outlined-basic"
                placeholder={"Price"}
                variant="outlined"
                sx={{
                  "& .MuiOutlinedInput-root": {
                    backgroundColor: bgcolor, // Background color when not active
                    "&:hover fieldset": {
                      borderColor: "purple", // Border color on hover
                    },
                    "&.Mui-focused fieldset": {
                      borderColor: "purple", // Border color on focus
                    },
                  },
                  "& .MuiInputBase-input": {
                    color: txtcolor, // Text color
                  },
                }}
              ></TextField>
            </Box> */}
            <Grid
              container
              sx={{ display: "flex", flexDirection: "row", width: "100%" }}
            >
              <Grid item lg={6} sx={{ pt: 2, pr: 1 }}>
                <Typography
                  variant="body1"
                  sx={{ color: txtcolor, fontWeight: 500 }}
                >
                  Set Flag
                </Typography>
                <TextField
                  select
                  size="small"
                  fullWidth
                  onChange={handleFlagChange}
                  id="outlined-basic"
                  placeholder={"Select Flag"}
                  variant="outlined"
                  sx={{
                    "& .MuiOutlinedInput-root": {
                      backgroundColor: bgcolor, // Background color when not active
                      "&:hover fieldset": {
                        borderColor: "purple", // Border color on hover
                      },
                      "&.Mui-focused fieldset": {
                        borderColor: "purple", // Border color on focus
                      },
                    },
                    "& .MuiInputBase-input": {
                      color: txtcolor, // Text color
                    },
                    "& .MuiSelect-icon": {
                      color: "white", // Color of the dropdown icon
                    },
                  }}
                >
                  {flags.map((item) => (
                    <MenuItem key={item.key} value={item.key}>
                      {item.value}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>
              <Grid item lg={6} sx={{ pt: 2, pl: 1 }}>
                <Typography
                  variant="body1"
                  sx={{ color: txtcolor, fontWeight: 500 }}
                >
                  Transfer Fee*
                </Typography>
                <TextField
                  required
                  size="small"
                  fullWidth
                  type="number"
                  onChange={handleTransferFee}
                  placeholder={"Select Fees"}
                  variant="outlined"
                  InputProps={{ inputProps: { min: 1 } }}
                  sx={{
                    "& .MuiOutlinedInput-root": {
                      backgroundColor: bgcolor, // Background color when not active
                      "&:hover fieldset": {
                        borderColor: "purple", // Border color on hover
                      },
                      "&.Mui-focused fieldset": {
                        borderColor: "purple", // Border color on focus
                      },
                    },
                    "& .MuiInputBase-input": {
                      color: txtcolor, // Text color
                    },
                  }}
                />
              </Grid>
            </Grid>
            <Box sx={{ display: "flex", justifyContent: "center", pt: 2 }}>
              <Button
                fullWidth
                variant="contained"
                sx={{
                  background: "#EFA61C",
                  color: "white",

                  "&:hover": {
                    backgroundColor: "#b2790d",
                    color: "f1f1f1",
                    "& .MuiSvgIcon-root": {
                      color: "black", // Change the icon color on hover
                    },
                  },
                  "&:active": {
                    backgroundColor: "#e3ab12",
                    color: "f1f1f1",
                    "& .MuiSvgIcon-root": {
                      color: "black", // Change the icon color on click
                    },
                  },
                  "&:disabled": {
                    backgroundColor: "#e3e3e3",
                    cursor: "not-allowed",
                  },
                }}
                onClick={onSubmit}
                disabled={!formValid}
              >
                <Typography sx={{ fontWeight: 600 }}>Mint</Typography>
              </Button>
            </Box>
            {/* </Box> */}
          </Grid>
          <Grid
            item
            lg={4}
            xl={4}
            sm={4}
            sx={{
              p: 2,
            }}
          />
        </Grid>
      </Box>
    </BackgroundWrapper>
  );
};

export default BulkMintingForm;
