import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import NFTCard from "../../../features/NFTCard/NFTCard";
import { Box, Grid, Typography, Skeleton } from "@mui/material";
import { getFeaturedNft } from "../../../../services/redux/LandingPageApiReducer/LandingPageApiReducer";

const SpotLight = () => {
  const dispatch = useDispatch();
  let skeletonArray = [1, 2, 3, 4];

  const { featuredNft } = useSelector((state) => state.nft);

  useEffect(() => {
    dispatch(getFeaturedNft());
  }, []);

  console.log({ featuredNft });

  // useEffect(()=>{
  //   i
  return (
    <Box sx={{ pb: 4 }}>
      <Box sx={{ py: 4, pl: 4, display: "flex", justifyContent: "center" }}>
        {featuredNft?.data?.featured_nfts &&
        featuredNft?.data?.featured_nfts?.length !== 0 ? (
          <Typography
            variant="h5"
            sx={{
              fontWeight: 600,
              color: "#7D3CE0",
              fontFamily: "goodTime",
              fontSize: 28,
              justifyContent: "center",
            }}
          >
            {"Featured"}
          </Typography>
        ) : null}
      </Box>
      <>
        {!featuredNft?.loading ? (
          <Grid
            container
            spacing={3}
            sx={{
              // px: "10%",

              maxWidth: "1200px",
              mx: "auto",
            }}
          >
            {featuredNft?.data?.featured_nfts?.map((items) => (
              <Grid
                item
                xl={3}
                lg={3}
                sm={12}
                xs={12}
                sx={{
                  display: "flex",
                  justifyContent: "center",
                }}
                key={items.id}
              >
                {/* Content for loading being false */}
                <NFTCard
                  nft={items}
                  isForSale={true}
                  isFeatured={true}
                  borderColor="#AF22C8"
                  bgColor="white"
                  loading={false}
                />
              </Grid>
            ))}
          </Grid>
        ) : (
          <Grid container spacing={0} sx={{ px: "10%" }}>
            {skeletonArray?.map((items, index) => (
              <Grid
                item
                xl={3}
                lg={3}
                sm={12}
                xs={12}
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  textAlign: "center",
                }}
                key={items.id}
              >
                {/* Content for loading being true */}

                <Skeleton
                  variant="rectangular"
                  width="100%"
                  height={420}
                  sx={{ bgcolor: "grey.900", borderRadius: 2 }}
                />
              </Grid>
            ))}
          </Grid>
        )}
      </>
    </Box>
  );
};

export default SpotLight;
