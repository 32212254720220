import React, { useEffect } from "react";
// import { makeStyles } from "@mui/styles";
import {
  Typography,
  Button,
  Grid,
  Box,
  Avatar,
  useMediaQuery,
  Divider,
  Checkbox,
  FormControlLabel,
  Menu,
  MenuItem,
  Tabs,
  Tab,
} from "@mui/material";
import toast from "react-hot-toast";
import {
  accpetTransfer,
  resetAcceptTransferRes,
  getTransferOffer,
} from "../../../services/redux/MyNftApiReducer/MyNftApiReducer";
import defaultImage from "../../../assets/Default/default.png";
import { useDispatch, useSelector } from "react-redux";
import { imageURL } from "../../../services/api/api";
import { resetbuyNFTState } from "../../../services/redux/NftApiReducer/NftApiReducer";
import {
  resetText,
  setText,
} from "../../../services/redux/TextReducer/TextReducer";
import { useState } from "react";
import { useParams } from "react-router-dom";
import { getUnmintedNfts } from "../../../services/redux/CampaignsReducer/CampaignsReducer";
const buttonStyles = {
  background: "linear-gradient(to right, #7D3CE0, #af22c8) !important",
  color: "#fff !important",
  borderRadius: 2,
  width: "100%", // Ensures the button spans the full width
  transition: "background 0.3s ease", // Add a transition for smooth effect
  "&:hover": {
    background: "#fff !important",
    color: "#000 !important",
  },
};

const TransferView = ({ data }) => {
  //   const classes = useStyles();
  const verySmallScreen = useMediaQuery("(max-width: 524px)");
  const smallScreen = useMediaQuery("(max-width: 724px)");
  const mediumScreen = useMediaQuery("(max-width: 1084px)");

  const [selectedTab, setSelectedTab] = useState(0);

  const handleChange = (event, newValue) => {
    setSelectedTab(newValue);
  };

  const dispatch = useDispatch();
  const { acceptTransferRes, loading, getTransferNftState } = useSelector(
    (state) => state.mynft
  );

  const { unmintednfts, unmintednftsloading } = useSelector(
    (state) => state.campaigns
  );

  const [selectedItems, setSelectedItems] = useState([]);
  // console.log(selectedItems, "selectedItems");
  const handleSelectAll = (event) => {
    if (event.target.checked) {
      const allItems = data.map((offerData) => ({
        offer_id: offerData.id,
        nft_id: offerData.user_nft.id,
      }));
      setSelectedItems(allItems);
    } else {
      setSelectedItems([]);
    }
  };

  const handleSelectItem = (offerData) => {
    const selectedItem = {
      offer_id: offerData.id,
      nft_id: offerData.user_nft.id,
    };

    setSelectedItems((prevSelectedItems) => {
      const exists = prevSelectedItems.find(
        (item) =>
          item.offer_id === selectedItem?.offer_id &&
          item.nft_id === selectedItem?.nft_id
      );
      if (exists) {
        return prevSelectedItems.filter(
          (item) =>
            item.offer_id !== selectedItem?.offer_id ||
            item.nft_id !== selectedItem?.nft_id
        );
      } else {
        return [...prevSelectedItems, selectedItem];
      }
    });
  };

  const handleTransferAccpet = (offerData) => {
    dispatch(setText("Waiting xaman approval ..."));
    const apiData = {
      offer_id: offerData.id,
      nft_id: offerData.user_nft.id,
    };
    dispatch(accpetTransfer({ accept_data: [apiData] }));
    setSelectedItems([]);
  };
  const handleTransferAccpetAll = (offerData) => {
    dispatch(setText("Waiting xaman approval ..."));
    console.log(offerData, "offerData");
    dispatch(accpetTransfer({ accept_data: offerData }));
    setSelectedItems([]);
  };
  useEffect(() => {
    if (getTransferNftState?.status === true) {
      toast.success(getTransferNftState?.message);
      setTimeout(() => {
        dispatch(getTransferOffer());
      }, 100);
      dispatch(resetAcceptTransferRes());
    } else if (getTransferNftState?.status === false) {
      dispatch(getTransferOffer());
      toast.error(getTransferNftState?.message);
      // dispatch(resetAcceptTransferRes());
    }
  }, [getTransferNftState]);

  useEffect(() => {
    console.log(acceptTransferRes, "acceptTransferRes");
    if (acceptTransferRes?.data?.status === true) {
      toast.success(acceptTransferRes?.data?.message);
      dispatch(resetAcceptTransferRes());
      dispatch(getTransferOffer());
      dispatch(resetText(""));
    } else if (acceptTransferRes?.data?.status === false) {
      console.log(acceptTransferRes, "transferNft");
      toast.error(acceptTransferRes?.data?.message);
      dispatch(resetAcceptTransferRes());
      dispatch(resetText(""));
    }
  }, [acceptTransferRes]);

  // acceptTransferRes
  console.log(acceptTransferRes?.data?.data, "acceptTransferRes");

  // console.log(getTransferNftState, "getTransferNftState");

  if (!data || data.length === 0) {
    return (
      <Box
        sx={{
          p: 4,
          pt: 8,
          display: "flex",
          justifyContent: "center",
          textAlign: "center",
        }}
      >
        <Typography
          sx={{ color: "white", fontFamily: "GoodTime" }}
          variant="body1"
        >
          No request available.
        </Typography>
      </Box>
    );
  }

  return (
    <>
      <Box sx={{ paddingY: "5px", paddingX: "25px" }}>
        <Box sx={{ padding: 2 }}>
          {true && (
            <>
              <Grid container sx={{ px: 6 }} alignItems="center">
                <Grid item xs={6} spacing={2} sx={{ pl: { md: 6 } }}>
                  <FormControlLabel
                    sx={{ color: "white" }}
                    control={
                      <Checkbox
                        //color="#af22c8"
                        sx={{
                          color: "#af22c8",
                          "&.Mui-checked": {
                            color: "#af22c8",
                          },
                        }}
                        checked={selectedItems?.length === data?.length}
                        onChange={handleSelectAll}
                      />
                    }
                    label="Select All"
                  />
                </Grid>
                <Grid
                  item
                  xs={6}
                  sx={{
                    display: "flex",
                    justifyContent: "end",
                  }}
                >
                  <Box>
                    {selectedItems?.length > 0 && (
                      <Button
                        variant="contained"
                        onClick={() => {
                          handleTransferAccpetAll(selectedItems);
                        }}
                        sx={{
                          background:
                            "linear-gradient(to right, #7D3CE0, #af22c8) !important",
                          color: "#fff !important",
                          borderRadius: 2,
                          // Ensures the button spans the full width
                          "&:hover": {
                            background: "#fff !important",
                            color: "#af22c8 !important",
                          },

                          px: 2.7,
                        }}
                      >
                        <Typography
                          variant="h6"
                          sx={{ fontSize: 12, fontWeight: 600 }}
                        >
                          {selectedItems?.length === data?.length
                            ? "Accept All"
                            : "Accept"}
                        </Typography>
                      </Button>
                    )}
                  </Box>
                </Grid>
              </Grid>
              <Box
                sx={{
                  pb: 4,
                  pl: { md: 4 },
                  pr: { md: 4 },
                  display: "flex",
                  justifyContent: "center",
                  textAlign: "center",
                  flexDirection: "column",
                }}
              >
                {data.map((item, index) => (
                  <>
                    <Grid
                      container
                      spacing={1}
                      sx={{ my: 2, p: { md: 2 } }}
                      alignItems="center"
                      key={index}
                    >
                      <Grid item xs={1}>
                        <Checkbox
                          sx={{
                            color: "#af22c8",
                            "&.Mui-checked": {
                              color: "#af22c8",
                            },
                          }}
                          checked={selectedItems?.some(
                            (selectedItem) =>
                              selectedItem?.offer_id === item.id &&
                              selectedItem?.nft_id === item.user_nft.id
                          )}
                          onChange={() => handleSelectItem(item)}
                        />
                      </Grid>
                      <Grid item xs={1}>
                        <Typography
                          variant="h6"
                          sx={{ fontSize: 14, fontWeight: 600, color: "white" }}
                        >
                          {index + 1}
                        </Typography>
                      </Grid>
                      <Grid
                        key={index}
                        item
                        xs={1}
                        sx={{
                          justifyContent: "center",
                          textAlign: "center",
                        }}
                      >
                        {item?.user_nft?.imageUrl ? (
                          <Avatar
                            alt="Remy Sharp"
                            variant="rounded"
                            src={item.user_nft.imageUrl}
                          />
                        ) : (
                          <Avatar
                            alt="Remy Sharp"
                            variant="rounded"
                            src={defaultImage}
                          />
                        )}
                      </Grid>
                      <Grid item xs={7} md={8}>
                        <Typography
                          variant="h6"
                          align="center"
                          sx={{
                            color: "white",
                            fontFamily: "goodTime",
                            fontSize: smallScreen ? 9 : 12,
                          }}
                        >
                          <span style={{ color: "#af22c8" }}>NFT ID :</span>{" "}
                          {smallScreen ? (
                            <>
                              {item?.user_nft?.nftokenID?.slice(0, 5)}
                              {"...."}
                              {item?.user_nft?.nftokenID?.slice(
                                item?.user_nft?.nftokenID?.length - 5,
                                item?.user_nft?.nftokenID?.length
                              )}
                            </>
                          ) : mediumScreen ? (
                            <>{item?.user_nft?.nftokenID?.slice(0, 35)}</>
                          ) : verySmallScreen ? (
                            <>{item?.user_nft?.nftokenID?.slice(0, 20)}</>
                          ) : (
                            <> {item?.user_nft?.nftokenID}</>
                          )}
                        </Typography>
                      </Grid>
                      <Grid
                        item
                        xs={1}
                        sx={{
                          justifyContent: "center",
                          textAlign: "center",
                        }}
                      >
                        <Button
                          variant="contained"
                          onClick={() => {
                            handleTransferAccpet(item);
                          }}
                          sx={{
                            background:
                              "linear-gradient(to right, #7D3CE0, #af22c8) !important",
                            color: "#fff !important",
                            borderRadius: 2,
                            width: "100%", // Ensures the button spans the full width
                            "&:hover": {
                              background: "#fff !important",
                              color: "#af22c8 !important",
                            },
                          }}
                        >
                          <Typography
                            variant="h6"
                            sx={{ fontSize: 12, fontWeight: 600 }}
                          >
                            Accept
                          </Typography>
                        </Button>
                      </Grid>
                    </Grid>
                    <Divider variant="middle" sx={{ bgcolor: "white" }} />
                  </>
                ))}
              </Box>
            </>
          )}
        </Box>
      </Box>
    </>
  );
};

export default TransferView;
