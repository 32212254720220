import React, { useEffect } from "react";
import { Typography, Container, Link, Box } from "@mui/material";

const PrivacyPolicy = () => {
  useEffect(() => {
    window.scroll(0, 0);
  }, []);
  return (
    <Box sx={{ color: "white", pt: 6 }}>
      <Container maxWidth="md" sx={{ color: "white" }}>
        <Typography variant="h4" gutterBottom sx={{ textAlign: "center" }}>
          Privacy Policy For Delux Mint
        </Typography>
        <Typography variant="body1" paragraph>
          This privacy policy describes how your personal information is
          collected, used, and shared when you visit the Delux Mint website any
          of the other services offered by Delux Mint.
        </Typography>
        <Typography variant="body1" paragraph>
          By accessing or using our services, you consent to the information
          collection and data handling practices outlined in this Privacy
          Policy.
        </Typography>
        <Typography variant="body1" paragraph>
          <strong>Personal Information</strong>
        </Typography>
        <Typography variant="body1" paragraph>
          Personal Information is defined as information that can be used to
          identify you, such as your contact details, including your name,
          e-mail address and any other information that you provide in the
          course of requesting access and using the website services, including
          any data about your use of the Services, which may be collected
          automatically.
        </Typography>
        <Typography variant="body1" paragraph>
          When you visit or use the services provided via the website,
          information may automatically be collected about your device,
          including information about your web browser, IP address, and
          including some of the cookies that are installed on your device. While
          within the site, cookies may be used to collect information about the
          way you interact with the website including individual web pages or
          products that you view.
        </Typography>
        <Typography variant="body1" paragraph>
          Note that certain service within our website may require certain
          cookies to be enabled; if all cookies are disabled, not all services
          may be available to you. Other than the information described, Delux
          Mint does not collect any personal information from users of its Site
          or Services. To utilize the services the user will need to establish
          an XRP Ledger with a third party.
        </Typography>
        <Typography variant="body1" paragraph>
          Delux Mint will not rent or sell your Personal Information into third
          parties without your consent.
        </Typography>
        <Typography variant="body1" paragraph>
          <strong>Received Information from Third Parties</strong>
        </Typography>
        <Typography variant="body1" paragraph>
          We currently do not receive personal information from third parties.
          All personal information received by Delux Mint in the future from
          third party websites or applications will be subject to this Privacy
          Policy.
        </Typography>
        <Typography variant="body1" paragraph>
          Lux Lions requires that a user have an XRP Ledger in order to utilize
          provided services. When your XRP Ledger is used purchase a Lux Lion
          NFT, your XRP Ledger account address is provided to Lux Lions by the
          third-party platform on which the XRP Ledger is created.
        </Typography>
        <Typography variant="body1" paragraph>
          <strong> External Websites and Services.</strong>
        </Typography>

        <Typography variant="body1" paragraph>
          Lux Lions services may contain links to third-party websites or
          applications, including for purposes of providing account linking
          services or payment services. Lux Lions has no control over the
          privacy practices or the content of any of these websites or
          applications. As such, Lux Lions is not responsible for the practices
          employed by external websites or services linked to our website,
          including the information or content contained therein. Our Privacy
          Policy also does not apply to third-party websites or services, it is
          specific to our website. Be sure to check the Privacy Policy of
          external websites or applications before use.
        </Typography>

        <Typography variant="body1" paragraph>
          <strong> How does Personal Information get Utilized?</strong>
        </Typography>

        <Typography variant="body1" paragraph>
          We utilize provided personal information generally to fulfill any
          orders placed for Lux Lion services. Additionally, we may use this
          information to:
        </Typography>
        <ul>
          <li>
            <Typography variant="body1">
              Analyze trends and track user usage of and interactions with
              provided services to improve a user experience and our service
              offerings, monitor aggregate metrics such as total number of
              visitors, traffic, and demographic patterns; comply with legal
              obligations and legal process;
            </Typography>
          </li>
          <li>
            <Typography variant="body1">
              Diagnose and/or fix technological issues; prevent fraud or
              criminal activities, misuse of our products or services other than
              the original intent and ensure the security of our IT systems;
            </Typography>
          </li>
          <li>
            <Typography variant="body1">
              Respond to requests from public and government authorities,
              including public and government authorities outside your country
              of residence; and
            </Typography>
          </li>
          <li>
            <Typography variant="body1">Protect our operations.</Typography>
          </li>
        </ul>

        <Typography variant="body1" paragraph>
          We may utilize collected cookies, log files, device identifiers, and
          location data information so that you will not have to re-enter it
          during your visit or the next time you visit our website and will
          provide custom, personalized content and information and help you
          efficiently access your information after you sign in.
        </Typography>

        <Typography variant="body1" paragraph>
          In an ongoing effort to better understand our users and our continued
          services, we might analyze your information in cumulative form to
          operate, maintain, manage, and improve our services. This information
          does not identify you personally. We may share this data with our
          affiliates, agents, and business partners. We may also disclose
          cumulative user statistics in order to describe our services, products
          and our services to current and prospective business partners and to
          other third parties for other lawful purposes.
        </Typography>

        <Typography variant="body1" paragraph>
          <strong>How We Share Personal Information</strong>
        </Typography>

        <Typography variant="body1" paragraph>
          Collected personal information is shared only with trusted third
          parties and business partners to help us use such information to
          provide services to users, as described herein, such as: web hosting
          services, IT and cybersecurity tools and developers.
        </Typography>

        <Typography variant="body1" paragraph>
          In the event of a merger, reorganization, dissolution or similar
          corporate event, or the sale of all or substantially all of our
          assets, we expect that the information that we have collected would be
          transferred to the remaining entity in a merger or the acquiring
          entity. If we are involved in a merger, acquisition or asset sale, or
          in the unlikely event of a bankruptcy, liquidation or receivership of
          our business, and your personal information becomes subject to a
          different privacy policy.
        </Typography>

        <Typography variant="body1" paragraph>
          Finally, we may also share personal information where required to do
          so by law or subpoena or if we reasonably believe that such action is
          necessary to comply with the law and the lawful requests by
          governmental, self-regulatory or other public authorities; to enforce
          our terms of use or to protect the security, quality or integrity of
          our Services; and/or to exercise or protect the rights, property, or
          personal safety of Lux Lions, our users, or others.
        </Typography>

        <Typography variant="body1" paragraph>
          <strong>Personal Information Storage</strong>
        </Typography>

        <Typography variant="body1" paragraph>
          We will maintain collected information in our records until such
          information is automatically deleted in accordance with Lux Lions data
          retention policy or until you ask us to delete this information, if
          earlier. If information has already been provided to external third
          parties as described in this Privacy Policy, retention of that
          information will be subject to those third parties’ policies.
        </Typography>

        <Typography variant="body1" paragraph>
          <strong>Secured Personal Information</strong>
        </Typography>

        <Typography variant="body1" paragraph>
          Lux Lions uses reasonable safeguards to preserve the integrity and
          security of all personal information collected from you or from third
          parties, including encryption. However, Lux Lions cannot ensure or
          warrant the security of any information you transmit to Lux Lions or
          guarantee that information on website may not be accessed, disclosed,
          altered, or destroyed. Underage Usage
        </Typography>

        <Typography variant="body1" paragraph>
          Our services are not intended for individuals under the age of
          eighteen (18), and Lux Lions does not knowingly collect or solicit any
          information from anyone under the age of 18 or knowingly allow such
          persons to register as users.
        </Typography>

        <Typography variant="body1" paragraph>
          <strong>Opt-Out for Direct Marketing</strong>
        </Typography>

        <Typography variant="body1" paragraph>
          You may opt out at any time from the use of your personal information
          for direct marketing purposes by contacting us. Please allow us a
          reasonable time to process your request. With your consent, we may
          send promotional and non-promotional push notifications or alerts. You
          can deactivate these messages at any time.
        </Typography>

        <Typography variant="body1" paragraph>
          <strong>Right to be free from discrimination.</strong>
        </Typography>

        <Typography variant="body1" paragraph>
          You may exercise any of the above rights without fear of being
          discriminated against. We are permitted to provide a different price
          or rate to you if the difference is directly related to the value
          provided to you by your data.
        </Typography>

        <Typography variant="body1" paragraph>
          <strong>Changes to this Privacy Policy </strong>
        </Typography>

        <Typography variant="body1" paragraph>
          This Privacy Policy is effective as of the date stated at the bottom
          of this Privacy Policy. We may make changes to the Privacy Policy at
          our discretion. Changes to the Privacy Policy will apply immediately
          at the time they are made, and your continued access to or use after
          the Privacy Policy have been updated will constitute your binding
          acceptance of all updates. If you do not agree to any revised Privacy
          Policy, you may not access or use the site.
        </Typography>

        <Typography variant="body1" paragraph>
          <strong>Contact Us</strong>
        </Typography>

        <Typography variant="body1" paragraph>
          For more information about our privacy practices, if you have
          questions,
          <Link href="mailto:support@luxlionsnft.com">
            support@luxlionsnft.com
          </Link>
        </Typography>

        <Typography variant="body1" paragraph>
          We will respond to requests within a reasonable timeframe. We retain
          and use your information as necessary to comply with our legal and
          contractual obligations, statute of limitations, resolution of
          disputes, and enforcement of our agreements. Please note that we may
          not be able to delete all of your data upon request depending on the
          reasons above and the nature of your interactions.
        </Typography>

        <Typography variant="body1">Last updated: March 19, 2024</Typography>
      </Container>
    </Box>
  );
};

export default PrivacyPolicy;
