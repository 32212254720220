import React from "react";
import styles from "./home.module.css";
import Banners from "../../components/layout/Home/Banners/Banners";
import SimpleSlider from "../../components/layout/Home/Carousel/Carousel";
import SpotLight from "../../components/layout/Home/SpotLight/SpotLight";
import Category from "../../components/layout/Category/Category";
import BottomBanner from "../../components/layout/Home/Banners/BottomBanner/BottomBanner";
import { useSelector } from "react-redux";
function Home() {
  return (
    <div className={styles.home}>
      <SimpleSlider />
      <Banners />
      <SpotLight />
      <Category />
      <BottomBanner />
    </div>
  );
}

export default Home;
