import React, { useState } from "react";
import {
  Typography,
  Box,
  Avatar,
  Grid,
  ButtonBase,
  Badge,
  Divider,
  Tooltip,
} from "@mui/material";

import { useDispatch } from "react-redux";
import { approveOffer } from "../../../services/redux/NftApiReducer/NftApiReducer";
import { setText } from "../../../services/redux/TextReducer/TextReducer";
const OfferTab = ({ data }) => {
  const dispatch = useDispatch();
  const handleClick = (offer) => {
    // Display an alert with the offer details
    const combinedData = {
      nft_id: data?.id,
      offer_id: offer.id,
    };

    // or if you prefer an array

    dispatch(approveOffer(combinedData));
    dispatch(setText("Waiting xaman approval"));
  };

  const LoginUser = localStorage.getItem("accnum");

  const isUserOwner = data?.owner === LoginUser;

  return (
    <>
      <Box
        sx={{
          color: "white", // Text color white
          borderRadius: "4px", // Rounded corners for the background
          display: "inline-flex", // Use inline-flex to keep the size tight to the content
          alignItems: "center", // Center align items
        }}
      >
        <Typography variant="h5" component="h2" sx={{ color: "white", mr: 1 }}>
          Buy Offers
        </Typography>
        <Badge
          badgeContent={data?.buy_offers && data?.buy_offers?.length}
          color="primary"
          sx={{
            ml: 1,
            ml: 3,
            ".MuiBadge-badge": {
              backgroundColor: "#AF22C8",
              // color: "white",
              py: 2,
              px: 2,
              fontSize: "15px",
            },
          }}
        />
      </Box>
      <Divider
        variant="middle"
        sx={{ my: 1, backgroundColor: "#AF22C8", width: "100%", ml: 0 }}
      />
      {data?.buy_offers &&
        data?.buy_offers.map((offer, index) => (
          <>
            {LoginUser === null ? (
              <Tooltip title="You need to login first">
                <ButtonBase
                  key={offer.nft_offer_index}
                  // onClick={isUserOwner ? () => handleClick(offer) : null}
                  sx={{ width: "100%", textAlign: "left", borderRadius: 0 }}
                >
                  <Grid
                    container
                    sx={{
                      color: "white",
                      py: 1,
                      alignItems: "center",
                      borderRadius: 0,
                      // borderTop: "2px solid #AF22C8",
                      // borderBottom: "2px solid white",
                      cursor: isUserOwner ? "pointer" : "auto",
                      // "&:hover": {
                      //   backgroundColor: isUserOwner ? "grey" : "", // Change color on hover if isUserOwner is true
                      // },
                    }}
                  >
                    <Grid item xs>
                      <Box
                        sx={{
                          cursor: "pointer",
                          p: 1,
                          border: "solid 2px grey",
                          borderRadius: 2,
                          display: "flex",
                          flexDirection: "row",
                          textAlign: "center",
                          width: "100%",
                          transition: "background-color 0.3s",
                        }}
                      >
                        <Typography variant="body1">
                          Sell offer for{" "}
                          <Typography
                            component="span"
                            color="#AF22C8"
                            sx={{ color: "#f1ed00", fontWeight: 600 }}
                          >
                            {" "}
                            {`${offer.price} xrp`}{" "}
                          </Typography>
                          was created by{" "}
                          <span
                            style={{
                              color: "#f1ed00",
                              marginLeft: "2px",
                              fontWeight: 600,
                            }}
                          >
                            {offer.wallet_address}
                          </span>
                        </Typography>
                      </Box>
                    </Grid>
                  </Grid>
                </ButtonBase>
              </Tooltip>
            ) : (
              <ButtonBase
                key={offer.nft_offer_index}
                onClick={isUserOwner ? () => handleClick(offer) : null}
                sx={{ width: "100%", textAlign: "left", borderRadius: 0 }}
              >
                <Grid
                  container
                  sx={{
                    color: "white",
                    py: 1,
                    alignItems: "center",
                    borderRadius: 0,
                    // borderTop: "2px solid #AF22C8",
                    // borderBottom: "2px solid white",
                    cursor: isUserOwner ? "pointer" : "auto",
                    // "&:hover": {
                    //   backgroundColor: isUserOwner ? "grey" : "", // Change color on hover if isUserOwner is true
                    // },
                  }}
                >
                  <Grid item xs>
                    <Box
                      sx={{
                        cursor: "pointer",
                        p: 1,
                        border: "solid 2px #af22c8",
                        borderRadius: 2,
                        display: "flex",
                        flexDirection: "row",
                        textAlign: "center",
                        width: "100%",
                        transition: "background-color 0.3s",

                        "&:hover": {
                          backgroundColor: "#af22c8",
                          boxShadow: "0 0 8px #af22c8",
                          color: "#FFF",
                        },
                      }}
                    >
                      <Typography variant="body1">
                        {isUserOwner ? "Accept" : "Buy"} offer for{" "}
                        <Typography
                          component="span"
                          color="#AF22C8"
                          sx={{ color: "#f1ed00", fontWeight: 600 }}
                        >
                          {" "}
                          {`${offer.price} xrp`}{" "}
                        </Typography>
                        was created by{" "}
                        <span
                          style={{
                            color: "#f1ed00",
                            marginLeft: "2px",
                            fontWeight: 600,
                          }}
                        >
                          {offer.wallet_address}
                        </span>
                      </Typography>
                    </Box>
                  </Grid>
                </Grid>
              </ButtonBase>
            )}
          </>
        ))}
    </>
  );
};
export default OfferTab;
