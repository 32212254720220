import React from "react";
import BackgroundImage from "../../../../assets/Background/WebsiteBackGround.svg";
import { Box } from "@mui/material";
const containerStyle = {
  position: "relative",
  width: "100%",
  minHeight: "120vh",
  backgroundImage: `url(${BackgroundImage})`,
  backgroundSize: "cover",
  backgroundRepeat: "no-repeat",
  backgroundPosition: "center",
};

const contentStyle = {
  position: "absolute",
  top: "0px",
  width: "100%",
};

const BackgroundWrapper = ({ children }) => {
  return (
    <Box sx={{ mt: 0 }}>
      <div style={containerStyle}>
        <div style={contentStyle}>{children}</div>
      </div>
    </Box>
  );
};

export default BackgroundWrapper;
