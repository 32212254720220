import "./App.css";
import AllRoutes from "./routes/routes";
import Header from "./components/layout/Shared/Header/Header";
import { ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import theme from "./theme"; // Import your custom theme
import Footer from "./components/layout/Shared/Footer/Footer";
import ToastConfig from "./utils/Toast/toastConfig";
import ScrollToTop from "./utils/ScrollToTop/ScrollToTop";
import { axiosRequest, axiosRequestAuth, socketBaseURL } from "./services/api/api";
import { useDispatch, useSelector } from "react-redux";
import { setApiTimer, setCountNFT, setIsAdmin, setToken, setUserId } from "./services/redux/UserNFTCountReducer/UserNFTCountReducer";
import { useEffect } from "react";
import { useState } from "react";
import { io } from "socket.io-client";
import Login from "./components/features/Login/login";
import toast from "react-hot-toast";
import { login } from "./services/redux/LoginReducers/LoginReducers";
import { XummPkce } from "xumm-oauth2-pkce";
import UserCollectionNFTs from "./pages/userCollectionNFT/UserCollectionNFT";


const XUMM_API_KEY = process.env.REACT_APP_XUMM_API_KEY;
const xumm = new XummPkce(XUMM_API_KEY);
const loginURL = "v2/user/login";

export const socket = io(socketBaseURL, {
  // Configure CORS options
  transports: ["websocket"],
  withCredentials: false,
  extraHeaders: {
    "Access-Control-Allow-Origin": socketBaseURL, // Replace with your frontend origin
  },
});
function App() {
  const [isConnected, setIsConnected] = useState(socket.connected);
const dispatch=useDispatch()


async function transferCount() {
  try {
    const response = await axiosRequestAuth("get", "v2/transfer/count", {});
    if (response) {
      // setNoOfTransfer(response.data?.data?.transfers_count);
    }
  } catch (error) {}
}

  useEffect(() => {
    function onConnect() {
      setIsConnected(true);
      console.log("socket connected");
      const userWallet = localStorage.getItem("accnum");
      socket.emit("new-connection", { user_wallet_address: userWallet });
    }

    function onDisconnect() {
      setIsConnected(false);
      console.log("socket connected failed");
    }

    socket.on("connect", onConnect);
    socket.on("disconnect", onDisconnect);

    return () => {
      socket.off("connect", onConnect);
      socket.off("disconnect", onDisconnect);
    };
  }, []);

  // useEffect(() => {
  //   if (isConnected) {
  //     const userWallet = localStorage.getItem("accnum");
  //     socket.emit("new-connection", { user_wallet_address: userWallet });
  //   }
  // }, [isConnected]);

  useEffect(()=> {
    const token=localStorage.getItem("token")
    console.log(1)
    const refreshPage = () => {
      console.log(2)

      const pkceStorageToken = JSON.parse(localStorage.getItem("XummPkceJwt"))
      if(pkceStorageToken) {
        console.log("iffffff")
        LoginAPI(pkceStorageToken);
        dispatch(login(pkceStorageToken));
      }else {
        console.log("leseeee  ")
        // window.location.reload()
    
      }
     }

     setTimeout(()=>{

       !token&&refreshPage()

     },2000)
    
  },[])
  
    async function LoginAPI(XummResponse) {
      console.log("backend funciton called" , XummResponse.me.account);
      try {
        const response = await axiosRequest("post", loginURL, {
          user_account: XummResponse.me.account,
        });
  
        if (response) {
          dispatch(setIsAdmin(response?.data?.data?.isAdmin));
          dispatch(setUserId(response?.data?.data?.existingUser?.id));
          dispatch(setToken(XummResponse.jwt));
          dispatch(setApiTimer(false));
          console.log(XummResponse.me?.account,"response")
  
          // UserCollectionNFTs(XummResponse?.me?.account);
          transferCount();
          localStorage.setItem("apiKey", XummResponse?.sdk?.Meta?.apiKey);
          localStorage.setItem("token", XummResponse.jwt);
          localStorage.setItem("accnum", XummResponse.me.account);
          localStorage.setItem("isAdmin", response?.data?.data?.isAdmin);
        }
  
        const currentDate = new Date(); // Get current date and time
        const next23Hours = new Date(currentDate.getTime() + 23 * 60 * 60 * 1000); // Add 23 hours in milliseconds
        toast.success(response.data.message);
        localStorage.setItem("expire", next23Hours);
  
        // setTimeout(function () {
        // window.location.reload();
        // }, 2000);
      } catch (error) {
        
        // localStorage.clear();
        toast.error(error,
      "adsda"
        );
  
        console.error(error);
      }
    }

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <div className="App-background">
        <ScrollToTop />
        <Header />
        <AllRoutes />
        <Footer />
        <ToastConfig />
      </div>
    </ThemeProvider>
  );
}

export default App;
