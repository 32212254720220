import React, { useState } from "react";
import Box from "@mui/material/Box";
import { useTheme } from "@mui/material";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close"; // Ensure you've imported CloseIcon
const SellFor = ({ onValueChange }) => {
  const theme = useTheme();
  const [value, setValue] = useState("");
  const [isTyping, setIsTyping] = useState(false);
  const handleChange = (event) => {
    const newValue = event.target.value.replace(/[^0-9]/g, "");
    setValue(newValue);
    setIsTyping(newValue.length > 0);
    if (onValueChange) {
      onValueChange(newValue); // Pass only the numeric value to the parent callback
    }
  };
  const handleClearInput = () => {
    setValue(""); // Set value to null
    setIsTyping(false);
    onValueChange(""); // Pass only the numeric value to the parent callback
  };
  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        color: "white",
        mt: 2,
      }}
    >
      <Typography
        variant="subtitle1"
        sx={{
          fontSize: "14px",
          marginRight: 2,
          cursor: "",
          // Hide on screens smaller than the medium breakpoint
          [theme.breakpoints.down("md")]: {
            display: "none",
          },
        }}
      >
        Sell for:
      </Typography>
      <TextField
        id="outlined-basic"
        variant="outlined"
        value={value}
        onChange={handleChange}
        sx={{
          // Apply margin-left only on screen sizes below the medium breakpoint
          [theme.breakpoints.down("md")]: {
            ml: 2,
          },
          backgroundColor: "white",
          borderRadius: 1,
          height: "30px",
          width: "150px",
          ".MuiOutlinedInput-root": {
            height: "30px",
            minHeight: "30px",
            alignItems: "center",
          },
          ".MuiOutlinedInput-input": {
            textAlign: "center",
            padding: "0px",
          },
          ".MuiOutlinedInput-notchedOutline": {
            border: "none",
          },
          input: {
            color: "black",
            textAlign: "center",
          },
        }}
        InputProps={{
          inputMode: "numeric",
          pattern: "[0-9]*",
        }}
      />
      {value ? (
        <CloseIcon
          onClick={handleClearInput}
          sx={{ marginLeft: 2, color: "white" }}
        />
      ) : (
        <Typography variant="body2" sx={{ fontSize: "12px", marginLeft: 2 }}>
          XRP
        </Typography>
      )}
    </Box>
  );
};
export default SellFor;
